import React, { useRef } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { AssetsTable } from './AssetsTable';
import { IAsset } from '../../types/AssetsTypes';
import { PagedResult } from '../../types/PagedResult';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { ElementScrollRestoration } from '../../routing/ElementScrollRestoration';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ValidPageSizes } from '../../common/table/Pagination';
import { convertSortingToSortBy, Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IAssetListOptions } from '../../types/Types';

export const securityAdvisoryParamName = 'advisory';

export interface IData {
  assets: PagedResult<IAsset>,
  options: IAssetListOptions
}

const defaultSort = [
  { property: 'name', direction: Direction.asc },
];

export const AssetsPage:IComponentWithLoader<IData, IAssetListOptions> = {
  loader: async (queryClient:QueryClient, _, pageSize:ValidPageSizes, options): Promise<IData> => (
    {
      assets: await getOrFetchFromApi<PagedResult<IAsset>>(
        queryClient,
        'assets',
        {
          ...options,
          page: 1,
          pageSize,
          sortBy: convertSortingToSortBy(defaultSort),
        },
      ),
      options: options ?? {},
    }),
  Component: () => {
    const { options } = useLoaderData() as Awaited<IData>;
    const pagedTableFilter = usePagedTableFilter<IAssetListOptions>(
      'assets-table',
      {},
      {
        initialQuery: options,
        initialSorting: defaultSort,
      },
    );

    const { data: pagedAssets } = useApiLoaderData<PagedResult<IAsset>, IData>(
      'assets',
      (loaderData) => loaderData.assets,
      pagedTableFilter.query,
    );

    const scrollContainerRef = useRef(null);

    return (
      <div>
        <Row>
          <Col>
            <Card className="fill-content">
              <Card.Header>
                <Card.Title>Assets</Card.Title>
              </Card.Header>
              <Card.Body ref={scrollContainerRef} className="overflow-auto" id="assets-card">
                <AssetsTable pagedAssets={pagedAssets} pagedFilters={pagedTableFilter} />
              </Card.Body>
            </Card>
            <ElementScrollRestoration targetId="assets-card" />
          </Col>
        </Row>
      </div>
    );
  },
};

export default AssetsPage;
