import React, { useMemo } from 'react';
import {
  Badge, Col, Form, Row,
} from 'react-bootstrap';
import { SecurityLevel, VulnerabilityStatus } from './Types';
import { QueryUtil } from './VulnerabilityFilter';
import { inEnum } from '../../utils/Utils';
import { useSecurityLevelAsText, useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';
import { securityLevelAsIndex, vulnerabilityStatusAsHexColor } from './Utils';
import { topicSearchParamName } from './VulnerabilityTrendPage';
import { IVulnerabilityListOptions } from '../../types/Types';

type SearchQueryKeys = {
  softSecurityLevels: string,
  softVulnerabilityStatuses: string,
}

const searchQueryKeys:SearchQueryKeys = {
  softSecurityLevels: 's_levels',
  softVulnerabilityStatuses: 's_statuses',
};

// Soft filters are done client side, and does not affect summaries and
// aggregated data, it only affects the vulnerabilitie listed in the table.
export interface IVulnerabilityControlFilter extends IVulnerabilityListOptions {
  softVulnerabilityStatuses?: VulnerabilityStatus[],
  softSecurityLevels?: SecurityLevel[],
}

export class VulnerabilityControlQueryUtil extends QueryUtil<IVulnerabilityControlFilter> {
  isSoftVulnerabityStatusDirty() {
    const filters = this.getFilters();
    if (!filters?.softVulnerabilityStatuses) return false;
    return filters?.softVulnerabilityStatuses.length > 0
          && filters.softVulnerabilityStatuses.length < Object.values(VulnerabilityStatus).length;
  }

  isSoftSecurityLevelsDirty() {
    const filters = this.getFilters();
    if (!filters?.softSecurityLevels) return false;
    return filters.softSecurityLevels.length > 0
      && filters.softSecurityLevels.length < Object.values(SecurityLevel).length - 1;
  }

  getFilterFromQueryParams(): IVulnerabilityControlFilter {
    const filters = {
      ...super.getFilterFromQueryParams(),
    };

    if (this.searchParams.has(searchQueryKeys.softSecurityLevels)) {
      filters.softSecurityLevels = [];
      this.searchParams.getAll(searchQueryKeys.softSecurityLevels).forEach((l) => {
        if (inEnum(SecurityLevel, l)) filters.softSecurityLevels?.push(l as SecurityLevel);
      });
    } else {
      filters.softSecurityLevels = Object.values(SecurityLevel).filter((s) => s !== SecurityLevel.Unknown);
    }
    if (this.searchParams.has(searchQueryKeys.softVulnerabilityStatuses)) {
      filters.softVulnerabilityStatuses = [];
      this.searchParams.getAll(searchQueryKeys.softVulnerabilityStatuses).forEach((s) => {
        if (inEnum(VulnerabilityStatus, s)) filters.softVulnerabilityStatuses?.push(s as VulnerabilityStatus);
      });
    } else {
      filters.softVulnerabilityStatuses = Object.values(VulnerabilityStatus);
    }

    return filters;
  }

  setFilters(
    filters: IVulnerabilityControlFilter,
  ): void {
    super.setFilters(filters);

    if (filters.softSecurityLevels) {
      this.searchParams.delete(searchQueryKeys.softSecurityLevels);
      filters.softSecurityLevels.forEach((level) => (
        this.searchParams.append(searchQueryKeys.softSecurityLevels, level)
      ));
    } else {
      this.searchParams.delete(searchQueryKeys.softSecurityLevels);
    }
    if (filters.softVulnerabilityStatuses) {
      this.searchParams.delete(searchQueryKeys.softVulnerabilityStatuses);
      filters.softVulnerabilityStatuses.forEach((status) => (
        this.searchParams.append(searchQueryKeys.softVulnerabilityStatuses, status)
      ));
    } else {
      this.searchParams.delete(searchQueryKeys.softVulnerabilityStatuses);
    }
  }

  resetFilters() {
    super.resetFilters();
    const keys = Object.keys(searchQueryKeys) as (keyof typeof searchQueryKeys)[];
    keys.forEach((n) => {
      this.searchParams.delete(searchQueryKeys[n]);
    });
    if (this.setSearchParams) {
      this.setSearchParams(this.searchParams);
    }
    this.filters = this.getFilterFromQueryParams();
    this.onChange(this);
  }

  static getSearchQueryKeys() {
    const keys = Object.keys(searchQueryKeys) as (keyof typeof searchQueryKeys)[];
    const searchKeys = super.getSearchQueryKeys().concat(keys.map((k) => searchQueryKeys[k]));
    searchKeys.push(topicSearchParamName);
    return searchKeys;
  }

  isDirty(): boolean {
    return super.isDirty()
     || this.isSoftVulnerabityStatusDirty()
     || this.isSoftSecurityLevelsDirty();
  }
}

interface IVulnerabilityControlProps {
  queryUtil:VulnerabilityControlQueryUtil,
  maxSecurityLevel:SecurityLevel|undefined
}

export const VulnerabilityControlSubFilters = ({
  queryUtil, maxSecurityLevel,
}:IVulnerabilityControlProps) => {
  const securityLevelAsText = useSecurityLevelAsText();
  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();

  const isVisible = <T, >(statuses:T[]|undefined, status:T) => (
    statuses === undefined || statuses.includes(status)
  );

  const filters = queryUtil.getFilters();

  const isAllSecurityLevelsSelected = (
    filters && (!filters.softSecurityLevels?.length || filters.softSecurityLevels.length === 3)
  );

  const isAllVulnerabilityStatusesSelected = (
    filters && (!filters.softVulnerabilityStatuses?.length || filters.softVulnerabilityStatuses.length === 3)
  );

  const applicableSecurityLevels = useMemo(() => (
    Object
      .values(SecurityLevel)
      .filter((s) => (s !== SecurityLevel.Unknown
                  && securityLevelAsIndex(s) <= securityLevelAsIndex(maxSecurityLevel)
                  && securityLevelAsText
      ))
  ), [maxSecurityLevel, securityLevelAsText]);

  return (
    <Row>
      <Col md={12}>
        <fieldset className="mb-3">
          <legend>Table content filters</legend>
          <Row>
            { filters.securityLevel !== SecurityLevel.Basic
              ? (
                <Col md={6} className="mb-3">
                  <Form.Label>
                    Security levels
                  </Form.Label>
                  { applicableSecurityLevels.map((s) => (
                    <Form.Check
                      id={`security-level-check-${s}`}
                      key={s}
                      label={securityLevelAsText(s)}
                      className={`${!isAllSecurityLevelsSelected && isVisible(filters?.softSecurityLevels, s) ? 'dirty' : ''}`}
                      checked={!isAllSecurityLevelsSelected && isVisible(filters?.softSecurityLevels, s)}
                      onChange={(e) => {
                        queryUtil.setFiltersAndUpdateSearchParams(
                          {
                            ...filters,
                            softSecurityLevels: e.target.checked ? [s] : undefined,
                          },
                          false,
                        );
                      }}
                    />
                  ))}
                  <Form.Check
                    id="security-level-check-all"
                    label="All"
                    checked={isAllSecurityLevelsSelected}
                    disabled={isAllSecurityLevelsSelected}
                    className={`${!isAllSecurityLevelsSelected ? 'dirty' : ''}`}
                    onChange={() => {
                      queryUtil.setFiltersAndUpdateSearchParams(
                        {
                          ...filters,
                          softSecurityLevels: undefined,
                        },
                        false,
                      );
                    }}
                  />
                </Col>
              ) : null }

            <Col md={6} className="mb-3">
              <Form.Label>Vulnerability statuses</Form.Label>
              { Object.values(VulnerabilityStatus).map((s) => (
                <Form.Check
                  key={s}
                  id={`vulnerability-status-check-${s}`}
                  label={(
                    <Badge
                      bg="none"
                      style={{ backgroundColor: vulnerabilityStatusAsHexColor(s) }}
                    >
                      {vulnerabilityStatusAsText(s)}
                    </Badge>
                  )}
                  className={`${!isAllVulnerabilityStatusesSelected && isVisible(filters?.softVulnerabilityStatuses, s) ? 'dirty' : ''}`}
                  checked={!isAllVulnerabilityStatusesSelected && isVisible(filters?.softVulnerabilityStatuses, s)}
                  onChange={(e) => {
                    queryUtil.setFiltersAndUpdateSearchParams(
                      {
                        ...filters,
                        softVulnerabilityStatuses: e.target.checked ? [s] : undefined,
                      },
                      false,
                    );
                  }}
                />
              ))}
              <Form.Check
                id="vulnerability-status-check-all"
                label="All"
                checked={isAllVulnerabilityStatusesSelected}
                disabled={isAllVulnerabilityStatusesSelected}
                className={`${!isAllVulnerabilityStatusesSelected ? 'dirty' : ''}`}
                onChange={() => {
                  queryUtil.setFiltersAndUpdateSearchParams(
                    {
                      ...filters,
                      softVulnerabilityStatuses: undefined,
                    },
                    false,
                  );
                }}
              />
            </Col>
          </Row>
        </fieldset>
      </Col>
    </Row>
  );
};
