import React from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  Card, Col, Row,
  Spinner,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { IVulnerabilityListOptions } from '../../types/Types';
import {
  IVulnerability, SecurityLevel, Severity, VulnerabilityStatus,
} from './Types';
import VulnerabilityFilter, { QueryUtil } from './VulnerabilityFilter';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { Module } from '../../types/AccessTypes';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { getOrFetchFromApi, useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { convertSortingToSortBy, Direction, usePagedTableFilter } from '../../common/table';
import { ValidPageSizes } from '../../common/table/Pagination';
import { CriticalityBar } from './CriticalityBar';

interface ILoaderData {
  options: IVulnerabilityListOptions|undefined,
  matrixSummary: Record<Severity, Record<Severity, number>>
}

const defaultSort = [
  { property: 'relativeSeverity', direction: Direction.desc },
];

export const VulnerabilitiesPage:IComponentWithLoader<
  ILoaderData,
  IVulnerabilityListOptions
> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:IVulnerabilityListOptions|undefined,
  ) => {
    const initialOptions:IVulnerabilityListOptions&{sortBy?: string|undefined} = {
      ...args ?? {},
      pageSize,
      page: args?.page ?? 1,
      status: args?.status ?? [VulnerabilityStatus.Open],
      sortBy: convertSortingToSortBy(defaultSort) ?? undefined,
    };

    return {
      matrixSummary: await getOrFetchFromApi<Record<Severity, Record<Severity, number>>>(
        queryClient,
        'vulnerabilities/severityMatrixSummary',
      ),
      options: initialOptions,
    };
  },
  Component: () => {
    const { getCustomerSetting } = useAccount();
    const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

    const [searchParams, setSearchParams] = useSearchParams();

    const {
      options,
    } = useLoaderData() as Awaited<ILoaderData>;

    const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
      'vulnerabilities-page',
      {},
      {
        initialQuery: options,
        initialSorting: defaultSort,
      },
    );

    const { appendQuery } = pagedTableFilter;

    const queryUtil = new QueryUtil<IVulnerabilityListOptions>(
      customerSecurityLevel,
      (query) => {
        appendQuery(query.getFilters());
      },
      [searchParams, setSearchParams],
    );

    const { query } = pagedTableFilter;

    const {
      data: pagedVulnerabilities,
      isLoading: vulnerabilitiesLoading,
    } = useApi<PagedResult<IVulnerability>>(
      'vulnerabilities',
      query,
    );

    const {
      data: customerVulnerability,
      isLoading: customerVulnerabilityLoading,
    } = useApi<PagedResult<IVulnerability>>(
      'vulnerabilities',
      { pageSize: 1 },
    );

    const { data: summary } = useApi<{severityCount:Record<Severity, number>}>(
      'vulnerabilities/summary',
    );

    return (
      <>
        <Row>
          <Col md={12}>
            <VulnerabilityFilter
              customerSecurityLevel={customerSecurityLevel}
              queryUtil={queryUtil}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                Vulnerabilities by criticality
              </Card.Header>
              <Card.Body>
                { summary?.severityCount
                  ? (
                    <CriticalityBar
                      criticalitySummary={summary.severityCount}
                      isSelected={(s) => (
                        !!(query.relativeSeverity && query.relativeSeverity.includes(s))
                      )}
                      onClick={(s) => {
                        pagedTableFilter.appendQuery({
                          ...query,
                          impact: undefined,
                          probability: undefined,
                          severity: undefined,
                          relativeSeverity: query.relativeSeverity?.includes(s)
                            ? query.relativeSeverity.filter((p) => p !== s)
                            : [s],
                        });
                      }}
                    />
                  )
                  : null }
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <Card.Header>
                Vulnerabilities
              </Card.Header>
              <Card.Body className="overflow-auto">
                <Row>
                  <Col md={12}>
                    { customerVulnerability?.items.length
                      ? (
                        <VulnerabilitiesTable
                          data={pagedVulnerabilities}
                          filters={pagedTableFilter}
                          isLoading={vulnerabilitiesLoading}
                        />
                      )
                      : customerVulnerabilityLoading
                        ? <Spinner animation="border" />
                        : <span>There are no vulnerabilities registered for your company</span> }
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  },
};

export default VulnerabilitiesPage;
