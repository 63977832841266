import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { IAssessmentAsset, IAssessmentSyncVulnerability } from './AssessmentTypes';
import { useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';
import { VulnerabilityStatus } from '../vulnerabilities/Types';
import {
  PagedTable, usePagedTableFilter, useArrayAsPagedResult, createPagedColumnHelper,
} from '../../common/table';

export interface IAssessmentVulnerabilityTableItem extends IAssessmentSyncVulnerability {
  asset:IAssessmentAsset
}

interface IProps {
  vulnerabilities: IAssessmentVulnerabilityTableItem[]
}

const AssessmentVulnerabilityTableCard = (props:IProps) => {
  const { vulnerabilities } = props;

  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();

  const columnHelper = createPagedColumnHelper<IAssessmentVulnerabilityTableItem>();

  const tableFilters = usePagedTableFilter<{
    asset?:string,
    statuses?:VulnerabilityStatus[],
    controlId?:number,
    summary?:string,
    details?:string,
    remediation?:string,
  }>(
    'assessment-vulnerabilities-table',
    {},
    {
      initialSorting: [

      ],
    },
  );

  const { appendQuery } = tableFilters;

  const columns = useMemo(
    () => [
      columnHelper.accessor('asset', {
        header: 'Asset',
        cell: ({ value }) => (value?.name),
        updateFilterFn: (assetNames:string[]) => {
          appendQuery({
            asset: assetNames ? assetNames[0] : undefined,
          });
        },
        isMatchFn: (asset:IAssessmentAsset, filterValue:unknown) => {
          const assetName = asset.name;
          return assetName.toLowerCase().includes(`${filterValue}`);
        },
        formatter: (value:IAssessmentAsset) => value.name,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ row }) => vulnerabilityStatusAsText(row.original.status),
        formatter: (value:VulnerabilityStatus) => vulnerabilityStatusAsText(value),
        supportMultiSelect: true,
        filterPropertyName: 'statuses',
        updateFilterFn: (statuses:VulnerabilityStatus[]) => {
          appendQuery({
            statuses,
          });
        },
        selectOptions: [
          VulnerabilityStatus.Open,
          VulnerabilityStatus.Mitigated,
        ],
      }),
      columnHelper.accessor('controlFriendlyId', {
        header: 'Control',
        sortFn: (a, b) => a.localeCompare(
          b,
          undefined,
          { numeric: true, sensitivity: 'base' },
        ),
        filterPropertyName: 'controlId',
        updateFilterFn: (values:number[]) => {
          appendQuery({
            controlId: values.length ? values[0] : undefined,
          });
        },
        isMatchFn: (controlId:string, filterValue:unknown) => (
          controlId === filterValue as string
        ),
      }),
      columnHelper.accessor('summary', {
        header: 'Summary',
        updateFilterFn: (values:string[]) => {
          appendQuery({
            summary: values.length ? values[0] : undefined,
          });
        },
      }),
      columnHelper.accessor('details', {
        header: 'Details',
        updateFilterFn: (values:string[]) => {
          appendQuery({
            details: values.length ? values[0] : undefined,
          });
        },
      }),
      columnHelper.accessor('remediation', {
        header: 'Remediation',
        updateFilterFn: (values:string[]) => {
          appendQuery({
            remediation: values.length ? values[0] : undefined,
          });
        },
      }),
    ],
    [appendQuery, columnHelper, vulnerabilityStatusAsText],
  );

  const pagedVulnerabilities = useArrayAsPagedResult(
    vulnerabilities,
    tableFilters,
    columns,
  );

  return (
    <Card>
      <Card.Header>
        Identified vulnerabilities
      </Card.Header>
      <Card.Body>
        <PagedTable
          columnDefs={columns}
          data={pagedVulnerabilities}
          filters={tableFilters}
          disableColumnSelect
        />
      </Card.Body>
    </Card>
  );
};

export default AssessmentVulnerabilityTableCard;
