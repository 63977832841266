import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { CancelledError, useQueryClient } from '@tanstack/react-query';
import { getOrFetchFromApi } from '../query/GenericQuery';
import { IModule } from '../types/AccessTypes';
import { LoadingTemplate } from '../routing/LoadingTemplate';
import { useAccount } from './AccountProvider';

interface IModuleData {
  modules: IModule[],
  customerModules: IModule[],
  getModuleByIdOrUndefined: (moduleId:number) => IModule|undefined,
  getModuleNameOrDefault: (moduleId:number, defaultName?:string) => string|typeof defaultName;
}

export const ModuleContext = createContext<IModuleData|null>(null);

export const useModules = () => {
  const context = useContext(ModuleContext);
  if (!context) {
    throw new Error('No ModuleContext found when calling useModules');
  }
  return context;
};

export const ModulesProvider = ({ children }:{children:React.ReactNode}) => {
  const { customerHasModule } = useAccount();
  const queryClient = useQueryClient();
  const [modules, setModules] = useState<IModule[]>();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setModules(
          await getOrFetchFromApi<IModule[]>(queryClient, 'constants/modules', undefined, { retry: 5 }),
        );
      } catch (err) {
        // Ignore CancelledError as that might occur for a short moment when switching customer
        if (!(err instanceof CancelledError)) {
          throw err;
        }
      }
    };
    fetchModules();
  }, [queryClient]);

  const findModule = useCallback((moduleId:number) => modules?.find((m) => m.id === moduleId), [modules]);

  const customerModules = useMemo(() : IModule[] => (
    modules ? modules?.filter((m) => customerHasModule(m.id)) : []
  ), [customerHasModule, modules]);

  const moduleData = useMemo(() => (modules
    ? {
      modules,
      customerModules,
      getModuleByIdOrUndefined: findModule,
      getModuleNameOrDefault: (moduleId:number) => (findModule(moduleId)?.name ?? (moduleId ? `Module ${moduleId}` : undefined)),
    }
    : undefined
  ), [customerModules, findModule, modules]);

  return moduleData
    ? (
      <ModuleContext.Provider value={moduleData}>
        { children }
      </ModuleContext.Provider>
    )
    : <LoadingTemplate>Loading modules</LoadingTemplate>;
};
