import React, { useState } from 'react';
import {
  Alert, Button, Col, Form, ModalBody, ModalFooter, Row, Stack,
} from 'react-bootstrap';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ICustomerSettingsUser, IDomainUser } from './CustomerTypes';
import { IUser } from '../../types/AccessTypes';

const emptyState:{
  upn: string,
  error?: string,
  saving: boolean,
  invite: boolean
} = {
  upn: '',
  error: undefined,
  saving: false,
  invite: false,
};

const AddAssignableUserModal = ({
  close,
}:{
  close: (confirmed:boolean, value?:ICustomerSettingsUser&{invite:boolean}|undefined) => void,
}) => {
  const [state, setState] = useState(emptyState);

  const internalHandleClose = (user:ICustomerSettingsUser&{invite:boolean}|undefined) => {
    setState(emptyState);
    close(true, user);
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, saving: true });

    try {
      if (!state.upn) return;

      if (state.invite) {
        const { data: invitedUser } = await axios.post<{account:IUser, created:boolean}>(
          `/api/v1/accountInvite?externalId=${encodeURIComponent(state.upn)}`,
        );

        if (invitedUser.account) {
          setState((s) => ({ ...s, upn: '' }));
          internalHandleClose({
            ...invitedUser.account,
            moduleRoles: {},
            invite: true,
          });
        } else {
          toast.warning('Email address is not for a valid Microsoft Entra ID account. Please try a different address.', {
            toastId: 'add-error',
            updateId: 'add-error',
          });
        }
        return;
      }

      const { data: user } = await axios.get<IDomainUser>(
          `api/v1/module/customer/assignableUser?upn=${encodeURIComponent(state.upn)}`,
      );

      if (user) {
        try {
          const { data: addedUser } = await axios.post<ICustomerSettingsUser>('/api/v1/module/customer/account', user);

          setState((s) => ({ ...s, upn: '' }));
          internalHandleClose({
            ...addedUser,
            invite: false,
          });
        } catch (err) {
          const axiosError = err as AxiosError;
          if (axiosError.response?.status === 409) {
            toast.warning('This user already exists.', {
              toastId: 'add-error',
              updateId: 'add-error',
            });
          } else {
            throw err;
          }
        }
      } else {
        setState((s) => ({ ...s, invite: true }));
      }
    } finally {
      setState((s) => ({ ...s, saving: false }));
    }
  };

  return (
    <Form onSubmit={submit}>
      <ModalBody>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Label>Email address (UPN):</Form.Label>
            <Form.Control
              value={state.upn}
              type="email"
              name="upn"
              autoFocus
              isInvalid={!!state.error}
              onChange={(e) => {
                const error = e.target.validity
                  ? e.target.validationMessage
                  : undefined;

                setState({
                  ...state,
                  error,
                  invite: false,
                  upn: e.target.value,
                });
              }}
            />
            {state.error
              ? (
                <Alert variant="danger" className="mt-2 p-3">
                  {state.error}
                </Alert>
              )
              : state.invite
                ? (
                  <Alert variant="warning" className="mt-2 p-3">
                    No matching users were found for your search.
                    <br />
                    <br />
                    You may invite the user, but please ensure you have entered the
                    full email address. Additionally, the email must correspond to a
                    valid Microsoft Entra ID account.
                  </Alert>
                )
                : null }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              { state.invite
                ? (
                  <Button variant="danger" type="submit" disabled={state.saving || !!state.error || !state.upn.length}>
                    Invite external user
                  </Button>
                )
                : (
                  <Button type="submit" disabled={state.saving || !!state.error || !state.upn.length}>
                    Add
                  </Button>
                ) }
              <Button
                type="button"
                variant="secondary"
                onClick={() => internalHandleClose(undefined)}
              >
                Cancel
              </Button>
            </Stack>
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  );
};

export default AddAssignableUserModal;
