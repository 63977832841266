import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Setting } from '../types/Types';
import { useModules } from '../providers/ModuleProvider';
import { Direction, usePagedTableFilter } from '../common/table/PagedResultFilter';
import { useArrayAsPagedResult, valueFilterWeakIncludes } from '../common/table/useArrayAsPagedResult';
import { Module } from '../types/AccessTypes';
import { createPagedColumnHelper, PagedTable } from '../common/table';

interface ISettingFilter {
  module?:Module,
  key?:string,
  value?:string
}

export const SettingsTable = ({
  settings,
  hide,
}:{
  settings:Setting[],
  hide?:number
}) => {
  const { getModuleNameOrDefault } = useModules();

  const columnHelper = useMemo(() => createPagedColumnHelper<Setting>(), []);

  const tableFilters = usePagedTableFilter<ISettingFilter>(
    'settings-table',
    {},
    {
      initialSorting: [
        { property: 'module', direction: Direction.asc },
        { property: 'key', direction: Direction.asc },
      ],
    },
  );

  const { appendQuery } = tableFilters;

  const columns = useMemo(
    () => ([
      columnHelper.accessor('module', {
        header: 'Module',
        cell: ({ value }) => {
          const moduleId = value ?? 0;
          return moduleId === 0
            ? ''
            : getModuleNameOrDefault(moduleId);
        },
        isMatchFn: (module:Module, filterValue:unknown) => {
          const moduleName = getModuleNameOrDefault(module);
          return moduleName
            ? valueFilterWeakIncludes(moduleName, `${filterValue}`)
            : false;
        },
        updateFilterFn: (modules:Module[]) => {
          appendQuery({
            module: modules.length ? modules[0] : undefined,
          });
        },
        sortFn: (a:Module, b:Module) => {
          const moduleA = a as number;
          const moduleB = b as number;
          if (moduleA === 0 && moduleB === 0) {
            return 0;
          }
          if (moduleA === 0) {
            return -1;
          }
          if (moduleB === 0) {
            return 1;
          }
          const nameA = getModuleNameOrDefault(moduleA);
          const nameB = getModuleNameOrDefault(moduleB);
          if (nameA === nameB) return 0;
          return (nameA ?? '') < (nameB ?? '') ? -1 : 1;
        },
      }),
      columnHelper.accessor('key', {
        header: 'Key',
        cell: ({ value }) => (
          <span className="font-monospace small">
            { value }
          </span>
        ),
        updateFilterFn: (keys:string[]) => {
          appendQuery({
            key: keys.length ? keys[0] : undefined,
          });
        },
      }),
      columnHelper.accessor(
        'value',
        {
          header: 'Value',
          cell: ({ value }) => (!hide || value.length < hide
            ? (
              <span className="font-monospace small">
                { value }
              </span>
            ) : (
              <span className="text-muted small">
                (hidden due to size)
              </span>
            )),
          updateFilterFn: (values:string[]) => {
            appendQuery({
              value: values.length ? values[0] : undefined,
            });
          },
        },
      ),
    ]),
    [appendQuery, columnHelper, getModuleNameOrDefault, hide],
  );

  const pagedResult = useArrayAsPagedResult(
    settings,
    tableFilters,
    columns,
  );

  if (!pagedResult) return <Spinner animation="border" />;

  return (
    <PagedTable
      columnDefs={columns}
      data={pagedResult}
      filters={tableFilters}
    />
  );
};
