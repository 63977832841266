import React, { useMemo } from 'react';
import { ISupportProperties, IProperty } from '../types/Types';
import { Direction, usePagedTableFilter } from '../common/table/PagedResultFilter';
import { PagedTable, createPagedColumnHelper } from '../common/table/PagedTable';
import { useArrayAsPagedResult } from '../common/table/useArrayAsPagedResult';

interface IPropertyFilter {
  key?:string,
  value?:string
}

export const PropertiesTable = ({ properties }:ISupportProperties&{assetId:string}) => {
  const columnHelper = createPagedColumnHelper<IProperty>();

  const tableFilters = usePagedTableFilter<IPropertyFilter>(
    'asset-properties-table',
    {},
    {
      initialSorting: [
        { property: 'key', direction: Direction.asc },
      ],
    },
  );

  const { appendQuery } = tableFilters;

  const tableColumnDefs = useMemo(() => {
    const c = [];

    c.push({
      ...columnHelper.accessor('key', {
        header: 'Key',
        updateFilterFn: (values:string[]) => {
          appendQuery({ key: values ? values[0] : undefined });
        },
      }),
    });

    c.push({
      ...columnHelper.accessor('value', {
        header: 'Value',
        updateFilterFn: (values:string[]) => {
          appendQuery({ value: values ? values[0] : undefined });
        },
      }),
    });

    return c;
  }, [appendQuery, columnHelper]);

  const pagedProperties = useArrayAsPagedResult(
    properties,
    tableFilters,
    tableColumnDefs,
  );

  if (!pagedProperties) return null;

  return (
    <PagedTable
      columnDefs={tableColumnDefs}
      data={pagedProperties}
      filters={tableFilters}
      disableColumnSelect
    />
  );
};
