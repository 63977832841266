/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from '@ailibs/feather-react-ts';
import React, {
  HTMLAttributes, useCallback, useMemo, useState,
} from 'react';
import {
  Button, Collapse, Stack, Table as BTable,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from 'react-bootstrap';
import { useStore } from 'zustand';
import { SelectColumnsModal } from './SelectColumnsModal';
import RenderHtml from '../../components/RenderHtml';
import { Direction, IPagedTableFilter, ISorting } from './PagedResultFilter';
import { useClickWithoutDrag } from '../useClickWithoutDrag';
import { PagedResult } from '../../types/PagedResult';
import { PagedResultTableFilter } from './filters/PagedResultTableFilter';
import {
  IHeader, IRow, useTableModel, VisibilityState,
} from './useTableModel';
import { convertPageToNewPageSize } from '../../utils/Utils';
import Pagination, { IPaginationMutableState, usePageCache } from './Pagination';

// ===============================
// This section is duplicated from react-table to keep createColumnHelper behaviour
// in out createPagedColumnHelper.
// Start: ===============================>
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
type AllowedIndexes<Tuple extends ReadonlyArray<any>, Keys extends number = never> = Tuple extends readonly [] ? Keys : Tuple extends readonly [infer _, ...infer Tail] ? AllowedIndexes<Tail, Keys | Tail['length']> : Keys;
type ComputeRange<N extends number, Result extends Array<unknown> = []> = Result['length'] extends N ? Result : ComputeRange<N, [...Result, Result['length']]>;
type Index40 = ComputeRange<40>[number];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IsTuple<T> = T extends readonly any[] & {
  length: infer Length;
} ? Length extends Index40 ? T : never : never;
// eslint-disable-next-line no-use-before-define, @typescript-eslint/no-explicit-any
type DeepKeysPrefix<T, TPrefix, TDepth extends any[]> = TPrefix extends keyof T & (number | string) ? `${TPrefix}.${DeepKeys<T[TPrefix], [...TDepth, any]> & string}` : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeepKeys<T, TDepth extends any[] = []> = TDepth['length'] extends 5 ? never : unknown extends T ? string : T extends readonly any[] & IsTuple<T> ? AllowedIndexes<T> | DeepKeysPrefix<T, AllowedIndexes<T>, TDepth> : T extends any[] ? DeepKeys<T[number], [...TDepth, any]> : T extends Date ? never : T extends object ? (keyof T & string) | DeepKeysPrefix<T, keyof T, TDepth> : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeepValue<T, TProp> = T extends Record<string | number, any> ? TProp extends `${infer TBranch}.${infer TDeepProp}` ? DeepValue<T[TBranch], TDeepProp> : T[TProp & string] : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowData = unknown | object | any[];
export type AccessorFn<TData extends RowData, TValue = unknown> = (originalRow: TData, index: number) => TValue;
export interface ColumnFilter {
  id: string;
  value: unknown;
}
export type PaginationState = {
  pageIndex: number
  pageSize: number
}
// End: <===============================

export interface IFilterFn<TValue, TFilterValue> {
  (propertyValue:TValue, filterValue:TFilterValue) : boolean
}

export type PagedFilterInputType = 'number'|'text'|'email'|'date'|'datetime-local'|'url'|'time'|'password'|'month'|'color';

export interface IPagedFilter<TValue, TFilterValue, TFilterInputValue> {
  filterPropertyName?: string,
  filterFormatter?: (values:TFilterValue[]) => string,
  updateFilterFn?: (values:TFilterValue[]) => void,
  selectOptions?: readonly TValue[],
  supportMultiSelect?: boolean,
  isMatchFn?: IFilterFn<TValue, TFilterInputValue>,
  inputType?: PagedFilterInputType
}

export interface IPagedSort<TValue> {
  disableSorting?:boolean,
  /**
   * If property has a different sort property than in the model, this mapping
   * can be done with this proprery.
   */
  sortPropertyName?: string,
  sortFn?: (a:TValue, b:TValue) => number
}

export type BaseColumnConfig<TObject, TValue, TFilterValue> = {
  id?: string,
  accessorKey?: string
  disableFilter?: boolean
  disableHiding?: boolean,
  header?: string,
  className?: string,
  cell?: ({ value, row }:{value:TValue, row:IRow<TObject, TValue, TFilterValue>}) =>
    string|JSX.Element[]|JSX.Element|undefined|null,
}

export type PageableColumnConfig<TObject, TValue, TFilterValue> =
BaseColumnConfig<TObject, TValue, TFilterValue> &
IPagedFilter<TValue, TFilterValue, unknown> &
IPagedSort<TValue> & {
  disableColumnDefault?: boolean,
  defaultHidden?: boolean,
  formatter?: (value:TValue) => React.ReactNode|string|undefined,
  optionsSortFn?: (itemA:TValue, itemB:TValue) => number,
}

export type PageableColumnDef<TObject, TValue, TFilterValue> =
IPagedFilter<TValue, TFilterValue, unknown> &
IPagedSort<TValue> & {
  id: string,
  accessorKey: string
  header: string,
  className?: string,
  disableHiding?: boolean,
  disableColumnDefault?: boolean,
  disableFilter?: boolean,
  defaultHidden?: boolean,
  cell?: ({ value, row }:{value:TValue, row:IRow<TObject, TValue, TFilterValue>}) =>
    string|JSX.Element|JSX.Element[]|undefined|null,
  formatter?: (value:TValue) => React.ReactNode|string|undefined,
}

type PagedTableRowClickProps<TObject> = {
  /** Custom Row Click handler */
  onRowClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: TObject
  ) => void,
  expand?: never,
  clickRowToToggleExpand?: boolean,
  ExpandElement?: never
}

type PagedTableExpandProps<TObject, TValue, TFilterValue> = {
  /** Flag to enable row expand/collapse on click */
  expand?: boolean,
  /** The element to render on row expand */
  ExpandElement?: React.FunctionComponent<{row: IRow<TObject, TValue, TFilterValue>, even: boolean}>,
  clickRowToToggleExpand?: boolean,
  onRowClick?: never,
}

export type BaseTableProps<TObject, TValue, TFilterValue> =
(PagedTableRowClickProps<TObject> | PagedTableExpandProps<TObject, TValue, TFilterValue>) & {
  /** The table columns. See https://tanstack.com/table/v8/docs/guide/column-defs for more info */
  columnDefs: PageableColumnDef<TObject, TValue, TFilterValue>[], // column type
  /** Flag to disable filters (enabled by default) */
  disableFilters?: boolean,
  /** Flag to disable column selection */
  disableColumnSelect?: boolean,
  // customSelectColumns?: boolean, // ???
  /** Additional props on the table rows */
  rowProps?: React.HTMLAttributes<HTMLTableRowElement>,
  /** Handler for setting props on the row based on row data */
  rowPropSelector?: (row: TObject) => React.HTMLAttributes<HTMLTableRowElement>,
  rowClassNameResolver?: (row: TObject) => string|undefined,
  /** Custom toolbar elements to render on the toolbar */
  CustomToolbarElements?: JSX.Element | (JSX.Element | null)[] | null,
  size?: 'sm' | 'lg',
  hover?: boolean,
  refresh?: () => void|Promise<void>,
  emptyContent?:string|React.ReactNode|undefined,
  className?:string|undefined,
  alternatingRowColors?:boolean,
  renderMarkdown?:boolean,
  noMargin?:boolean,
}

export type PagedTableProps<TObject, TValue, TFilterValue> =
BaseTableProps<TObject, TValue, TFilterValue> & {
  data?: PagedResult<TObject>,
  isLoading?: boolean
};

export const createPagedColumnHelper = <TObject, >() => ({
  accessor: <
      TAccessor extends AccessorFn<TObject> | DeepKeys<TObject>,
      TValue extends TAccessor extends AccessorFn<TObject, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TObject> ? DeepValue<TObject, TAccessor> : never,
      TFilterValue
    >(
    accessor: TAccessor,
    column: PageableColumnConfig<TObject, TValue, TFilterValue>,
  )
  : PageableColumnDef<TObject, unknown, unknown> => {
    const columnDef:PageableColumnDef<TObject, TValue, TFilterValue> = {
      ...column as BaseColumnConfig<TObject, TValue, TFilterValue>,
      id: typeof accessor === 'string' ? accessor : 'unknown-accessor',
      accessorKey: typeof accessor === 'string' ? accessor : 'unknown-accessor',
      header: column.header ?? '',
    };
    return columnDef as PageableColumnDef<TObject, unknown, unknown>;
  },
  display: <TValue, TFilterValue, >(column: BaseColumnConfig<TObject, TValue, TFilterValue>)
  : PageableColumnDef<TObject, unknown, unknown> => ({
    ...column,
    id: column.id ?? '',
    accessorKey: column.accessorKey ?? '',
    header: column.header ?? '',
    disableFilter: true,
  } as PageableColumnDef<TObject, unknown, unknown>),
});

/**
 * Hook for storing the tables expanded state as search params.
 */
/*
const useSearchParamsExpandStatePersister = (paramName:string, pageSize?:number) : [
  ExpandedState,
  (updaterOrValue:Updater<ExpandedState>)=> void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getExpandedFromUri = () => {
    const e = {} as Record<string, boolean>;
    if (pageSize) {
      searchParams.getAll(paramName).forEach((v) => {
      // Expand all items in page if requested
        if (v === 'all') {
          for (let i = 0; i < pageSize; i += 1) {
            e[i] = true;
          }
        } else {
        // Expand single item if requested
          e[v] = true;
        }
      });
    }
    return e as ExpandedState;
  };

  const expanded = getExpandedFromUri();

  const setExpanded = (updaterOrValue: Updater<ExpandedState>) => {
    const value = typeof updaterOrValue === 'function'
      ? updaterOrValue(expanded)
      : updaterOrValue;

    const expandedKeys = Object.keys(value);
    if (!expandedKeys.length) searchParams.delete(paramName);
    else {
      searchParams.set(paramName, expandedKeys[0]);
      expandedKeys.slice(1).forEach((v) => searchParams.append(paramName, v));
    }
    setSearchParams(searchParams, { replace: true });
  };

  return [expanded, setExpanded];
};
*/
/*
const getAccessorKeyFromColumn = <TObject, TValue, >(column:Column<TObject, TValue>) => {
  const columnDef = column.columnDef as {accessorKey:string|undefined};
  return columnDef.accessorKey;
};
const getAccessorKeyFromHeader = <TObject, TValue, >(header:Header<TObject, TValue>) => (
  getAccessorKeyFromColumn(header.column)
); */

const isRowClick = (el:Element) => {
  let curr = el;
  while (curr && curr.nodeName !== 'TD') {
    if (curr.nodeName === 'BUTTON') {
      return /row-click/.test(el.className);
    }
    if (curr.nodeName === 'A') {
      return false;
    }
    curr = curr.parentElement as Element;
  }
  return true;
};

const TableFilter = <TObject, TValue, TFilterValue, >({
  size,
  columnDef,
  accessorKey,
  setCustomFilters,
  filterValuesMap,
}:{
  columnDef: PageableColumnDef<TObject, TValue, TFilterValue>,
  accessorKey: string,
  setCustomFilters?: (accessorKey:string, filter:TFilterValue[]) => void,
  filterValuesMap:Record<string, TFilterValue[]>,
  size?: 'sm' | 'lg',
}) => {
  const filterValue = filterValuesMap[accessorKey];

  return (
    columnDef
    && setCustomFilters
    && (columnDef.updateFilterFn || filterValuesMap[accessorKey])
      ? (
        <PagedResultTableFilter
          size={size}
          columnDef={columnDef}
          disabled={columnDef.disableFilter === true}
          setFilterValues={(values) => {
            setCustomFilters(accessorKey, values);
          }}
          filterText={
            columnDef.filterFormatter
              ? columnDef.filterFormatter(filterValue)
              : filterValue === undefined || filterValue === null ? '' : `${filterValuesMap[accessorKey]}`
          }
          filterValues={filterValuesMap[accessorKey]}
        />
      )
      : null
  );
};

const TableHeader = <TValue, >({
  header,
  sortDef,
  filterPropertyName,
  customSorting,
  setCustomSorting,
}:{
  header:IHeader,
  filterPropertyName:string|undefined,
  sortDef:IPagedSort<TValue>|undefined,
  customSorting:ISorting[]|undefined,
  setCustomSorting?:(sorting:ISorting[]) => void,
}) => {
  const isSortable = sortDef?.disableSorting !== true;
  const sortProperty = sortDef?.sortPropertyName ?? filterPropertyName ?? header.accessorKey;

  const columnSort:ISorting|null = useMemo(() => (
    customSorting
      ? customSorting.find((s) => (
        s.property === sortProperty
      )) ?? { property: sortProperty } as ISorting
      : null
  ), [sortProperty, customSorting]);

  const toggleSort = useCallback(() => {
    if (columnSort && setCustomSorting) {
      const sorting = customSorting ? [...customSorting] : [];
      const newColumnSorting:ISorting = {
        ...columnSort,
        direction: (typeof columnSort.direction === 'number' ? (columnSort.direction + 1) % 3 : Direction.desc),
      };
      const idx = sorting.findIndex((s) => s.property === sortProperty);
      if (idx < 0) {
        setCustomSorting([
          newColumnSorting,
        ]);
      } else {
        sorting.splice(idx, 1, newColumnSorting);
        setCustomSorting(sorting);
      }
    }
  }, [columnSort, setCustomSorting, customSorting, sortProperty]);

  const sortDirection:Direction = columnSort?.direction ?? Direction.none;

  // if (header.isPlaceholder) return null;

  if (!header.canSort) {
    return header.name;
  }

  return (
    <Button
      variant="text text-bold"
      disabled={!isSortable}
      onClick={toggleSort}
    >
      {header.name}
      {[
        null,
        <Icon name="arrow-down" className="align-middle" size="16px" />,
        <Icon name="arrow-up" className="align-middle" size="16px" />,
      ][sortDirection] ?? null}
    </Button>
  );
};

const Table = <TObject, TValue, TFilterValue, TQuery, >({
  data,
  columnDefs,
  disableFilters,
  disableColumnSelect,
  onRowClick,
  rowProps,
  rowPropSelector,
  rowClassNameResolver,
  CustomToolbarElements,
  expand,
  ExpandElement,
  clickRowToToggleExpand,
  size,
  refresh,
  hover,
  emptyContent,
  className,
  alternatingRowColors,
  renderMarkdown,
  noMargin,
  filters,
  isLoading,
}: PagedTableProps<TObject, TValue, TFilterValue> & {
  filters:IPagedTableFilter<TQuery>,
}) => {
  const tableState = useStore(filters.tableStore);

  const {
    columnVisibility,
    setColumnVisibility,
  } = tableState;

  const {
    isFiltered: isCustomFiltered,
    reset: resetCustomFilters,
    query: customFilterValues,
    sorting: customSorting,
    setSorting: setCustomSorting,
  } = filters ?? {};

  const defaultColumnVisibility = useMemo(() => {
    const mDefaultColumnVisibility:VisibilityState = {};
    columnDefs.forEach((c) => {
      if (!c.accessorKey) {
        return;
      }
      if (c.defaultHidden === true && (c.disableHiding !== true)) {
        mDefaultColumnVisibility[c.accessorKey] = false;
      }
    });
    return mDefaultColumnVisibility;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefs]);

  // Expanded state is not presisted to localstorage, so using a normal React.useState
  // const [expanded, setExpanded] = useSearchParamsExpandStatePersister('expand', pagedTableFilter.pageableQuery.page);
  const [rowHover, setRowHover] = useState<string>();

  const columnDefMap = useMemo(() => {
    const mColumnDefMap:Record<string, PageableColumnDef<TObject, TValue, TFilterValue>> = {};
    columnDefs.forEach((def) => {
      if (def.accessorKey) {
        mColumnDefMap[def.accessorKey] = def;
      }
    });
    return mColumnDefMap;
  }, [columnDefs]);

  const hasActiveFilters = useMemo(() => (
    isCustomFiltered
    || filters.query.page > 1
  ), [filters.query.page, isCustomFiltered]);

  const customFilterDefMap = useMemo(() => {
    const filterMap:Record<string, IPagedFilter<unknown, TFilterValue, unknown>> = {};
    columnDefs.forEach((c) => {
      filterMap[c.accessorKey] = c as IPagedFilter<unknown, TFilterValue, unknown>;
    });
    return filterMap;
  }, [columnDefs]);

  const filterValueColumnAccessorKeyMap = useMemo(() => {
    const mFilterValueColumnAccessorKeyMap:Record<string, TFilterValue[]> = {};
    if (customFilterDefMap && customFilterValues) {
      Object.keys(customFilterDefMap).forEach((accessorKey) => {
        const filterPropertyName = customFilterDefMap[accessorKey]?.filterPropertyName ?? accessorKey;
        if (filterPropertyName) {
          const entry = Object.entries(customFilterValues as object).find(([k]) => k === filterPropertyName);
          if (entry) {
            const [, value] = entry;
            mFilterValueColumnAccessorKeyMap[accessorKey] = (value === undefined || Array.isArray(value))
              ? value
              : [value] as object[];
          }
        }
      });
    }

    return mFilterValueColumnAccessorKeyMap;
  }, [customFilterDefMap, customFilterValues]);

  const setCustomFilters = (accessorKey:string, filter:TFilterValue[]) => {
    const filterFn = customFilterDefMap[accessorKey]?.updateFilterFn;
    if (typeof filterFn === 'function') {
      filterFn(filter);
    }
  };

  const [showSelect, setShowSelect] = useState(false);
  const handleOpenSelect = () => setShowSelect(true);
  const handleCloseSelect = () => setShowSelect(false);

  const onClickWithoutDrag = useClickWithoutDrag();

  const getRowClickProps = (
    row: IRow<TObject, TValue, TFilterValue>,
  ) => {
    if (onRowClick) {
      return {
        onMouseLeave: () => {
          setTimeout(() => {
            if (rowHover === row.id) {
              setRowHover(undefined);
            }
          });
        },
        onMouseMove: (e:React.MouseEvent<HTMLElement, MouseEvent>) => {
          setRowHover(isRowClick(e.target as Element) ? row.id : undefined);
        },
        onMouseDown: onClickWithoutDrag.onMouseDown,
        onMouseUp: (
          e:React.MouseEvent<HTMLElement, MouseEvent>,
        ) => onClickWithoutDrag.getMouseUpHandler(e, () => onRowClick(e, row.original)),
        role: 'button',
      };
    }

    return {};
  };

  const getRowExpandProps = (
    row: IRow<TObject, TValue, TFilterValue>,
  ) : HTMLAttributes<HTMLTableRowElement> => {
    if (expand) {
      return {
        onClick: (e) => {
          const tagName = (e.target as unknown as {tagName:string}).tagName.toLowerCase();
          if (tagName !== 'a' && tagName !== 'button') {
            e.preventDefault();
            row.toggleExpanded();
          }
        },
        role: 'button',
      };
    }

    return {};
  };

  // Modify visibility state to ensure filtered columns are always visible
  const allFilteredVisibleVisibilityState = useMemo(() => {
    const mVisibilityState: VisibilityState = {
      ...(columnVisibility ?? defaultColumnVisibility),
    };

    Object.keys(mVisibilityState).forEach((key) => {
      if (filters.isColumnFiltered(columnDefMap[key] as PageableColumnDef<unknown, unknown, unknown>)) {
        mVisibilityState[key] = true;
      }
    });

    return mVisibilityState;
  }, [columnDefMap, columnVisibility, defaultColumnVisibility, filters]);

  const table = useTableModel(
    data?.items,
    columnDefs,
    allFilteredVisibleVisibilityState,
    setColumnVisibility,
    filters,
  );

  const atLeastOneFilter = table.visibleColumns
    .find((col) => !!col.columnDef.updateFilterFn);

  return (
    <div className={`react-table ${noMargin ? '' : 'mb-3'} ${className}`}>
      <BTable hover={hover ?? true} size={size ?? 'sm'} className="table-flex">
        <thead>
          <React.Fragment key="header-group-default">
            <tr>
              {table.headers.map((header) => {
                const { accessorKey } = header;
                return (
                  <th key={header.id} colSpan={1} className={atLeastOneFilter ? 'pb-0' : ''}>
                    <TableHeader
                      header={header}
                      filterPropertyName={accessorKey ? columnDefMap[accessorKey].filterPropertyName : undefined}
                      sortDef={accessorKey ? columnDefMap[accessorKey] : undefined}
                      customSorting={customSorting}
                      setCustomSorting={setCustomSorting}
                    />
                  </th>
                );
              })}
              { onRowClick ? <th aria-label="Row Click" /> : null }
            </tr>
            {!disableFilters ? (
              <tr className="filter-row">
                {table.headers.map((header) => (atLeastOneFilter ? (
                  <th key={header.id}>
                    {!disableFilters
                      ? (
                        <TableFilter
                          size={size}
                          accessorKey={header.accessorKey}
                          columnDef={header.columnDef as PageableColumnDef<TObject, TValue, TFilterValue>}
                          setCustomFilters={setCustomFilters}
                          filterValuesMap={filterValueColumnAccessorKeyMap}
                        />
                      ) : null }
                  </th>
                ) : null))}
                { onRowClick ? <th aria-label="Row Click" /> : null }
              </tr>

            ) : null}
          </React.Fragment>
          {/* Table Toolbar (in table head) Section */}
          {!disableFilters || !disableColumnSelect || CustomToolbarElements ? (
            <tr key="filters" className="filter-toolbar">
              <th colSpan={table.visibleColumns.length + (onRowClick ? 1 : 0)} aria-label="Toolbar">
                <div>
                  <span className="float-end">
                    <Stack direction="horizontal" gap={1}>
                      {CustomToolbarElements}
                      {refresh ? (
                        <Button
                          variant="secondary"
                          size={size ?? 'sm'}
                          onClick={refresh}
                        >
                          Refresh
                          {' '}
                          <Icon name="refresh-cw" size="12px" />
                        </Button>
                      ) : null }
                      {disableColumnSelect
                        ? null
                        : (
                          <Button
                            variant="secondary"
                            size={size ?? 'sm'}
                            onClick={handleOpenSelect}
                          >
                            Visible columns
                            {' '}
                            <Icon name="sidebar" size="12px" />
                          </Button>
                        )}
                      {!disableFilters ? (
                        <Button
                          variant="secondary"
                          className={hasActiveFilters ? 'has-selection' : ''}
                          size={size ?? 'sm'}
                          disabled={!hasActiveFilters}
                          onClick={async () => {
                            filters.setPage({
                              ...filters.query,
                              page: 1,
                            });
                            if (typeof resetCustomFilters === 'function') {
                              await resetCustomFilters();
                            }
                          }}
                        >
                          Clear filters
                          {' '}
                          <Icon name="x-square" size="12px" />
                        </Button>
                      ) : null}
                    </Stack>
                  </span>
                </div>
              </th>
            </tr>
          ) : null}
        </thead>
        {/* Table body section */}
        <tbody>
          { table.rows.length === 0
            ? (
              <tr className="no-content-row">
                <td colSpan={table.visibleColumns.length + (onRowClick ? 1 : 0)}>
                  { isLoading === true
                    ? <Spinner animation="border" />
                    : (emptyContent ?? <div className="p-1">No content...</div>) }
                </td>
              </tr>
            )
            : table.rows.map((row, rowIdx) => {
              const classNames = [] as string[];
              if (expand) classNames.push('border-bottom-0 head-row');

              const even = rowIdx % 2 === 0;

              const rowClassNames = [
                ...classNames,
                rowClassNameResolver ? rowClassNameResolver(row.original) : '',
              ];

              if (alternatingRowColors !== false) {
                rowClassNames.push(even ? 'even' : 'odd');
              }

              if (expand && ExpandElement && row.isExpanded) {
                rowClassNames.push('expanded');
              }

              if (rowHover === row.id) {
                rowClassNames.push('hover');
              }

              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...getRowClickProps(row)}
                    {...(rowProps ?? {})}
                    {...rowPropSelector && rowPropSelector(row.original)}
                    {...expand && (clickRowToToggleExpand ?? true) && getRowExpandProps(row)}
                    className={rowClassNames.join(' ')}
                    key={row.id}
                  >
                    {row.visibleCells.map((cell) => {
                      const cellClassNames = [
                        ...classNames,
                      ];
                      if (cell.columnDef.className) {
                        cellClassNames.push(cell.columnDef.className);
                      }
                      return (
                        <td
                          key={cell.id}
                          className={`${cellClassNames.join(' ')}`}
                        >
                          { renderMarkdown && typeof cell.value === 'string'
                            ? (
                              <RenderHtml allowedTags={['br', 'a']} allowedAttributes={['target']}>
                                {cell.value}
                              </RenderHtml>
                            )
                            : cell.render() }
                        </td>
                      );
                    })}
                    { onRowClick
                      ? (
                        <td className="text-end row-click">
                          <OverlayTrigger
                            overlay={<Tooltip>Open</Tooltip>}
                          >
                            <Button
                              className={`row-click ${rowHover === row.id ? 'hover' : ''}`}
                              variant="secondary"
                              onClick={(e) => { onRowClick(e, row.original); }}
                            >
                              <Icon name="arrow-right-circle" size="18" />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      )
                      : null }
                  </tr>
                  {expand && ExpandElement && row.isExpanded ? (
                    // eslint-disable-next-line no-nested-ternary
                    <tr className={`expand-row${row.isExpanded ? ' expanded' : ''} ${alternatingRowColors ? (even ? 'even' : 'odd') : ''}`}>
                      <td colSpan={table.visibleColumns.length + (onRowClick ? 1 : 0)} className="p-0 border-top-0">
                        <Collapse className="p-2" in={row.isExpanded}>
                          <div>
                            <ExpandElement row={row} even={even} />
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
        </tbody>
      </BTable>
      <SelectColumnsModal
        show={showSelect}
        handleClose={handleCloseSelect}
        columns={table.allColumns}
        resetColumnVisibility={() => setColumnVisibility(defaultColumnVisibility)}
      />
    </div>
  );
};

export const PagedTable = <TObject, TValue, TFilterValue, TQuery extends object, >({
  data,
  isLoading,
  columnDefs,
  disableFilters,
  disableColumnSelect,
  onRowClick,
  rowProps,
  rowPropSelector,
  rowClassNameResolver,
  CustomToolbarElements,
  clickRowToToggleExpand,
  size,
  disablePagination,
  refresh,
  hover,
  emptyContent,
  className,
  alternatingRowColors,
  renderMarkdown,
  noMargin,
  filters,
}: PagedTableProps<TObject, TValue, TFilterValue> & {
  filters: IPagedTableFilter<TQuery>,
  isLoading?: boolean,
  disablePagination?: boolean,
}) => {
  const { pagination, setPage: setCachedPage } = usePageCache(filters.id);

  const { setPage } = filters;
  const { pageSize } = filters.query;

  const loadAndUpdatePage = useCallback((loadPage:IPaginationMutableState) => {
    const pageToSet = {
      ...loadPage,
      // When changing page size, we need to convert current page accordingly
      page: pagination.pageSize !== loadPage.pageSize
        ? convertPageToNewPageSize(
          pagination.pageSize,
          loadPage.pageSize,
          loadPage.page,
          data?.rowCount ?? 0,
        )
        : loadPage.page,
    };
    setPage(pageToSet);
    setCachedPage(pageToSet);
  }, [data?.rowCount, pagination.pageSize, setCachedPage, setPage]);

  const onPaginationChange = async (newPagination:IPaginationMutableState) => (
    loadAndUpdatePage(newPagination)
  );

  const hidePagination = disablePagination || !data?.pageCount;

  return (
    <>
      <div className={`${isLoading ? 'mask-area' : 'd-none'}`} />

      {!hidePagination ? (
        <Pagination
          isLoading={isLoading}
          disabled={isLoading}
          page={data?.currentPage}
          pageSize={pageSize}
          totalPages={data?.pageCount}
          totalItemCount={data.rowCount}
          onPaginationChange={onPaginationChange}
          className="mb-3 paginator-top"
        />
      ) : null }
      <Table
        data={data}
        filters={filters}
        columnDefs={columnDefs}
        disableFilters={disableFilters}
        disableColumnSelect={disableColumnSelect}
        onRowClick={onRowClick}
        rowProps={rowProps}
        rowPropSelector={rowPropSelector}
        rowClassNameResolver={rowClassNameResolver}
        CustomToolbarElements={CustomToolbarElements}
        clickRowToToggleExpand={clickRowToToggleExpand}
        size={size}
        refresh={refresh}
        hover={hover}
        emptyContent={emptyContent}
        className={className}
        alternatingRowColors={alternatingRowColors}
        renderMarkdown={renderMarkdown}
        noMargin={noMargin}
        isLoading={isLoading}
      />
      {!hidePagination ? (
        <Pagination
          isLoading={isLoading}
          disabled={isLoading}
          page={data?.currentPage}
          pageSize={pageSize}
          totalPages={data?.pageCount}
          totalItemCount={data.rowCount}
          onPaginationChange={onPaginationChange}
        />
      ) : null }
    </>
  );
};

export default PagedTable;
