import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../query/GenericQuery';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { PagedResult } from '../../types/PagedResult';
import { IVulnerability, VulnerabilityStatus } from './Types';
import ROUTES from '../../routing/Routes';
import { useAccount } from '../../providers/AccountProvider';
import { Module } from '../../types/AccessTypes';
import { Direction, ISorting, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IVulnerabilityListOptions } from '../../types/Types';

export const MyVulnerabilitiesTable = ({
  sort,
}:{
  sort?:ISorting[],
}) => {
  const { user, hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
    'my-vulnerabilities',
    {},
    {
      initialQuery: {
        status: [VulnerabilityStatus.Open],
        assignedToId: user.id,
      },
      initialSorting: sort ?? [
        { property: 'severity', direction: Direction.desc },
        { property: 'updated', direction: Direction.desc },
      ],
    },
  );

  const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
    'vulnerabilities',
    {
      ...pagedTableFilter.query,
      assignedToId: user.id,
    },
  );

  return pagedVulnerabilities?.items.length === 0
    ? (
      <>
        <p>
          There are no vulnerabilities assigned to you.
        </p>
        { hasModuleRole(Module.vulnerability, 'read')
          ? (
            <Button
              onClick={() => navigate(ROUTES.vulnerabilities.uri)}
            >
              Show all vulnerabilities
            </Button>
          ) : null }
      </>
    ) : (
      <VulnerabilitiesTable
        data={pagedVulnerabilities}
        filters={pagedTableFilter}
        hide={{
          actions: true,
          risk: true,
          status: true,
          uniqueId: true,
          assignedTo: true,
        }}
        disableFilters
        skipFilterFromSearchParamInitialization
        disablePagination
      />
    );
};
