import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../query/GenericQuery';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { PagedResult } from '../../types/PagedResult';
import {
  IVulnerability, Severity, Significance, VulnerabilityStatus,
} from './Types';
import ROUTES from '../../routing/Routes';
import { Direction, ISorting, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IVulnerabilityListOptions } from '../../types/Types';

export const TopVulnerabilitiesTable = ({
  relativeSeverity,
  sorting,
}:{
  relativeSeverity?:Severity[],
  sorting?:ISorting[],
}) => {
  const navigate = useNavigate();

  const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
    'top-vulnerabilities',
    {},
    {
      initialQuery: {
        status: [VulnerabilityStatus.Open],
      },
      initialSorting: sorting ?? [
        { property: 'relativeSeverity', direction: Direction.desc },
      ],
    },
  );

  const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
    'vulnerabilities',
    {
      ...pagedTableFilter.query,
      status: 'open',
      relativeSeverity: relativeSeverity ?? [Significance.High, Significance.VeryHigh],
    },
  );

  return pagedVulnerabilities?.items.length === 0
    ? (
      <>
        <p>
          There are no critical vulnerabilities to worry about!
        </p>
        <Button
          onClick={() => navigate(ROUTES.vulnerabilities.uri)}
        >
          Show all vulnerabilities
        </Button>
      </>
    )
    : (
      <VulnerabilitiesTable
        data={pagedVulnerabilities}
        filters={pagedTableFilter}
        hide={{
          actions: true,
          risk: true,
          updated: true,
          status: true,
          uniqueId: true,
        }}
        disableFilters
        disablePagination
        skipFilterFromSearchParamInitialization
      />
    );
};
