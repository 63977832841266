import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import { Button } from 'react-bootstrap';
import { IPagedTableFilter } from '../../common/table/PagedResultFilter';
import { PagedResult } from '../../types/PagedResult';
import { ISecurityAdvisoryListOptions, ISecurityAdvisoryWithVulnerabilityCount } from '../../types/Types';
import { getSecurityAdvisoryUrl } from '../vulnerabilities/SoftwareTable';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { searchQueryKeys } from '../vulnerabilities/VulnerabilityFilter';
import ROUTES from '../../routing/Routes';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { createPagedColumnHelper, PagedTable } from '../../common/table';

export const SecurityAdvisoryTable = ({
  data,
  pagedTableFilter,
  disableFilters,
  disablePagination,
  disableColumnSelect,
  disableSorting,
}:{
  data: PagedResult<ISecurityAdvisoryWithVulnerabilityCount>,
  pagedTableFilter: IPagedTableFilter<ISecurityAdvisoryListOptions>,
  disableFilters?: boolean,
  disablePagination?: boolean,
  disableColumnSelect?: boolean,
  disableSorting?: boolean,
}) => {
  const navigate = useNavigate();
  const { hasModuleRole } = useAccount();

  const columnHelper = createPagedColumnHelper<ISecurityAdvisoryWithVulnerabilityCount>();

  const assetColumns = useMemo(() => {
    const columns = [
      columnHelper.accessor(
        'id',
        {
          header: 'Id',
          cell: ({ value }) => {
            const externalUri = getSecurityAdvisoryUrl(value);

            return externalUri
              ? (
                <Link target="_blank" to={externalUri}>
                  {value}
                  <Icon name="external-link" size="16" className="ms-1" />
                </Link>
              ) : <ClipboardCopy>{value}</ClipboardCopy>;
          },
          disableFilter: !disableFilters,
          disableSorting,
          filterPropertyName: 'id',
          sortPropertyName: 'id',
          updateFilterFn: pagedTableFilter
            ? (values:string[]) => {
              pagedTableFilter.appendQuery({
                id: values ? values[0] : undefined,
              });
            }
            : undefined,
        },
      ),
      columnHelper.accessor(
        'name',
        {
          header: 'Name',
          disableFilter: !disableFilters,
          disableSorting,
          filterPropertyName: 'name',
          sortPropertyName: 'name',
          updateFilterFn: pagedTableFilter
            ? (values:string[]) => {
              pagedTableFilter.appendQuery({
                name: values ? values[0] : undefined,
              });
            }
            : undefined,
        },
      ),
      columnHelper.accessor(
        'assetCount',
        {
          cell: ({ value, row }) => {
            const searchParams = new URLSearchParams();
            searchParams.set(searchQueryKeys.securityAdvisory, row.original.id);

            return (
              <Button
                variant="link"
                onClick={() => {
                  navigate({
                    pathname: ROUTES.assets.uri,
                    search: searchParams.toString(),
                  });
                }}
              >
                {value}
                <Icon name="arrow-right-circle" className="ms-1" size="18" />
              </Button>
            );
          },
          header: 'Asset count',
          disableFilter: true,
          disableSorting,
          filterPropertyName: 'assetCount',
          sortPropertyName: 'assetCount',
        },
      ),
    ];

    if (hasModuleRole(Module.vulnerability, 'read')) {
      columns.push({
        ...columnHelper.accessor(
          'vulnerabilityCount',
          {
            header: 'Vulnerability count',
            cell: ({ value, row }) => {
              const searchParams = new URLSearchParams();
              searchParams.set(searchQueryKeys.securityAdvisory, row.original.id);

              return (
                <Button
                  variant="link"
                  onClick={() => {
                    navigate({
                      pathname: ROUTES.vulnerabilities.uri,
                      search: searchParams.toString(),
                    });
                  }}
                >
                  {value}
                  <Icon name="arrow-right-circle" className="ms-1" size="18" />
                </Button>
              );
            },
            disableFilter: true,
            disableSorting,
            filterPropertyName: 'vulnerabilityCount',
            sortPropertyName: 'vulnerabilityCount',
          },
        ),
      });
    }

    return columns;
  }, [columnHelper, disableFilters, pagedTableFilter, disableSorting, hasModuleRole, navigate]);

  return (
    <PagedTable
      columnDefs={assetColumns}
      data={data}
      filters={pagedTableFilter}
      disablePagination={disablePagination}
      disableColumnSelect={disableColumnSelect}
      disableFilters={disableFilters}
    />
  );
};
