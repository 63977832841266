import React, { useCallback, useMemo } from 'react';
import {
  Alert, Button, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import { getOrFetchFromApi } from '../../query/GenericQuery';
import { IControl } from '../vulnerabilities/Types';
import { IAssessment, IAssessmentSyncAsset } from './AssessmentTypes';
import { PagedResult } from '../../types/PagedResult';
import { IAsset } from '../../types/AssetsTypes';
import AssessmentControlHeaderCard from './AssessmentControlHeaderCard';
import AssessmentStepCard from './AssessmentStepCard';
import AssessmentStartCard from './AssessmentStartCard';
import { urlEncodeBase64 } from '../../utils/StringUtils';
import { createAssessmentStateManager } from './AssessmentStateManager';
import { SkippedQuestionsTable } from './SkippedQuestionsTable';
import { useAssessmentStateStore } from './AssessmentStateStore';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ValidPageSizes } from '../../common/table/Pagination';

interface IData {
  assets:IAsset[],
  assessment:IAssessment,
  controlMap:Record<string, IControl>,
  sha512:string|undefined
}

interface IArgs {
  sha512:string,
  requestedLibraryFriendlyId:string|null
}

export const AssessmentPage:IComponentWithLoader<IData, IArgs> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:IArgs|undefined,
  ) => {
    const assessment = await getOrFetchFromApi<IAssessment>(
      queryClient,
      args?.sha512 && `module/assessment/${urlEncodeBase64(args.sha512)}`,
    );

    const assets = (await getOrFetchFromApi<PagedResult<IAsset>>(queryClient, 'assets')).items;

    const libraryFriendlyId = args?.requestedLibraryFriendlyId ?? assessment.library.id;

    // Fetch the control library alias. The alias map is keyed by the main
    // `ivolv` control library id's, referenced in the assessment, but contains
    // the alias framework control definitions.
    const controlMap = await getOrFetchFromApi<Record<string, IControl>>(
      queryClient,
      libraryFriendlyId && `controls/${libraryFriendlyId}/alias`,
    );

    return {
      assessment,
      sha512: args?.sha512,
      controlMap,
      assets,
    };
  },
  Component: () => {
    const { customer } = useAccount();

    const {
      assessment, assets, controlMap, sha512,
    } = useLoaderData() as Awaited<IData>;

    const showAssetCompleteToast = useCallback((
      asset:IAssessmentSyncAsset,
      hasSkippedQuestions:boolean,
    ) => {
      const toastId = `assessment-${asset}`;
      if (!hasSkippedQuestions) {
        toast.success(
          (
            <div>
              Assessment of
              {' '}
              { asset ? <code>{asset.name}</code> : 'your asset'}
              {' '}
              is done.
              <br />
              <br />
              The result is added to your assessment and will be saved when the assessment is completed.
            </div>
          ),
          {
            toastId,
            autoClose: 20000,
            updateId: toastId,
          },
        );
      } else {
        toast.warning(
          (
            <div>
              Assessment of
              {' '}
              { asset ? <code>{asset.name}</code> : 'your asset'}
              {' '}
              is partially done.
              <br />
              <br />
              There are skipped questions that you&apos;ll have to address before the assessment can be completed.
            </div>
          ),
          {
            toastId,
            autoClose: 20000,
            updateId: toastId,
          },
        );
      }
    }, []);

    const managedState = useAssessmentStateStore(customer, sha512);
    const stateManager = useMemo(() => (
      createAssessmentStateManager(assessment, managedState, showAssetCompleteToast)
    ), [assessment, managedState, showAssetCompleteToast]);

    const getControlDetails = useMemo(() => (friendlyId:string) => (
      (controlMap && controlMap[friendlyId] ? controlMap[friendlyId] : undefined)
    ), [controlMap]);

    if (!stateManager || !stateManager.isReady()) {
      return <Spinner animation="border" />;
    }

    return managedState.hasStateSaveError
      ? (
        <Row>
          <Col mb={12}>
            <Alert variant="danger" className="p-3">
              <Icon name="alert-triangle" className="me-3" />
              <div>
                <h4>Unable to save state!</h4>
                Please contact Ivolv support if the problem persists.
                <Button
                  className="mt-2 d-block"
                  onClick={() => window.location.reload()}
                >
                  Retry
                </Button>
              </div>
            </Alert>
          </Col>
        </Row>
      )
      : (
        <Row className="assessment">
          {
            stateManager.hasActiveQuestion()
              ? (
                <>
                  <Col md={4}>
                    <AssessmentControlHeaderCard
                      getControlDetails={getControlDetails}
                      stateManager={stateManager}
                    />
                  </Col>
                  <Col md={8}>
                    <AssessmentStepCard
                      stateManager={stateManager}
                    />
                  </Col>
                </>
              )
              : (
                <Col md={12}>
                  { sha512
                    ? (
                      <AssessmentStartCard
                        stateManager={stateManager}
                        sha512={sha512}
                        customerAssets={assets}
                      />
                    )
                    : null }
                </Col>
              )
          }
          { !stateManager.hasActiveQuestion() && stateManager.getSkippedQuestionsCount() > 0
            ? (
              <Col md={12}>
                <Card>
                  <Card.Header>Skipped questions</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <SkippedQuestionsTable
                          stateManager={stateManager}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )
            : null }
        </Row>
      );
  },
};
