/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import RenderHtml from '../../components/RenderHtml';
import {
  formatDateTimes, getStringDate, getUTCStringDateOnly,
} from '../../utils/StringUtils';
import {
  IContent, ITableContentBody,
} from './Types';
import { identifyDataType } from '../../utils/Utils';
import { PagedTable, usePagedTableFilter, useArrayAsPagedResult } from '../../common/table';

/*
const findAppropriateFilter = (
  dataType:IDataTypeDetection,
  max:number|undefined,
):FilterFn<unknown>|'includesString'|'arrIncludes' => {
  if (dataType.isBoolean) {
    return arrIncludesSomeWithEmptyFixFn;
  }
  if (dataType.isDateString) {
    return dateStringFilterFn;
  }

  if (dataType.isInt) {
    return max && max > 10 ? rangeSelectFilterV8 : arrIncludesSomeWithEmptyFixFn;
  }

  if (dataType.isFloat) {
    return rangeSelectFilterV8;
  }

  return 'includesString';
};
*/

export const RenderContentSegment = ({
  id,
  content,
}:{
  id:string,
  content:IContent
}) => {
  const segmentId = `content-segment-${id}`;

  const tableFilter = usePagedTableFilter(
    segmentId,
    {},
  );

  const { appendQuery } = tableFilter;

  const [tableColumns, tableData] = useMemo(() => {
    if (content.type === 'table') {
      const mTableContent = content.body as ITableContentBody;

      if (!mTableContent?.headers) {
        return [undefined, undefined];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mTableData = [] as any;
      mTableContent.rows.forEach((r, rowIndex) => {
        mTableData[rowIndex] = {};
      });

      const columns = mTableContent.headers.map((h, columnIdx) => {
        const dataType = identifyDataType(mTableContent.rows.map((row) => row[columnIdx]));

        if (dataType.isInt) {
          mTableContent.rows.forEach((r, rowIdx) => {
            const val = r[columnIdx];
            mTableData[rowIdx][columnIdx] = val
              ? parseInt(val, 10)
              : null;
          });
        } else if (dataType.isFloat) {
          mTableContent.rows.forEach((r, rowIdx) => {
            const val = r[columnIdx];
            mTableData[rowIdx][columnIdx] = val
              ? parseFloat(val)
              : null;
          });
        } else if (dataType.isDateString) {
          mTableContent.rows.forEach((r, rowIdx) => {
            const val = r[columnIdx];
            mTableData[rowIdx][columnIdx] = val
              ? dataType.dateHasTime
                ? getStringDate(val)
                : getUTCStringDateOnly(val)
              : null;
          });
        } else {
          mTableContent.rows.forEach((r, rowIdx) => {
            mTableData[rowIdx][columnIdx] = r[columnIdx];
          });
        }

        return {
          id: `${columnIdx}`,
          accessorKey: `${columnIdx}`,
          header: h,
          enableColumnFilter: true,
          updateFilterFn: dataType.isDateString
            ? undefined
            : (values:unknown[]) => {
              appendQuery({
                [columnIdx]: values.length ? values[0] : undefined,
              });
            },
        };
      });

      return [columns, mTableData];
    }
    return [undefined, undefined];
  }, [appendQuery, content.body, content.type]);

  const pagedResult = useArrayAsPagedResult(
    tableData,
    tableFilter,
    tableColumns,
  );

  switch (content.type) {
  case 'table':
    return tableColumns && pagedResult
      ? (
        <PagedTable
          className="mb-0"
          columnDefs={tableColumns}
          data={pagedResult}
          filters={tableFilter}
          renderMarkdown
          noMargin
          disableColumnSelect
        />
      )
      : null;
  default:
    return (
      <RenderHtml allowedTags={['a', 'br']} allowedAttributes={['target']}>
        {formatDateTimes(content.body.toString())}
      </RenderHtml>
    );
  }
};
