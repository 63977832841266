/* eslint-disable no-nested-ternary */
import React, {
  ReactElement, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';

export interface IModalBodyAndFooterProps {
  close: (confirmed:boolean, value?:unknown|undefined) => void,
  value: unknown,
  setValue: (value:unknown) => void,
}

interface IModal {
  backdrop?:boolean,
  size?: 'sm'|'lg'|'xl',
  title: string,
  ModalBodyAndFooter: (props:IModalBodyAndFooterProps) => JSX.Element,
  nofocus?: boolean,
  value?:unknown,
}

interface IConfirm {
  title?: string,
  content?: ReactElement|string,
  okLabel?: string,
  cancelLabel?: string,
}

interface IExtendedModal extends IModal {
  internalClose:(confirmed:boolean, value:unknown) => void,
}

const modalStack:IExtendedModal[] = [];

export interface IModalContext {
  pushModal: (modal:IModal) => Promise<unknown|undefined>,
  pushConfirm: (confirm:IConfirm) => Promise<boolean>,
}

const ModalContext = createContext<IModalContext | undefined>(undefined);

/**
   * Provider function to push a modal onto the stack.
   *
   * Since we can only show one modal at a time, we need to keep track of
   * the modal data in a stack. When a new modal is pushed, the current modal
   * data is pushed to the modal stack. When the new modal is closed, the
   * previous modal state is restored from the modal stack.
   */
const pushModal = (
  activateModal:() => void,
  modal:IModal,
) : Promise<unknown|undefined> => {
  let resolver:(value:unknown|undefined) => void;
  const promise = new Promise<unknown|undefined>((resolve) => {
    resolver = resolve;
  });

  modalStack.push({
    ...modal,
    value: modal.value,
    internalClose: (confirmed:boolean, value?:unknown|undefined) => {
      setTimeout(() => {
        if (resolver) resolver(confirmed ? value : undefined);
      });
      modalStack.pop();
      activateModal();
    },
  });
  activateModal();
  return promise;
};

const pushConfirm = (
  activateModal:() => void,
  confirm:IConfirm,
) : Promise<boolean> => {
  let resolver:(value:boolean) => void;
  const promise = new Promise<boolean>((resolve) => {
    resolver = resolve;
  });

  modalStack.push({
    title: confirm.title ?? 'Please confirm',
    ModalBodyAndFooter: ({ close }: IModalBodyAndFooterProps) => (
      <>
        <Modal.Body>{confirm.content}</Modal.Body>
        <Modal.Footer>
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="primary"
              onClick={async () => { close(true, true); }}
            >
              { confirm.okLabel ?? 'Ok' }
            </Button>
            <Button
              variant="secondary"
              onClick={async () => { close(true, false); }}
            >
              { confirm.cancelLabel ?? 'Cancel' }
            </Button>
          </Stack>
        </Modal.Footer>
      </>
    ),
    internalClose: (confirmed:boolean, value?:unknown|undefined) => {
      setTimeout(() => {
        if (resolver) resolver(confirmed && value as boolean);
      });
      modalStack.pop();
      activateModal();
    },
  });
  activateModal();
  return promise;
};

/**
 * A provider which adds a ConfirmModal to the "global" context.
 * Call openConfirm from useGlobalContext to open a confirm dialog.
 */
export const NewModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { Provider } = ModalContext;

  const [activeModal, setActiveModal] = useState<IExtendedModal>();
  const [activeValue, setActiveValue] = useState<unknown>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const activateModal = useCallback(() => {
    if (modalStack.length) {
      setModalVisible(false);
    }
    const modal = modalStack[modalStack.length - 1];

    // If we're pushing a modal ontop of an existing one, leave a few millis
    // between before showing the new modal to allow for a slight animation.
    setTimeout(() => {
      setActiveValue(modal?.value);
      setActiveModal(modal);
      setModalVisible(true);
    }, modalStack.length > 1 ? 100 : 0);
  }, []);

  const closeActiveModal = () => {
    modalStack.pop();
    activateModal();
  };

  useEffect(() => {
    if (activeValue && modalStack.length) {
      modalStack[modalStack.length - 1].value = activeValue;
    }
  }, [activeValue]);

  const ModalBodyAndFooter = activeModal?.ModalBodyAndFooter;

  const modalRef = useRef<HTMLDivElement>(null);

  const pushFns = useMemo(() => ({
    pushModal: async (modal:IModal) => pushModal(activateModal, modal),
    pushConfirm: async (confirm:IConfirm) => pushConfirm(activateModal, confirm),
  }), [activateModal]);

  return (
    <Provider value={{
      pushModal: pushFns.pushModal,
      pushConfirm: pushFns.pushConfirm,
    }}
    >
      {children}

      { activeModal && ModalBodyAndFooter
        ? (
          <Modal
            show={modalVisible}
            onHide={async () => {
              closeActiveModal();
            }}
            onShow={() => {
              if (activeModal.nofocus === true) return;
              const hasDialog = modalRef.current as unknown as {dialog:HTMLDivElement}|null;
              const firstInput = hasDialog?.dialog.querySelector('.modal-body input, .modal-body textarea, .modal-body button') as HTMLElement;
              if (firstInput) {
                firstInput.focus();
              }
            }}
            ref={modalRef}
            backdrop
            size={activeModal?.size ?? 'sm'}
          >
            <Modal.Header
              closeButton
            >
              <Modal.Title>
                {activeModal.title}
              </Modal.Title>
            </Modal.Header>
            <ModalBodyAndFooter
              close={activeModal.internalClose}
              setValue={setActiveValue}
              value={activeValue}
            />
          </Modal>
        )
        : null }
    </Provider>
  );
};

export const useNewModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('No ModalContext found when calling useNewModalContext');
  }
  return context;
};
