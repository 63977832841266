import React, { useMemo } from 'react';
import {
  Badge, Button, Col, Form, Modal, Row, Spinner,
  Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useApi } from '../../query/GenericQuery';
import { Direction, ISorting, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { PagedTable, createPagedColumnHelper } from '../../common/table';
import { PagedResult } from '../../types/PagedResult';
import { useTranslation } from '../../providers/TranslationProvider';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { IUser } from '../../types/AccessTypes';
import { asNameUpn, urlEncodeBase64 } from '../../utils/StringUtils';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { CodeBox } from '../../common/CodeBox';
import { useDownloadFromApi } from '../../common/useDownloadFromApi';
import { TableCellDateFormatted } from '../../common/table/TableHelpers';

type deliveryStatus = 'pending'|'inProgress'|'failed'|'sent';

interface INotificationAttachment {
  fileName:string,
  lastModifiedDateTime:string,
  sha512:string,
  id:string
}

interface INotificationDelivery {
  id: string,
  toAccountId?: string,
  toAccount?: IUser,
  fromAccount?: IUser,
  fromAccountId?: string,
  status: deliveryStatus,
  created: string,
  updated?: string,
  processed?: string,
  address: string,
  error?: string,
  type: string,
  subject: string,
  body: string,
  why?: string,
  attachments?: INotificationAttachment[]
}

interface INotificationDeliveryListOptions {
  id?: string,
  address?: string,
  toAccount?: string,
  subject?: string,
  body?: string,
  status?: deliveryStatus[],
  attachment?: string
}

const defaultSort:ISorting[] = [
  { property: 'created', direction: Direction.desc },
];

const statusAsCssClassName = (status:deliveryStatus) => {
  switch (status) {
  case 'sent':
    return 'success';
  case 'failed':
    return 'danger';
  case 'inProgress':
    return 'primary';
  case 'pending':
  default:
    return 'secondary';
  }
};

export const AdminNotificationsMessageLogTab = () => {
  const pagedTableFilter = usePagedTableFilter<INotificationDeliveryListOptions>(
    'notification-log-page',
    {},
    { initialSorting: defaultSort },
  );

  const { appendQuery, query } = pagedTableFilter;
  const { pushModal } = useNewModalContext();

  const {
    data: pagedResult,
    isLoading,
    invalidate: invalidateNotifications,
  } = useApi<PagedResult<INotificationDelivery>>(
    'module/admin/notifications',
    query,
  );

  const i18n = useTranslation();
  const columnHelper = createPagedColumnHelper<INotificationDelivery>();
  const download = useDownloadFromApi();

  const tableColumns = useMemo(() => [
    columnHelper.accessor(
      'id',
      {
        header: 'Id',
        defaultHidden: true,
        cell: ({ value }) => <code><ClipboardCopy>{value}</ClipboardCopy></code>,
        updateFilterFn: (values: string[]) => {
          if (appendQuery) {
            appendQuery({
              id: values[0],
            });
          }
        },
        filterPropertyName: 'id',
      },
    ),
    columnHelper.accessor(
      'type',
      {
        header: 'Message type',
        cell: ({ value }) => {
          switch (value) {
          case 'email':
            return <Icon name="mail" size="20" />;
          default:
            return value;
          }
        },
        defaultHidden: true,
        disableFilter: true,
      },
    ),
    columnHelper.accessor(
      'fromAccount',
      {
        header: 'From account',
        defaultHidden: true,
        cell: ({ value: account }) => {
          if (!account) return null;

          return (
            <>
              <div>
                {asNameUpn(account)}
              </div>
              <div>
                <code><ClipboardCopy>{account.id}</ClipboardCopy></code>
              </div>
            </>
          );
        },
        filterPropertyName: 'fromAccount',
        updateFilterFn: (values: string[]) => {
          if (appendQuery) {
            appendQuery({
              toAccount: values[0],
            });
          }
        },
      },
    ),
    columnHelper.accessor(
      'address',
      {
        header: 'To address',
        cell: ({ value }) => (
          <ClipboardCopy>
            {value}
          </ClipboardCopy>
        ),
        filterPropertyName: 'address',
        updateFilterFn: (values: string[]) => {
          if (appendQuery) {
            appendQuery({
              address: values[0],
            });
          }
        },
      },
    ),
    columnHelper.accessor(
      'attachments',
      {
        header: 'Attachment',
        defaultHidden: true,
        cell: ({ value: attachments }) => (attachments != null
          ? attachments.map((a) => (
            <Button
              key={a.sha512}
              variant="link"
              className="text-start"
              onMouseUp={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await download(`files/${urlEncodeBase64(a.sha512)}`);
              }}
            >
              {a.fileName}
              <Icon name="download" className="ms-2" size="16" />
            </Button>
          )) : null),
        filterPropertyName: 'attachment',
        updateFilterFn: (values: string[]) => {
          if (appendQuery) {
            appendQuery({
              attachment: values[0],
            });
          }
        },
      },
    ),
    columnHelper.accessor(
      'toAccount',
      {
        header: 'To account',
        defaultHidden: true,
        cell: ({ value: account }) => {
          if (!account) return null;

          return (
            <>
              <div>
                {asNameUpn(account)}
              </div>
              <div>
                <code><ClipboardCopy>{account.id}</ClipboardCopy></code>
              </div>
            </>
          );
        },
        filterPropertyName: 'toAccount',
        updateFilterFn: (values: string[]) => {
          if (appendQuery) {
            appendQuery({
              toAccount: values[0],
            });
          }
        },
      },
    ),
    columnHelper.accessor(
      'subject',
      {
        header: 'Subject',
        sortPropertyName: 'message.subject',
        filterPropertyName: 'subject',
        updateFilterFn: (values: string[]) => {
          if (appendQuery) {
            appendQuery({
              subject: values[0],
            });
          }
        },
      },
    ),
    columnHelper.accessor(
      'status',
      {
        header: 'Status',
        cell: ({ value: status }) => (
          <Badge
            style={{ fontSize: '0.7rem' }}
            bg={`${statusAsCssClassName(status)}`}
          >
            {i18n.getString(`notification-delivery-status.${status}`)}
          </Badge>
        ),
        filterPropertyName: 'status',
        updateFilterFn: (values: deliveryStatus[]) => {
          if (appendQuery) {
            appendQuery({
              status: values,
            });
          }
        },
        supportMultiSelect: true,
        selectOptions: [
          'failed',
          'sent',
          'pending',
        ] as deliveryStatus[],
      },
    ),
    columnHelper.accessor(
      'error',
      {
        header: 'Error',
        disableFilter: true,
        defaultHidden: true,
        sortPropertyName: 'error',
      },
    ),
    columnHelper.accessor(
      'created',
      {
        header: 'Created',
        cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
        disableFilter: true,
      },
    ),
    columnHelper.accessor(
      'updated',
      {
        header: 'Updated',
        defaultHidden: true,
        cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
        disableFilter: true,
      },
    ),
    columnHelper.accessor(
      'processed',
      {
        header: 'Processed',
        cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
        disableFilter: true,
      },
    ),
  ], [appendQuery, columnHelper, download, i18n]);

  return pagedResult ? (
    <PagedTable
      columnDefs={tableColumns}
      data={pagedResult}
      filters={pagedTableFilter}
      isLoading={isLoading}
      onRowClick={async (_, notification) => {
        pushModal({
          title: notification.subject,
          size: 'lg',
          ModalBodyAndFooter: ({ close }) => (
            <>
              <Modal.Body>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Label>Subject:</Form.Label>
                    <div className="form-control">
                      {notification.subject}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Label>Body:</Form.Label>
                    <CodeBox text={notification.body} />
                  </Col>
                  { notification.why
                    ? (
                      <Col md={12} className="mb-3">
                        <Form.Label>Why:</Form.Label>
                        <CodeBox text={notification.why} />
                      </Col>
                    ) : null }
                  { notification.attachments?.length
                    ? (
                      <Col md={12} className="mb-3">
                        <>
                          <Form.Label>Attachments:</Form.Label>
                          <Stack direction="vertical">
                            { notification.attachments.map((a) => (
                              <Button
                                key={a.sha512}
                                variant="link"
                                className="text-start"
                                onClick={() => {
                                  download(`files/${urlEncodeBase64(a.sha512)}`);
                                }}
                              >
                                <Icon name="download" className="me-2" />
                                {a.fileName}
                              </Button>
                            )) }
                          </Stack>
                        </>
                      </Col>
                    ) : null }
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Stack direction="horizontal" gap={2}>
                  <Button onClick={() => { close(true); }}>Close</Button>
                </Stack>
              </Modal.Footer>
            </>
          ),
        });
      }}
      refresh={invalidateNotifications}
    />
  )
    : <Spinner animation="border" />;
};
