/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import {
  Button, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { QueryClient, queryOptions } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import RiskOverviewChart from './RiskOverviewChart';
import { RiskSummaryMatrix } from './RiskMatrix';
import { IRiskFilters, RiskTable } from './RiskTable';
import { IRisk, RiskStatus } from '../../types/RiskTypes';
import RiskForm from './RiskForm';
import { FormModal } from '../../common/modal';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { Severity, Significance } from '../vulnerabilities/Types';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { VisibilityState } from '../../common/table/useTableModel';

export const RiskPage:IComponentWithLoader<PagedResult<IRisk>, undefined> = {
  loader: async (queryClient:QueryClient) => (
    getOrFetchFromApi<PagedResult<IRisk>>(
      queryClient,
      'risks',
    )
  ),
  Component: () => {
    const { hasModuleRole, hasAnyModuleRole } = useAccount();
    const [searchParams] = useSearchParams();

    const [selectedRisk, setSelectedRisk] = useState<IRisk>();
    const [show, setShow] = useState(false);

    const handleOpen = (risk?: IRisk) => {
      setSelectedRisk(risk);
      setShow(true);
    };
    const handleClose = () => setShow(false);

    const defaultColumnVisibility:VisibilityState = {
      owner: false,
      response: false,
      probability: false,
      impact: false,
      created: false,
      updated: false,
      status: false,
    };

    const tableFilters = usePagedTableFilter<IRiskFilters>(
      'risk-table',
      {},
      {
        initialQuery: searchParams.has('severity')
          ? { severity: searchParams.get('severity') as Severity }
          : searchParams.has('impact') && searchParams.has('probability')
            ? {
              impacts: [searchParams.get('impact') as Significance],
              probabilities: [searchParams.get('probability') as Significance],
            } : {},
        initialSorting: [
          { property: 'status', direction: Direction.desc },
          { property: 'severity', direction: Direction.desc },
        ],
      },
    );
    const { appendQuery } = tableFilters;

    const { data: risks } = useApiLoaderData<PagedResult<IRisk>, PagedResult<IRisk>>(
      'risks',
      (loaderData) => loaderData,
      queryOptions,
    );

    const onSeverityFilterChanged = (selectedSeverities:Severity[]) => {
      appendQuery({
        severity: selectedSeverities.length ? selectedSeverities[0] : undefined,
        probabilities: undefined,
        impacts: undefined,
        status: selectedSeverities.length ? RiskStatus.Open : undefined,
      });
    };

    return (
      <div>
        <Row>
          <Col sm={6}>
            <Card className="flex-fill w-100 fixed-height-card">
              <Card.Header>
                <Card.Title className="mb-0">
                  Risk overview
                </Card.Title>
              </Card.Header>
              <Card.Body className="py-3">
                { !risks
                  ? <Spinner animation="border" />
                  : (
                    <RiskOverviewChart
                      risks={risks.items}
                      onClick={onSeverityFilterChanged}
                      activeSeverity={tableFilters.query.severity}
                    />
                  )}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card className="flex-fill w-100 fixed-height-card">
              <Card.Header>
                <Card.Title className="mb-0">Risk matrix</Card.Title>
              </Card.Header>
              <Card.Body className="py-3 overflow-auto">
                { risks
                  ? (
                    <RiskSummaryMatrix
                      risks={risks.items}
                      onClick={(item) => (
                        appendQuery(tableFilters.query.impacts?.includes(item.impact)
                        && tableFilters.query.probabilities?.includes(item.probability)
                          ? {
                            impacts: undefined,
                            probabilities: undefined,
                            status: undefined,
                          }
                          : {
                            impacts: [item.impact],
                            probabilities: [item.probability],
                            severity: undefined,
                            status: RiskStatus.Open,
                          })
                      )}
                      cellClassName={(item) => (
                        tableFilters.query.impacts?.includes(item.impact)
                        && tableFilters.query.probabilities?.includes(item.probability)
                          ? 'highlight'
                          : ''
                      )}
                    />
                  )
                  : <Spinner animation="border" />}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card flex-fill w-100">
              <Card.Header>
                <Card.Title className="mb-0">
                  Identified risks
                </Card.Title>
              </Card.Header>
              <Card.Body className="overflow-auto">
                <>
                  <Row>
                    <Col md={12} className="mb-3">
                      <RiskTable
                        risks={risks.items}
                        openForm={handleOpen}
                        defaultColumnVisibility={defaultColumnVisibility}
                        tableFilters={tableFilters}
                      />
                    </Col>
                  </Row>
                  { hasModuleRole(Module.riskUnmanaged, 'readWrite') ? (
                    <Row>
                      <Col md={12}>
                        <Button variant="primary" onClick={() => handleOpen(undefined)}>Add risk</Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        { hasAnyModuleRole(Module.riskUnmanaged)
          ? (
            <FormModal
              itemType={selectedRisk ? `risk ${selectedRisk.riskId}` : 'risk'}
              isAdd={!selectedRisk}
              show={show}
              handleClose={handleClose}
              Form={<RiskForm risk={selectedRisk} handleClose={handleClose} />}
            />
          )
          : null }
      </div>
    );
  },
};

export default RiskPage;
