/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Badge, Dropdown, DropdownButton, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import {
  ControlAction, IActionTarget, IControl, IControlDetails, IVulnerability, Severity, Significance, VulnerabilityStatus,
} from './Types';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { asNameUpn, getCveUri, getStringDate } from '../../utils/StringUtils';
import ControlRemediateModal from './ControlRemediateModal';
import ControlUndoModal from './ControlUndoModal';
import { useTranslation } from '../../providers/TranslationProvider';
import { IRisk } from '../../types/RiskTypes';
import RenderHtml from '../../components/RenderHtml';
import VulnerabilityStatusBadge from './VulnerabilityStatusBadge';
import {
  useGetAssetNameAsText, useGetSeverityAsText, useVulnerabilityStatusAsText,
} from '../../utils/TranslationUtils';
import { IUser, Module } from '../../types/AccessTypes';
import { severityAsCssClassName } from '../vulnerabilities/Utils';
import { IJob, IVulnerabilityListOptions } from '../../types/Types';
import { startAutomationJobAndAwaitResult } from '../../utils/AutomationJobRunner';
import { useAccount } from '../../providers/AccountProvider';
import ROUTES from '../../routing/Routes';
import { useModules } from '../../providers/ModuleProvider';
import { PagedResult } from '../../types/PagedResult';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { getSecurityAdvisoryUrl } from './SoftwareTable';
import {
  createPagedColumnHelper, PagedTable, IPagedTableFilter, useAllVulnerabilityModuleOptions,
} from '../../common/table';

interface IAction {
  name: ControlAction;
  action: () => void;
}

interface IActionContext {
  vulnerability: IVulnerability;
  timestamp?: Date;
  name: ControlAction;
}

const startAnalyzis = async (
  vuln: IVulnerability,
  controlDetails: IControlDetails,
) => (
  await axios.post<IJob>(
      `/api/v1/module/vulnerabilityJobs/controlStart/${
        vuln.sourceModuleId
      }/execute-control-action/${vuln.asset.friendlyId}/${
        controlDetails?.frameworkFriendlyId
      }/${controlDetails?.friendlyId}/${
        vuln.uniqueId ? `${vuln.uniqueId}/` : ''
      }analyze`,
  )
).data;

const VulnerabilityActionDropdown = (params: {
  vuln: IVulnerability;
  actions: IAction[];
  active: string | undefined;
}) => {
  const { actions, vuln, active } = params;
  const i18n = useTranslation();

  return !actions.length ? null : (
    <DropdownButton
      id={`actions-${vuln.id}`}
      drop="down-centered"
      align="end"
      title="Actions"
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    >
      {actions.map((a) => (
        <Dropdown.Item
          key={a.name}
          disabled={a.name === active}
          onClick={(ev) => {
            ev.stopPropagation();
            a.action();
          }}
        >
          {i18n.getString(`vulnerability.action.${a.name}`)}
          {a.name === active ? (
            <Spinner animation="grow" size="sm" className="ms-2" />
          ) : null}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

const getVulnerabilityActions = (
  actionTargets: IActionTarget[],
  vuln: IVulnerability,
  control: IControlDetails,
  setActiveContext: (context: IActionContext | undefined) => void,
  invalidateQueries: () => void,
  signal: AbortSignal | undefined,
) => {
  const actions = [] as IAction[];

  // TODO: limited to m365 for now, open after we've tested with another module
  if (vuln.sourceModuleId !== Module.m365) {
    return actions;
  }

  const supportAction = (action: ControlAction) => !!actionTargets
    && !!actionTargets.find(
      (a) => a.action === action && a.uniqueId === vuln.uniqueId,
    );

  if (
    supportAction('impact')
    && supportAction('configure')
    && vuln.status === VulnerabilityStatus.Open
  ) {
    actions.push({
      name: 'configure',
      action: () => {
        setActiveContext({ name: 'configure', vulnerability: vuln });
      },
    });
  }

  if (supportAction('revert')) {
    actions.push({
      name: 'revert',
      action: async () => {
        const { data: timestamp } = await axios.get<Date>(
          `/api/v1/module/vulnerabilityJobs/revertTimestamp/${
            vuln.asset.friendlyId
          }/${control.frameworkFriendlyId}/${control.friendlyId}${
            vuln.uniqueId ? `/${vuln.uniqueId}` : ''
          }`,
        );

        if (!timestamp) {
          toast.warn(
            'Control has no undo configuration and changes cannot be reverted',
          );
          return;
        }

        setActiveContext({ name: 'revert', vulnerability: vuln, timestamp });
      },
    });
  }

  if (supportAction('analyze')) {
    actions.push({
      name: 'analyze',
      action: async () => {
        setActiveContext({ name: 'analyze', vulnerability: vuln });
        try {
          toast.info('Started update', {
            toastId: 'analysis-status',
          });
          try {
            await startAutomationJobAndAwaitResult(
              () => startAnalyzis(vuln, control),
              signal,
            );
            toast.success('Vulnerability analysis has been updated', {
              toastId: 'analysis-status',
              updateId: 'analysis-status',
            });
            invalidateQueries();
          } finally {
            setActiveContext(undefined);
          }
        } catch {
          // Ignore
        }
      },
    });
  }

  return actions as IAction[];
};

const getRiskUri = (risk: IRisk) => `/risk/${encodeURIComponent(risk.riskId)}`;
const getControlUri = (
  control: IControl,
) => `/control/${encodeURIComponent(control.id)}`;

export interface IVulnerabilityColumns {
  id?: string;
  status?: boolean;
  created?: boolean;
  updated?: boolean;
  asset?: boolean;
  details?: boolean;
  summary?: boolean;
  risk?: boolean;
  control?: boolean;
  actions?: boolean;
  uniqueId?: boolean;
  severity?: boolean;
  relativeSeverity?: boolean;
  probability?: boolean;
  impact?: boolean;
  moduleId?: boolean;
  assignedTo?: boolean;
  cveIds?: boolean;
  securityAdvisoryIds?: boolean;
}

export const allSeverities = [
  Severity.VeryLow,
  Severity.Low,
  Severity.Medium,
  Severity.High,
  Severity.VeryHigh,
];

export const allSignificances = [
  Significance.VeryLow,
  Significance.Low,
  Significance.Medium,
  Significance.High,
  Significance.VeryHigh,
];

const allStatuses = [
  VulnerabilityStatus.Unknown,
  VulnerabilityStatus.Mitigated,
  VulnerabilityStatus.Open,
];

/**
 * A generic component to display a list of vulnerabilities with an optional vulnerability action menu.
 * @param params:IProps
 * @returns The vulnerability list component
 */
export const VulnerabilitiesTable = ({
  data,
  filters,
  RowSubMenu,
  hide,
  show,
  disableFilters,
  disableColumnSelect,
  disablePagination,
  isLoading,
}: {
  data?: PagedResult<IVulnerability>;
  RowSubMenu?: React.FunctionComponent<{ vuln: IVulnerability }>;
  hide?: IVulnerabilityColumns;
  show?: IVulnerabilityColumns;
  disableFilters?: boolean;
  disableColumnSelect?: boolean;
  skipFilterFromSearchParamInitialization?: boolean;
  disablePagination?: boolean;
  filters: IPagedTableFilter<IVulnerabilityListOptions>;
  isLoading?: boolean;
}) => {
  const { hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const { appendQuery } = filters;

  const isEnabled = useCallback(
    (
      columnName:
        | 'id'
        | 'status'
        | 'created'
        | 'updated'
        | 'uniqueId'
        | 'asset'
        | 'details'
        | 'summary'
        | 'risk'
        | 'actions'
        | 'control'
        | 'severity'
        | 'relativeSeverity'
        | 'impact'
        | 'probability'
        | 'moduleId'
        | 'assignedTo'
        | 'cveIds'
        | 'securityAdvisoryIds',
    ) => typeof hide === 'undefined' || !hide[columnName],
    [hide],
  );

  const columnHelper = createPagedColumnHelper<IVulnerability & {securityAdvisoryIds?: string[]}>();

  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();
  const severityAsText = useGetSeverityAsText();
  const getAssetName = useGetAssetNameAsText();
  const { getModuleNameOrDefault } = useModules();
  const allModuleOptions = useAllVulnerabilityModuleOptions();

  const tableColumns = useMemo(() => {
    const c = [];

    if (isEnabled('status')) {
      c.push(
        columnHelper.accessor('status', {
          header: 'Status',
          cell: ({ row }) => (
            <VulnerabilityStatusBadge vulnerability={row.original} />
          ),
          formatter: (value: VulnerabilityStatus) => vulnerabilityStatusAsText(value),
          className: 'vuln-status-col',
          updateFilterFn: (values: VulnerabilityStatus[]) => {
            appendQuery({
              status: values,
            });
          },
          filterPropertyName: 'status',
          supportMultiSelect: true,
          selectOptions: allStatuses,
        }),
      );
    }

    if (isEnabled('relativeSeverity')) {
      c.push(
        columnHelper.accessor('relativeSeverity', {
          header: 'Criticality',
          cell: ({ value, row }) => {
            if (row.original.status === VulnerabilityStatus.Mitigated) {
              return null;
            }
            return value && value !== Severity.Unknown ? (
              <Badge bg="none" className={severityAsCssClassName(value)}>
                {severityAsText(value)}
              </Badge>
            ) : (
              ''
            );
          },
          formatter: (value?: Severity) => (value ? severityAsText(value) : 'None'),
          filterPropertyName: 'relativeSeverity',
          updateFilterFn: (values: Severity[]) => {
            appendQuery({
              relativeSeverity: values.length ? values : undefined,
            });
          },
          sortPropertyName: 'relativeSeverity',
          supportMultiSelect: false,
          selectOptions: allSeverities,
        }),
      );
    }

    if (isEnabled('severity')) {
      c.push(
        columnHelper.accessor('severity', {
          header: 'Severity',
          cell: ({ value, row }) => {
            if (row.original.status === VulnerabilityStatus.Mitigated) {
              return null;
            }
            return value && value !== Severity.Unknown ? (
              <Badge bg="none" className={severityAsCssClassName(value)}>
                {severityAsText(value)}
              </Badge>
            ) : (
              ''
            );
          },
          formatter: (value: Severity) => (value ? severityAsText(value) : 'None'),
          defaultHidden: !show?.severity && true,
          filterPropertyName: 'severity',
          updateFilterFn: (values: Severity[]) => {
            appendQuery({
              severity: values.length ? values[0] : undefined,
            });
          },
          sortPropertyName: 'severity',
          supportMultiSelect: false,
          selectOptions: allSeverities,
        }),
      );
    }

    if (isEnabled('asset')) {
      c.push(
        columnHelper.accessor('asset', {
          header: 'Asset',
          cell: ({ value }) => (
            <ClipboardCopy>{getAssetName(value)}</ClipboardCopy>
          ),
          filterPropertyName: 'asset',
          sortPropertyName: 'asset.name',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              asset: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('id')) {
      c.push(
        columnHelper.accessor('id', {
          header: 'Id',
          defaultHidden: !show?.id && true,
          cell: ({ value }) => <ClipboardCopy>{value}</ClipboardCopy>,
          filterPropertyName: 'id',
          updateFilterFn: (values: number[]) => {
            appendQuery({
              id: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('uniqueId')) {
      c.push(
        columnHelper.accessor('uniqueId', {
          header: 'Unique ID',
          defaultHidden: !show?.uniqueId && true,
          cell: ({ value }) => <ClipboardCopy>{value}</ClipboardCopy>,
          filterPropertyName: 'uniqueId',
          sortPropertyName: 'uniqueId',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              uniqueId: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('summary')) {
      c.push(
        columnHelper.accessor('summary', {
          header: 'Summary',
          cell: ({ value }) => <RenderHtml>{value}</RenderHtml>,
          filterPropertyName: 'summary',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              summary: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('risk')) {
      c.push(
        columnHelper.accessor('risk', {
          header: 'Risk ID',
          cell: ({ value: risk }) => (risk && hasModuleRole(Module.risk, 'read') ? (
            <OverlayTrigger overlay={<Tooltip>Go to risk</Tooltip>}>
              <Link
                to={getRiskUri(risk)}
                onClick={(e) => e.stopPropagation()}
              >
                {risk?.riskId}
              </Link>
            </OverlayTrigger>
          ) : (
            <span>{risk?.riskId}</span>
          )),
          defaultHidden: !show?.risk && true,
          filterPropertyName: 'risk',
          sortPropertyName: 'risk.riskId',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              risk: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('control')) {
      c.push(
        columnHelper.accessor('control', {
          header: 'Control ID',
          cell: ({ row }) => {
            const vuln = row.original;
            return vuln.control
              && hasModuleRole(Module.vulnerability, 'read') ? (
                <OverlayTrigger overlay={<Tooltip>Go to control</Tooltip>}>
                  <Link
                    to={getControlUri(vuln.control)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {vuln.control?.friendlyId}
                  </Link>
                </OverlayTrigger>
              ) : (
                vuln.control?.friendlyId
              );
          },
          defaultHidden: !show?.control && true,
          filterPropertyName: 'control',
          sortPropertyName: 'control.friendlyId',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              control: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('created')) {
      c.push(
        columnHelper.accessor('created', {
          header: 'Created',
          cell: ({ row }) => {
            const vuln = row.original;
            return getStringDate(vuln.created);
          },
          defaultHidden: !show?.created && true,
          disableFilter: true,
          filterPropertyName: 'createdAfter',
          sortPropertyName: 'created',
          filterFormatter: (values: string[]) => `${getStringDate(values[0])}\u00A0↦`,
        }),
      );
    }

    if (isEnabled('updated')) {
      c.push(
        columnHelper.accessor('updated', {
          header: 'Updated',
          cell: ({ row }) => {
            const vuln = row.original;
            return getStringDate(vuln.updated ?? vuln.created);
          },
          defaultHidden: !show?.updated && true,
          disableFilter: true,
          filterPropertyName: 'updatedAfter',
          sortPropertyName: 'updated',
          filterFormatter: (values: string[]) => `${getStringDate(values[0])}\u00A0↦`,
        }),
      );
    }

    if (isEnabled('moduleId')) {
      c.push(
        columnHelper.accessor('sourceModuleId', {
          header: 'Module',
          cell: ({ value }) => getModuleNameOrDefault(value),
          formatter: getModuleNameOrDefault,
          defaultHidden: !show?.moduleId && true,
          filterPropertyName: 'sourceModuleIds',
          updateFilterFn: (values: number[]) => {
            appendQuery({
              sourceModuleIds: values,
            });
          },
          selectOptions: allModuleOptions,
          supportMultiSelect: true,
        }),
      );
    }

    if (isEnabled('cveIds')) {
      c.push(
        columnHelper.accessor('cveIds', {
          header: 'CVE',
          cell: ({ value }) => {
            if (!value?.length) return null;

            return value
              ? value.map((cveId) => (
                <Link key={cveId} to={getCveUri(cveId)} target="_blank">
                  {cveId}
                  <Icon name="external-link" className="ms-2" size="16" />
                </Link>
              ))
              : null;
          },
          defaultHidden: !show?.cveIds && true,
          filterPropertyName: 'cveIds',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              cveIds: values.length ? values : undefined,
            });
          },
        }),
      );
    }

    // Only add securityAdvisoryIds if filtered. If filtered, we know the
    // vulnerability has the advisory and add that from the filter. We do not
    // expand this server side, due to the database cost.
    if (
      isEnabled('securityAdvisoryIds')
      && filters?.query.securityAdvisoryIds?.length
    ) {
      c.push(
        columnHelper.accessor('securityAdvisoryIds', {
          header: 'Security Advisory',
          cell: () => {
            const advisoryIds = filters.query?.securityAdvisoryIds;
            if (!advisoryIds?.length) return null;

            return advisoryIds
              ? advisoryIds.map((advisoryId) => {
                const externalUri = getSecurityAdvisoryUrl(advisoryId);

                return externalUri ? (
                  <Link key={advisoryId} target="_blank" to={externalUri}>
                    {advisoryId}
                    <Icon name="external-link" size="16" className="ms-1" />
                  </Link>
                ) : (
                  <ClipboardCopy key={advisoryId}>{advisoryId}</ClipboardCopy>
                );
              })
              : null;
          },
          defaultHidden: !show?.securityAdvisoryIds && true,
          filterPropertyName: 'securityAdvisoryIds',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              securityAdvisoryIds: values.length ? values : undefined,
            });
          },
        }),
      );
    }

    if (isEnabled('assignedTo')) {
      c.push(
        columnHelper.accessor('assignedTo', {
          header: 'Assignee',
          cell: ({ value }) => (value ? <ClipboardCopy>{asNameUpn(value)}</ClipboardCopy> : ''),
          formatter: (user?: IUser) => (user ? asNameUpn(user) : ''),
          defaultHidden: !show?.assignedTo && true,
          filterPropertyName: 'assignedTo',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              assignedTo: values.length ? values[0] : undefined,
            });
          },
        }),
      );
    }

    if (RowSubMenu) {
      c.push(
        columnHelper.display({
          id: 'submenu',
          header: 'Actions',
          cell: ({ row }) => <RowSubMenu vuln={row.original} />,
          disableFilter: true,
        }),
      );
    }

    return c;
  }, [
    isEnabled,
    filters.query.securityAdvisoryIds,
    RowSubMenu,
    columnHelper,
    vulnerabilityStatusAsText,
    appendQuery,
    severityAsText,
    show?.severity,
    show?.id,
    show?.uniqueId,
    show?.risk,
    show?.control,
    show?.created,
    show?.updated,
    show?.moduleId,
    show?.cveIds,
    show?.securityAdvisoryIds,
    show?.assignedTo,
    getAssetName,
    hasModuleRole,
    getModuleNameOrDefault,
    allModuleOptions,
  ]);

  return (
    <PagedTable
      data={data}
      filters={filters}
      className="vulnerabilities-table"
      isLoading={isLoading}
      columnDefs={tableColumns}
      disablePagination={disablePagination}
      disableFilters={disableFilters}
      disableColumnSelect={disableColumnSelect}
      onRowClick={(_, vuln) => {
        navigate(`${ROUTES.vulnerability.uri}/${vuln.id}`);
      }}
    />
  );
};

/**
 * A vulnerability tailored for use to display vulnerabilities associated with a control.
 * @param props:IControlDetails
 * @returns The control vulnerability table
 */
export const ControlVulnerabilitiesTable = (
  {
    control,
    pagedVulnerabilities,
    pagedTableFilter,
    hasVulnerabilities,
  }:{
      control: IControlDetails,
      pagedVulnerabilities?: PagedResult<IVulnerability>,
      pagedTableFilter: IPagedTableFilter<IVulnerabilityListOptions>,
      hasVulnerabilities: boolean
  },
) => {
  const { data: allModulesControlActions } = useApi<
    Record<number, IActionTarget[]>
  >(
    control
      && `module/vulnerabilityJobs/controlActions/${encodeURIComponent(
        control.friendlyId,
      )}`,
  );

  // TODO: Support control actions from any module
  // TODO: How do we link asset to module to determine what actions to support?
  const controlActions: IActionTarget[] = allModulesControlActions && allModulesControlActions[Module.m365]
    ? allModulesControlActions[Module.m365]
    : [];

  const abortSignalRef = useRef<AbortController>();

  useEffect(() => {
    abortSignalRef.current = new AbortController();
    return () => {
      abortSignalRef.current?.abort();
    };
  }, []);

  const [activeActionContext, setActiveActionContext] = useState<IActionContext>();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');

  const invalidateQueries = () => {
    invalidateVulnerabilities();
  };

  const closeModal = () => {
    setActiveActionContext(undefined);
    invalidateQueries();
  };

  return !hasVulnerabilities
    ? 'Control has no associated vulnerabilities.'
    : (
      <VulnerabilitiesTable
        data={pagedVulnerabilities}
        filters={pagedTableFilter}
        RowSubMenu={({ vuln }) => (
          <>
            <VulnerabilityActionDropdown
              vuln={vuln}
              actions={getVulnerabilityActions(
                controlActions,
                vuln,
                control,
                setActiveActionContext,
                invalidateQueries,
                abortSignalRef.current?.signal,
              )}
              active={activeActionContext?.name}
            />
            <ControlRemediateModal
              control={control}
              vulerability={vuln}
              handleClose={closeModal}
              start
              show={
                activeActionContext?.name === 'configure'
              && activeActionContext?.vulnerability.id === vuln.id
              }
            />
            <ControlUndoModal
              control={control}
              vulerability={vuln}
              handleClose={closeModal}
              show={
                activeActionContext?.name === 'revert'
              && activeActionContext?.vulnerability.id === vuln.id
              }
              undoTimestamp={activeActionContext?.timestamp}
            />
          </>
        )}
        hide={{ control: true }}
      />
    );
};
