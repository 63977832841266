import React from 'react';
import { getStringDate, getStringDateOnly } from '../../utils/StringUtils';

// TODO The Date / Boolean Cells should be able to be used like:
// columnDef:
// {
//   ...
//   cell: TableCellDateFormattedV8,
// }
// instead of: cell: ({ value }) => TableCellDateFormattedV8(value),

/** Shorthand Cell component for rendering dates in a standardized format (uses getStringDate) */
export const TableCellDateFormatted = (value: unknown, options?:Intl.DateTimeFormatOptions) => (
  <span>{getStringDate(value as string, options)}</span>
);

export const TableCellDateOnlyFormatted = (value: unknown) => (
  <span>{getStringDateOnly(value as string)}</span>
);

export const TableCellBooleanYesNo = (value: unknown) => (
  <span>{value ? 'Yes' : 'No'}</span>
);
