/* eslint-disable no-nested-ternary */
import React, {
  createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { IAvailableCustomerData } from './DashboardPage';
import { startVulnerabilityScan } from '../../components/ScanVulnerabilitiesButton';
import { Module } from '../../types/AccessTypes';
import { useApi } from '../../query/GenericQuery';
import { IErrorDetails, IJob, JobStatus } from '../../types/Types';
import { useAccount } from '../../providers/AccountProvider';
import { useAvailableCustomerData, useInvalidateAvailableCustomerData } from '../../providers/AvailableCustomerDataProvider';

export const useGetDisplayStatus = () => {
  const { hasAnyRole, hasOnlyModuleRole, isAssociatedTo } = useAccount();
  const { hasModuleRole } = useAccount();
  const availableData = useAvailableCustomerData();

  return (scanError:AxiosError|undefined) => {
    if (!availableData || !availableData.hasConsents || hasOnlyModuleRole(Module.customerAdmin, 'read')) {
      if (hasModuleRole(Module.customerAdmin, 'readWrite')) {
        return 'notConnectedModules';
      }

      if (isAssociatedTo?.length) {
        return 'noContentAvailableCustomerIsMsp';
      }

      return 'noContentAvailable';
    }

    if (hasOnlyModuleRole(Module.customerAdmin, 'readWrite')) {
      return 'noContentIsAdmin';
    }

    if (!hasAnyRole) {
      return 'noPermissions';
    }

    // This must be placed before 'noPermittedContent'
    if (hasModuleRole(Module.vulnerability, 'readOwn')
      && !hasModuleRole(Module.vulnerability, 'read')
      && !hasModuleRole(Module.admin, 'read')
      && !hasModuleRole(Module.risk, 'read')
      && !hasModuleRole(Module.assets, 'read')) {
      return 'onlyReadOwnAndNoAssigned';
    }

    if (!hasModuleRole(Module.vulnerability, 'read')
      && !hasModuleRole(Module.admin, 'read')
      && !hasModuleRole(Module.risk, 'read')
      && !hasModuleRole(Module.assets, 'read')) {
      return 'noPermittedContent';
    }

    if (scanError) {
      return 'scanError';
    }

    if (!availableData.hasVulnerabilities) {
      return 'scanInProgress';
    }

    return null;
  };
};

export const InitialScanContext = createContext<{
  scanError?:AxiosError,
  setScanError:(scanError:AxiosError|undefined) => void,
  jobId?:number,
  setJobId: (jobId:number|undefined) => void,
    }>({
      setScanError: () => {},
      setJobId: () => {},
    });

export const WelcomeTileProvider = ({ children }:{children:ReactNode}) => {
  useContext(InitialScanContext);

  const [scanError, setScanError] = useState<AxiosError|undefined>();
  const [jobId, setJobId] = useState<number|undefined>();

  const value = useMemo(() => ({
    scanError,
    setScanError,
    jobId,
    setJobId,
  }), [jobId, scanError]);

  return (
    <InitialScanContext.Provider value={value}>
      {children}
    </InitialScanContext.Provider>
  );
};

export const Welcome = ({ availableData }:{availableData?:IAvailableCustomerData}) => {
  const { customer } = useAccount();
  const jobStartedRef = useRef(false);
  const {
    jobId, setJobId, scanError, setScanError,
  } = useContext(InitialScanContext);
  const invalidateAvailableData = useInvalidateAvailableCustomerData();

  const refetchInterval = 5000;

  const { data: job } = useApi<IJob>(
    jobId ? `jobs/${jobId}` : undefined,
    undefined,
    {
      refetchInterval,
      enabled: !!jobId && !availableData?.hasVulnerabilities,
    },
  );

  useEffect(() => {
    // Stop job status polling when job has completed
    if ([JobStatus.Finalized, JobStatus.Succeeded, JobStatus.Failed].includes(job?.status ?? JobStatus.Unknown)) {
      if (job?.status === JobStatus.Failed) {
        toast.error('Scan failed');
      }
      setJobId(undefined);
    }

    if (job?.status === JobStatus.Finalized) {
      toast.info('We\'re done scanning for vulnerabilities and your dashboard has been updated! Your connected systems will be continouly scanned and your dashboard will always reflect the current status.', {
        autoClose: 30000,
      });
      invalidateAvailableData();
    }
  }, [invalidateAvailableData, job?.status, setJobId]);

  useEffect(() => {
    // Make sure we only start one job
    if (jobStartedRef.current) {
      return;
    }

    // TODO: Currently we trigger the entraID module consent from dashboard. Since all other
    // modules with consent is currently dependent on this, we can safely assume that if
    // consent is available, it is entraID. This will probably not be the case in the future
    // and we'll need to adress this at some point.
    if (!availableData || !availableData.hasConsents || availableData.hasVulnerabilities) {
      return;
    }

    startVulnerabilityScan(Module.entraId, customer.id)
      .then((id) => setJobId(id))
      .catch((err) => {
        const axiosError = err as AxiosError;
        if (axiosError.response?.status === 409) {
          const errorDetails = axiosError.response?.data as IErrorDetails;
          if (errorDetails) {
            const details = errorDetails.errorDetails as {conflictingJobId:number};
            if (details && details.conflictingJobId) {
              setJobId(details.conflictingJobId);
            }
          }
        } else {
          setScanError(axiosError);
          // eslint-disable-next-line no-console
          console.error(`Unable to start scan from welcome tile: ${err}`);
        }
      });

    // Set flag indicating that a scan job has been started
    jobStartedRef.current = true;
  }, [availableData, customer.id, setJobId, setScanError]);

  const status = useGetDisplayStatus()(scanError);

  switch (status) {
  case 'notConnectedModules':
    return (
      <p>
        There&apos;s no information to display until you connect to your services
      </p>
    );
  case 'noContentAvailable':
    return (
      <>
        <p>
          There&apos;s no information to show.
        </p>
        <p>
          Please check back later, and don&apos;t hesitate to contact
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          {' '}
          if you need assistance connecting your services.
        </p>
      </>
    );
  case 'noPermittedContent':
    return (
      <>
        <p>
          Your account currently does not have access to any content.
        </p>
        <p>
          For further assistance, please contact your organization&apos;s administrator or
          reach out to
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          .
        </p>
      </>
    );
  case 'noContentIsAdmin':
    return (
      <>
        <p>
          You currently have no module permissions and no content to display.
        </p>
        <p>
          As an administrator, you can assign additional permissions via the
          {' '}
          <em>Customer settings</em>
          {' '}
          option in the dropdown menu at the top right of the application.
        </p>
      </>
    );
  case 'noContentAvailableCustomerIsMsp':
    return (
      <p>
        There&apos;s no information available for your tenant,
        but you have permission to access other customer tenants. Use the
        {' '}
        <em>Switch organization</em>
        {' '}
        button on your top right to select another customer.
      </p>
    );
  case 'noPermissions':
    return (
      <>
        <p>
          You currently don&apos;t have any permissions assigned to your account.
        </p>
        <p>
          For further assistance, please contact your organization&apos;s administrator or
          reach out to
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          .
        </p>
      </>
    );
  case 'onlyReadOwnAndNoAssigned':
    return (
      <>
        <p>
          You currently don&apos;t have any assigned vulnerabilities to view.
        </p>
        <p>
          For further assistance, please contact your organization&apos;s administrator or
          reach out to
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          .
        </p>
      </>
    );
  case 'scanError':
    return (
      <Alert variant="warning" className="p-3 pb-1">
        <div>
          <p>
            We&apos;ve tried to scan your service for vulnerabilities but encountered some issues
            { scanError
              ? (
                <span>
                  (
                  {scanError.response?.status}
                  {' '}
                  {scanError.response?.statusText}
                  )
                </span>
              )
              : null }
            .
          </p>
          <p>
            For further assistance, please contact reach out to
            {' '}
            <a href="mailto:support@ivolv.no">Ivolv Support</a>
            .
          </p>
        </div>
      </Alert>
    );
  case 'scanInProgress':
    return (
      <>
        <p>
          You have successfully connected your first service, and we&apos;ve started a vulnerability scan.
        </p>
        <p>
          In a few minutes, when the scan is done, the platform will be updated with the initial findings.
        </p>
      </>
    );
  default:
    break;
  }

  return null;
};
