import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IAsset } from '../../types/AssetsTypes';
import { VulnerabilitiesTable } from '../vulnerabilities/VulnerabilitiesTable';
import { useApi } from '../../query/GenericQuery';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IVulnerability, VulnerabilityStatus } from '../vulnerabilities/Types';
import { PagedResult } from '../../types/PagedResult';
import { IVulnerabilityListOptions } from '../../types/Types';

export const AssetVulnerabilitiesTab = ({
  asset,
}:{
  asset: IAsset
}) => {
  const filters = usePagedTableFilter<IVulnerabilityListOptions>(
    'asset-vulnerabilities',
    {
      assets: [asset?.id],
    },
    {
      initialQuery: {
        status: [VulnerabilityStatus.Open],
        assets: [asset?.id],
      },
      initialSorting: [
        { property: 'severity', direction: Direction.desc },
      ],
    },
  );

  const { data } = useApi<PagedResult<IVulnerability>>(
    'vulnerabilities',
    {
      ...filters.query,
      assets: [asset?.id],
    },
  );

  return data
    ? (
      <VulnerabilitiesTable
        data={data}
        filters={filters}
        hide={{ asset: true }}
        show={{ severity: true }}
      />
    ) : <Spinner animation="border" />;
};
