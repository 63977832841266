import React, { useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Spinner } from 'react-bootstrap';
import { AdminCustomer, Customer, ICustomerEssentials } from '../../types/AdminTypes';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';

interface IProps {
  selectedCustomer:ICustomerEssentials|undefined|null,
  onCustomerSelected:(customer:AdminCustomer|undefined) => void|Promise<void>,
  onSearchChange?:(search:string) => void
  placeholder?:string,
  loadingText?:string,
  excludeCustomerIds?: string[]
}

const CustomerSelector = (props:IProps) => {
  const {
    onCustomerSelected,
    onSearchChange,
    selectedCustomer,
    placeholder,
    loadingText,
    excludeCustomerIds,
  } = props;

  const [query, setQuery] = useState('');

  const pageSize = 20;

  // Get one more than page size to trigger overflow text
  const customersQuery = useApi<PagedResult<Customer>>(
    'module/admin/customers',
    {
      search: query,
      pageSize: pageSize + 1,
      page: 1,
      sortBy: 'name',
      excludeCustomerIds,
    },
  );

  return (
    <AsyncTypeahead
      id="userTab-customerSelect"
      isLoading={customersQuery.isLoading}
      useCache={false}
      onInputChange={(s) => {
        if (s === '') {
          setQuery('');
          if (onSearchChange) onSearchChange('');
        }
      }}
      onSearch={async (q) => {
        setQuery(q);
        if (onSearchChange) onSearchChange(q);
      }}
      options={customersQuery.data?.items ?? []}
      onChange={async (selected) => {
        if (selected.length) await onCustomerSelected(selected[0] as AdminCustomer);
        else {
          await onCustomerSelected(undefined);
          if (onSearchChange) onSearchChange('');
        }
      }}
      labelKey="name"
      /*
      We're doing server side filtering, which also supports tenantId.
      Let typeahead show the full result set.
      */
      filterBy={() => true}
      placeholder={placeholder ?? 'Select or type to search...'}
      clearButton
      paginate
      selected={selectedCustomer ? [selectedCustomer] : []}
      renderMenu={(options, menuProps) => (
        <Menu id={menuProps.id} className={menuProps.className}>
          { customersQuery.isLoading
            ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#" role="option" aria-selected className="dropdown-item disabled">
                <Spinner size="sm" />
                <span className="ms-2">{ loadingText ?? 'Loading...' }</span>
              </a>
            )
            : options.map((option, index) => {
              const customer = option as Customer;
              return customer.id
                ? (
                  <MenuItem key={customer.id} id={customer.id} option={option} position={index} className={customer.active ? '' : 'text-muted'}>
                    {customer.name}
                  </MenuItem>
                )
                : (
                  <span className="text-muted dropdown-item ivolv-rbt-overflow-item" key="paginator">
                    {pageSize}
                    {' '}
                    of
                    {' '}
                    {customersQuery.data?.rowCount}
                    {' '}
                    matching items displayed. Narrow results by typing more.
                  </span>
                );
            })}
        </Menu>
      )}
      paginationText={`${(customersQuery.data?.rowCount ?? 0) - pageSize} more items exist, type to narrow results...`}
      maxResults={pageSize}
      minLength={0}
    />
  );
};

export default CustomerSelector;
