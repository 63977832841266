import React from 'react';
import {
  Button, Col, Row, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastOptions } from 'react-toastify';
import { useLogout } from './BearerTokenProvider';
import { IAccountExists } from './AccountProvider';
import { SimpleCard } from '../common/SimpleCard';
import { ActiveStatus } from '../types/AdminTypes';
import { asClientError } from '../routing/ClientError';

const toastOptions:ToastOptions = {
  toastId: 'resendActivation',
  updateId: 'resendActivation',
};

const PendingInviteCard = ({ accountExists }:{accountExists:IAccountExists}) => {
  const logout = useLogout();

  const { accountId, customerId } = accountExists;

  if (!accountId || !customerId) {
    return null;
  }

  const resendInvite = async () => {
    try {
      const { data } = await axios.post<{email:string}>(`/api/v1/actiontoken/resend/${encodeURIComponent(customerId)}/${encodeURIComponent(accountId)}/SignupActivation`);
      toast.success(
        (
          <div>
            Activation email was sent to
            {' '}
            <code>{data.email}</code>
            .
            <br />
            <br />
            If your email address is incorrect, please contact
            {' '}
            <a href="mailto:support@ivolv.no">support@ivolv.no</a>
            {' '}
            to correct it.
          </div>
        ),
        {
          ...toastOptions,
          autoClose: false,
        },
      );
    } catch (err) {
      const error = asClientError(err);
      switch (error?.status) {
      case 404:
        toast.error(
          <div>
            <strong>No activation link exists.</strong>
            <br />
            <br />
            Please contact
            {' '}
            <a href="mailto:support@ivolv.no">support@ivolv.no</a>
            {' '}
            to activate your account.
          </div>,
          toastOptions,
        );
        break;
      case 409:
        toast.warning(
          <div>
            An activation link was sent less than a minute ago. Please check your inbox or spam folder.
          </div>,
          toastOptions,
        );
        break;
      default:
        toast.error(
            `Activation email cannot be sent (${err})`,
            toastOptions,
        );
        break;
      }
    }
  };

  return (
    <SimpleCard header="Please activate your account">
      <>
        <Row>
          <Col md={12} className="mb-3">
            <p>
              An activation email has been sent to your organization.
              Please click the activation link in the email to activate your account.
            </p>
            <p>
              If you haven&apos;t received the email, check your spam folder. If you&apos;re unable
              to find it, you can try resending the activation link with the button below.
            </p>
            <p>
              If you&apos;re unable to activate your account, please contact us at
              {' '}
              <a href="mailto:support@ivolv.no">support@ivolv.no</a>
              {' '}
              for assistance.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button onClick={async () => resendInvite()}>
                Resend activation email
              </Button>
              <Button onClick={logout} variant="secondary">
                Log out
              </Button>
            </Stack>
          </Col>
        </Row>
      </>
    </SimpleCard>
  );
};

const LogoutButtonStack = () => {
  const logout = useLogout();

  return (
    <Stack direction="horizontal" className="mt-4">
      <Button onClick={logout} variant="secondary">
        Log out
      </Button>
    </Stack>
  );
};

export const MissingAccountCard = ({ accountExists }:{ accountExists?:IAccountExists }) => {
  if (!accountExists?.accountId) return null;

  if (accountExists.customerStatus === ActiveStatus.pending) {
    return <PendingInviteCard accountExists={accountExists} />;
  }
  if (accountExists?.customerStatus === ActiveStatus.disabledAndHidden) {
    return (
      <SimpleCard header="Your organization has been deleted">
        <p>
          Please contact
          {' '}
          <a href="mailto:support@ivolv.no">support@ivolv.no</a>
          {' '}
          if you have any related questions.
        </p>
        <LogoutButtonStack />
      </SimpleCard>
    );
  }
  if (accountExists?.customerStatus === ActiveStatus.disabled) {
    return (
      <SimpleCard header="Your organization has been deactivated">
        <p>
          Please contact
          {' '}
          <a href="mailto:support@ivolv.no">support@ivolv.no</a>
          {' '}
          to reactivate your organization.
        </p>
        <LogoutButtonStack />
      </SimpleCard>
    );
  }
  if (accountExists?.customerStatus === ActiveStatus.invited) {
    return (
      <SimpleCard header="You're invited">
        <p>
          Your organization has been invited, but is not yet activated.
        </p>
        <p>
          Please contact
          {' '}
          <a href="mailto:support@ivolv.no">support@ivolv.no</a>
          {' '}
          if you have any related questions.
        </p>
        <LogoutButtonStack />
      </SimpleCard>
    );
  }
  if (accountExists?.customerStatus === ActiveStatus.active) {
    if (accountExists.accountStatus !== ActiveStatus.active) {
      return (
        <SimpleCard header="Your account disabled">
          <p>
            Your organization is active but your account is not.
          </p>
          <p>
            Please contact
            {' '}
            <a href="mailto:support@ivolv.no">support@ivolv.no</a>
            {' '}
            to activate your account.
          </p>
          <LogoutButtonStack />
        </SimpleCard>
      );
    }
  }

  // Fallback that should never be visible if method is called correctly
  return (
    <SimpleCard header={`Your account is ${accountExists.accountStatus}!`}>
      <p>
        Everything should be fine...
      </p>
      <p>
        If it&apos;s not, please contact
        {' '}
        <a href="mailto:support@ivolv.no">support@ivolv.no</a>
        .
      </p>
      <LogoutButtonStack />
    </SimpleCard>
  );
};
