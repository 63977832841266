import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ISecurityAdvisoryListOptions, ISecurityAdvisoryWithVulnerabilityCount } from '../../types/Types';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { SecurityAdvisoryTable } from '../assets/SecurityAdvisoryTable';
import ROUTES from '../../routing/Routes';
import { usePagedTableFilter } from '../../common/table/PagedResultFilter';

export const SecurityAdvisoriesTile = () => {
  const navigate = useNavigate();

  const tableFilter = usePagedTableFilter<ISecurityAdvisoryListOptions>(
    'security-advisories',
    { withOpenVulnerabilities: true },
    {
      fixedPage: {
        pageSize: 5,
        page: 0,
      },
    },
  );

  const { data } = useApi<PagedResult<ISecurityAdvisoryWithVulnerabilityCount>>(
    'securityAdvisories',
    tableFilter.query,
  );

  return data && tableFilter
    ? (
      <>
        <SecurityAdvisoryTable
          data={data}
          pagedTableFilter={tableFilter}
          disableFilters
          disableColumnSelect
          disablePagination
          disableSorting
        />
        <Button
          onClick={() => navigate(ROUTES.assetsSecurityAdvisories.uri)}
        >
          Show all advisories
        </Button>
      </>
    )
    : <Spinner animation="border" />;
};
