import React, { useMemo } from 'react';
import {
  Button, Stack,
} from 'react-bootstrap';
import { IAssetQuestionContext } from './AssessmentTypes';
import AssessmentDiscardAssetButton from './AssessmentDiscardAssetButton';
import { AssessmentStateManager } from './AssessmentStateManager';
import RenderHtml from '../../components/RenderHtml';
import {
  PagedTable, usePagedTableFilter, useArrayAsPagedResult, createPagedColumnHelper,
} from '../../common/table';

interface ISkippedQuestionsTableItem {
  id:string,
  questionMeta:IAssetQuestionContext,
}

interface IProps {
  stateManager:AssessmentStateManager,
}

export const SkippedQuestionsTable = (props:IProps) => {
  const { stateManager } = props;

  const columnHelper = createPagedColumnHelper<ISkippedQuestionsTableItem>();

  const tableFilters = usePagedTableFilter<object>(
    'assessment-skipped-questions',
    {},
  );

  const { appendQuery } = tableFilters;

  const skippedQuestions = useMemo(() => {
    const mSkippedQuestions:ISkippedQuestionsTableItem[] = [];
    const skipped = stateManager.getSkippedQuestions();
    Object.values(skipped).forEach((questionMetadataArray) => {
      questionMetadataArray.forEach((questionMeta) => {
        const { asset, index } = questionMeta;
        mSkippedQuestions.push({
          id: `${asset.uniqueId}::${JSON.stringify(index)}`,
          questionMeta,
        });
      });
    });

    return mSkippedQuestions;
  }, [stateManager]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('questionMeta.asset.name', {
        id: 'asset',
        header: 'Asset',
        updateFilterFn: (assetNames:string[]) => {
          appendQuery({
            asset: assetNames.length ? assetNames[0] : undefined,
          });
        },
        filterPropertyName: 'asset',
        className: 'text-nowrap pe-4',
      }),
      columnHelper.accessor('questionMeta.question.text', {
        id: 'question',
        header: 'Question',
        cell: ({ value }) => (
          <RenderHtml>
            {value}
          </RenderHtml>
        ),
        filterPropertyName: 'question',
        updateFilterFn: (questionTexts:string[]) => {
          appendQuery({
            question: questionTexts.length ? questionTexts[0] : undefined,
          });
        },
        className: 'w-100 pe-4',
      }),
      {
        ...columnHelper.accessor('questionMeta', {
          id: 'action',
          header: 'Actions',
          cell: ({ value }) => (
            <Stack direction="horizontal" gap={2}>
              <Button
                onClick={() => stateManager.resumeQuestion(value.asset, value.index)}
              >
                Resume
              </Button>
              <AssessmentDiscardAssetButton
                stateManager={stateManager}
              />
            </Stack>
          ),
          className: 'text-nowrap text-end',
        }),
      },
    ],
    [appendQuery, columnHelper, stateManager],
  );

  const pagedResult = useArrayAsPagedResult(
    skippedQuestions,
    tableFilters,
    columns,
  );

  return (
    <PagedTable
      columnDefs={columns}
      data={pagedResult}
      filters={tableFilters}
      disableColumnSelect
    />
  );
};
