import { useMsal } from '@azure/msal-react';
import React, { useState } from 'react';
import {
  Alert,
  Button, Card, Col, Form, Row, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routing/Routes';
import { useLogout } from '../../providers/BearerTokenProvider';

export interface ISignup {
  customerName:string,
  accountEmail:string,
  customerOrganizationNumber:string,
  accountPhone:string,
}

export function hasRequiredSignupData(data:ISignup) {
  return data.customerName;
}

export const SignupFormCard = () => {
  const [data, setData] = useState<ISignup>({
    accountPhone: '',
    accountEmail: '',
    customerName: '',
    customerOrganizationNumber: '',
  });
  const logout = useLogout();

  const navigate = useNavigate();
  const { accounts } = useMsal();

  const msalAccount = accounts.length > 0 ? accounts[0] : undefined;

  if (!msalAccount) {
    return null;
  }

  return (
    <Form onSubmit={async (e) => {
      e.preventDefault();

      sessionStorage.setItem('signup', JSON.stringify(data));
      await navigate(ROUTES.signupTerms.uri);
    }}
    >
      <Card className="fill card-md">
        <Card.Header>Add organization details to continue</Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                readOnly
                value={msalAccount.username}
              />
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Label>Your name</Form.Label>
              <Form.Control
                readOnly
                value={msalAccount.name}
              />
            </Col>
            <Col sm={8} className="mb-3">
              <Form.Label>Your email</Form.Label>
              <Form.Control
                value={data.accountEmail}
                autoFocus
                placeholder={msalAccount.username}
                onChange={(e) => {
                  setData({
                    ...data,
                    accountEmail: e.target.value,
                  });
                }}
              />
              <Alert variant="info" className="p-2 mt-2">
                <div>
                  An activation email will be sent to this address after signing up. Please ensure that the email
                  address provided is valid, as you won&apos;t be able to activate your account otherwise.
                </div>
              </Alert>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Label>Your phone number</Form.Label>
              <Form.Control
                value={data.accountPhone}
                pattern="^\+?[0-9 ]{8,}$"
                onChange={(e) => {
                  setData({
                    ...data,
                    accountPhone: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={12} className="mb-3">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                value={data.customerName}
                required
                onChange={(e) => {
                  setData({
                    ...data,
                    customerName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </Card.Body>
        <div className="card-button-footer">
          <Row>
            <Col md={12}>
              <Stack direction="horizontal" gap={2}>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!hasRequiredSignupData(data)}
                >
                  Continue
                </Button>
                <Button
                  variant="secondary"
                  onClick={logout}
                >
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>
  );
};
