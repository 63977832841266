import { useCallback, useMemo } from 'react';
import { PagedResult } from '../../types/PagedResult';
import { IFilterFn, PageableColumnDef } from './PagedTable';
import { Direction, IPagedTableFilter, ISorting } from './PagedResultFilter';
import { getNestedValue } from '../../utils/Utils';

export const valueFilterWeakEquals:IFilterFn<string, string> = (
  propertyValue:string,
  filterValue:string,
) => (
  propertyValue.toLowerCase() === filterValue.toLowerCase()
);

export const valueFilterWeakIncludes:IFilterFn<unknown, unknown> = (
  propertyValue:unknown,
  filterValue:unknown,
) => (
  typeof (propertyValue) === 'string' && typeof (filterValue) === 'string'
    ? propertyValue.toLowerCase().includes(filterValue.toLowerCase())
    : propertyValue === filterValue
);

export const valueFilterArrIncludesSome:IFilterFn<unknown, unknown[]> = (
  propertyValue:unknown,
  filterValue:unknown[],
) => (
  Array.isArray(filterValue)
    ? (!filterValue.length || filterValue.includes(propertyValue))
    : filterValue === propertyValue
);

const createFilterFn = <TObject, TValue, TFilterValue, >(
  sorting:ISorting[],
  getProperty:(propertyName:string) => PageableColumnDef<TObject, TValue, TFilterValue>|undefined,
) => (a:TObject, b:TObject) => {
    for (const option of sorting) {
      const { property, direction } = option;
      if (direction === 0) continue; // Skip if no sorting is specified

      const column = getProperty(property);

      const aValue = a[(column?.accessorKey ?? property) as keyof typeof a];
      const bValue = b[(column?.accessorKey ?? property) as keyof typeof b];

      if (aValue == null && bValue != null) return direction === 1 ? 1 : -1;
      if (aValue != null && bValue == null) return direction === 1 ? -1 : 1;
      if (aValue == null && bValue == null) continue;

      if (column?.sortFn) {
        const comparison = column.sortFn ? column.sortFn(aValue as TValue, bValue as TValue) : 0;
        if (comparison !== 0) {
          return direction === Direction.desc
            ? -comparison
            : comparison;
        }
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        if (comparison !== 0) {
          return direction === Direction.desc
            ? -comparison
            : comparison;
        }
      } else if (aValue < bValue) {
        return direction === Direction.desc ? -1 : 1;
      } else if (aValue > bValue) {
        return direction === Direction.desc ? 1 : -1;
      }
    }
    return 0; // If all properties are equal
  };

export const useArrayAsPagedResult = <
TObject,
TValue,
TFilterValue,
TFilter extends object>(
    items?:TObject[],
    tableFilter?:IPagedTableFilter<TFilter>,
    columnDefs?:PageableColumnDef<TObject, TValue, TFilterValue>[],
  ) : PagedResult<TObject>|undefined => {
  const columnFilterPropertyNameKeyMap = useMemo(() => {
    if (!columnDefs) return undefined;
    const a:Record<string, PageableColumnDef<TObject, TValue, TFilterValue>> = {};
    columnDefs
      .forEach((c) => {
        const p = c as unknown as PageableColumnDef<TObject, TValue, TFilterValue>;
        if (p.filterPropertyName ?? p.accessorKey) {
          a[p.filterPropertyName ?? p.accessorKey] = p;
        }
      });

    return a;
  }, [columnDefs]);

  const isVisible = useCallback((item:TObject) => {
    if (!tableFilter || !columnFilterPropertyNameKeyMap) return undefined;
    const filterEntries = Object.entries(tableFilter.query);

    for (let i = 0; i < filterEntries.length; i += 1) {
      const filterEntry = filterEntries[i];

      const column = columnFilterPropertyNameKeyMap[filterEntry[0]];
      if (!column) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const propertyValue = getNestedValue(item, column.accessorKey);

      const filterFn = column.isMatchFn ?? (
        column.selectOptions
          ? valueFilterArrIncludesSome
          : valueFilterWeakIncludes
      );

      if (
        filterEntry[1] !== undefined
        && !filterFn(propertyValue as TValue, filterEntry[1])
      ) {
        return false;
      }
    }

    return true;
  }, [tableFilter, columnFilterPropertyNameKeyMap]);

  const filteredAndSortedItems = useMemo(() => {
    if (!items || !tableFilter || !columnFilterPropertyNameKeyMap) return undefined;
    if (!Array.isArray(items)) {
      throw new Error(`Items is not an array (typeof: ${typeof items})`);
    }
    const filteredItems = items.filter(isVisible);
    if (tableFilter.sorting.length) {
      filteredItems.sort(
        createFilterFn(
          tableFilter.sorting,
          (propertyName:string) => columnFilterPropertyNameKeyMap[propertyName],
        ),
      );
    }
    return filteredItems;
  }, [items, tableFilter, columnFilterPropertyNameKeyMap, isVisible]);

  const { page, pageSize } = tableFilter?.query ?? { page: 1, pageSize: 10 };

  const pagedItems = useMemo(() => {
    if (!filteredAndSortedItems) return undefined;
    const skip = Math.max(0, page - 1) * pageSize;
    return filteredAndSortedItems.slice(skip, skip + pageSize);
  }, [page, pageSize, filteredAndSortedItems]);

  const pagedResult = useMemo(() => {
    if (!pagedItems || !filteredAndSortedItems) {
      return undefined;
    }

    return {
      items: pagedItems,
      pageCount: Math.ceil(filteredAndSortedItems.length / pageSize),
      currentPage: page,
      rowCount: filteredAndSortedItems.length,
    };
  }, [filteredAndSortedItems, page, pageSize, pagedItems]);

  return pagedResult;
};
