import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Badge, Button, Card, OverlayTrigger, Spinner, Stack, Modal, Tooltip, Tab, Tabs,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import axios, { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getOrFetchFromApi, useApi, useInvalidateQueries,
} from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import {
  IJob, IJobWithOutput, IListJob, JobStatus,
} from '../../types/Types';
import { ALL_JOB_TYPE_KEYS, Module } from '../../types/AccessTypes';
import { ElementScrollRestoration } from '../../routing/ElementScrollRestoration';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { useModules } from '../../providers/ModuleProvider';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { CodeBox } from '../../common/CodeBox';
import { useDownloadFromApi } from '../../common/useDownloadFromApi';
import { asHumanReadableSize } from '../../utils/StringUtils';
import {
  createPagedColumnHelper, TableCellDateFormatted, usePagedTableFilter, ValidPageSizes, PagedTable,
} from '../../common/table';

interface IAdminJobsFilter {
    ids?: number[],
    moduleId?: number[],
    lookupKey?: string,
    status?: JobStatus[],
    customerName?: string,
    accountName?: string,
    runner?: string
}

/**
 * The main admin page for global CRMS admins
 *
 * The page for the "Global Admin" module
 */
export const AdminJobsPage:IComponentWithLoader<{jobs:PagedResult<IJob>|null}, string|null|undefined> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    id?:string|null|undefined,
  ) => (
    {
      jobs: id
        ? await getOrFetchFromApi<PagedResult<IJob>>(
          queryClient,
          'jobs',
          {
            pageSize,
            ids: id ? [id] : undefined,
          },
        )
        : null,
    }
  ),
  Component: () => {
    const [refreshing, setRefreshing] = useState<Record<number, boolean>>({});
    const { hasModuleRole } = useAccount();
    const { customerModules } = useModules();
    const [searchParams] = useSearchParams();
    const jobId = typeof searchParams.get('id') === 'string'
      ? parseInt(searchParams.get('id')!, 10)
      : null;
    const { getModuleNameOrDefault } = useModules();
    const queryClient = useQueryClient();
    const modal = useNewModalContext();

    const [jobHasData, setJobHasData] = useState<Record<number, boolean>>({});

    const loadJobDetails = useCallback(async (id:number) => {
      try {
        const job = await getOrFetchFromApi<IJobWithOutput>(queryClient, `jobs/${id}?fetchResult=true&notifyChanges=true`);
        jobHasData[job.id] = true;
        setJobHasData({ ...jobHasData });
        return job;
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (axiosErr.response?.status === 404) {
          toast.error('Job output is not available in storage', {
            autoClose: 2500,
          });
        } else {
          toast.error(`Unexpected error while loading job details: ${axiosErr.response?.status} ${axiosErr.message}`);
        }
        jobHasData[id] = false;
        setJobHasData({ ...jobHasData });
        return null;
      }
    }, [jobHasData, queryClient]);

    const pagedTableFilter = usePagedTableFilter<IAdminJobsFilter>(
      'admin-jobs',
      {},
      {
        initialQuery: {
          ids: jobId ? [jobId] : undefined,
        },
      },
    );

    const {
      data: pagedJobs,
      isLoading,
      invalidate: invalidateJobs,
    } = useApi<PagedResult<IJobWithOutput>>(
      'jobs',
      pagedTableFilter.query,
    );

    const columnHelper = useMemo(() => createPagedColumnHelper<IListJob>(), []);

    const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');
    const invalidateRisks = useInvalidateQueries('risks');
    const download = useDownloadFromApi();

    const refreshJob = useCallback(
      async (id:number) => {
        refreshing[id] = true;
        // Copy records for useMemo to detect change
        setRefreshing({ ...refreshing });

        const toastId = `job-${id}-refresh`;

        try {
          const { status: statusBefore } = pagedJobs?.items.find((j) => j.id === id) ?? { status: undefined };
          const { data: job } = await axios.get<IJobWithOutput>(`/api/v1/jobs/${id}?notifyChanges=true`);

          if (job.jobType.lookupKey === 'scan'
            && job.status === JobStatus.Finalized
            && statusBefore !== job.status
          ) {
            invalidateRisks();
            invalidateVulnerabilities();
          }

          toast.info(`Job ${job.id} was refreshed`, {
            toastId,
            updateId: toastId,
          });
        } catch (err) {
          const axiosError = err as AxiosError;
          if (axiosError.response?.status === 423) {
            toast.warning('Job finalization in progress. Please wait a few minutes and try again.', {
              toastId,
              updateId: toastId,
            });
          } else {
            toast.warning(`An error occured refreshing job: ${axiosError.response?.status} ${axiosError.response?.statusText}`, {
              toastId,
              updateId: toastId,
            });
          }
        }

        invalidateJobs();
        refreshing[id] = false;
        setRefreshing({ ...refreshing });
      },
      [invalidateJobs, invalidateRisks, invalidateVulnerabilities, pagedJobs?.items, refreshing],
    );

    const getStatusClassName = (status:JobStatus) => {
      switch (status) {
      case JobStatus.Finalized:
        return 'success';
      case JobStatus.Active:
        return 'primary';
      case JobStatus.Succeeded:
        return 'dark';
      case JobStatus.Failed:
        return 'danger';
      default:
        return 'secondary';
      }
    };

    const allModuleOptions = useMemo(() => customerModules.map((m) => m.id), [customerModules]);

    const columnDefs = useMemo(() => {
      const columns = [
        columnHelper.accessor(
          'id',
          {
            header: 'Id',
            filterPropertyName: 'ids',
            sortPropertyName: 'id',
            updateFilterFn: (values: number[] | null) => pagedTableFilter.appendQuery({
              ids: values ?? undefined,
            }),
            inputType: 'number',
          },
        ),
        columnHelper.accessor(
          'jobType.moduleId',
          {
            header: 'Module',
            cell: ({ value }) => getModuleNameOrDefault(value),
            formatter: getModuleNameOrDefault,
            filterPropertyName: 'moduleId',
            sortPropertyName: 'jobType.moduleId',
            updateFilterFn: (values: number[]) => pagedTableFilter.appendQuery({
              moduleId: values,
            }),
            selectOptions: allModuleOptions,
          },
        ),
        columnHelper.accessor(
          'jobType.lookupKey',
          {
            header: 'Type',
            cell: ({ value }) => value,
            filterPropertyName: 'lookupKey',
            sortPropertyName: 'jobType.lookupKey',
            updateFilterFn: (values: string[]) => pagedTableFilter.appendQuery({
              lookupKey: values.length ? values[0] : undefined,
            }),
            selectOptions: ALL_JOB_TYPE_KEYS,
          },
        ),
        columnHelper.accessor(
          'status',
          {
            header: 'Status',
            cell: ({ value }) => <Badge bg={getStatusClassName(value)}>{value}</Badge>,
            formatter: (value: JobStatus) => String(value),
            filterPropertyName: 'status',
            updateFilterFn: (values: JobStatus[]) => pagedTableFilter.appendQuery({
              status: values,
            }),
            supportMultiSelect: true,
            selectOptions: [
              JobStatus.Active,
              JobStatus.Failed,
              JobStatus.Finalized,
              JobStatus.Pending,
              JobStatus.Succeeded,
              JobStatus.Unknown,
              JobStatus.Waiting,
            ],
          },
        ),
        columnHelper.accessor(
          'customerName',
          {
            header: 'Customer',
            filterPropertyName: 'customerName',
            sortPropertyName: 'customer.name',
            updateFilterFn: (values: string[]) => pagedTableFilter.appendQuery({
              customerName: values.length ? values[0] : undefined,
            }),
          },
        ),
        columnHelper.accessor(
          'accountExternalId',
          {
            header: 'Account',
            defaultHidden: true,
            filterPropertyName: 'accountName',
            sortPropertyName: 'account.externalId',
            updateFilterFn: (values: string[]) => pagedTableFilter.appendQuery({
              accountName: values.length ? values[0] : undefined,
            }),
          },
        ),
        columnHelper.accessor('created', {
          header: 'Created',
          cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
          disableFilter: true,
        }),
        columnHelper.accessor('started', {
          header: 'Started',
          cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
          defaultHidden: true,
          disableFilter: true,
        }),
        columnHelper.accessor('ended', {
          header: 'Finished',
          cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
          disableFilter: true,
        }),
        columnHelper.accessor('updated', {
          header: 'Last updated',
          cell: ({ value }) => TableCellDateFormatted(value, { timeStyle: 'medium' }),
          defaultHidden: true,
          disableFilter: true,
        }),
        columnHelper.accessor(
          'runner',
          {
            header: 'Runner',
            cell: ({ value }) => (
              <span className="font-monospace small">{value}</span>
            ),
            defaultHidden: true,
            filterPropertyName: 'runner',
            updateFilterFn: (values: string[]) => pagedTableFilter.appendQuery({
              runner: values.length ? values[0] : undefined,
            }),
          },
        ),
        {
          ...columnHelper.accessor('input', {
            header: 'Input',
            className: 'text-end',
            cell: ({ value, row }) => (value ? (
              <OverlayTrigger
                placement="auto"
                overlay={(
                  <Tooltip>
                    <div>Click to show</div>
                  </Tooltip>
                )}
              >
                <Button
                  variant="link"
                  size="sm"
                  onClick={async () => {
                    modal.pushModal({
                      title: `Job ${row.original.id} input`,
                      size: 'lg',
                      nofocus: true,
                      ModalBodyAndFooter: ({ close }) => (
                        <>
                          <Modal.Body>
                            <CodeBox text={value} />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              onClick={() => {
                                close(true);
                              }}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </>
                      ),
                    });
                  }}
                >
                  <Icon name="copy" size="18" />
                </Button>
              </OverlayTrigger>
            ) : null),
          }),
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          ...columnHelper.display({
            id: 'output',
            header: 'Output',
            className: 'text-end',
            cell: ({ row }) => {
              const jobOutput = jobHasData[row.original.id];
              if (jobOutput === false) return null;

              if (
                row.original.status === JobStatus.Finalized
                || row.original.status === JobStatus.Failed
              ) {
                return (
                  <OverlayTrigger
                    placement="auto"
                    overlay={(
                      <Tooltip>
                        <div>Click to show</div>
                      </Tooltip>
                    )}
                  >
                    <Button
                      variant="link"
                      size="sm"
                      onClick={async () => {
                        const job = await loadJobDetails(row.original.id);

                        if (!job) return;

                        modal.pushModal({
                          title: `Job ${job.id} output`,
                          size: 'xl',
                          nofocus: true,
                          ModalBodyAndFooter: ({ close }) => (
                            <>
                              <Modal.Body>
                                <Tabs className="secondary">
                                  {job.error ? (
                                    <Tab title="Error" eventKey="error">
                                      <pre className="pre-wrap mt-3">
                                        {job.error}
                                      </pre>
                                    </Tab>
                                  ) : null}
                                  {job.output ? (
                                    <Tab title="Output" eventKey="output">
                                      <pre className="pre-wrap mt-3">
                                        {job.output}
                                      </pre>
                                    </Tab>
                                  ) : null}
                                  {job.log ? (
                                    <Tab title="Log" eventKey="log">
                                      <pre className="pre-wrap mt-3">
                                        {job.log}
                                      </pre>
                                    </Tab>
                                  ) : null}
                                </Tabs>
                              </Modal.Body>
                              <Modal.Footer>
                                <Stack direction="horizontal" gap={2}>
                                  {job.output ? (
                                    <Button
                                      variant="secondary"
                                      onClick={() => download(
                                          `jobs/${job.id}/downloadOutput`,
                                      )}
                                    >
                                      Download output
                                      {job.outputSizeBytes
                                        ? ` (${asHumanReadableSize(
                                          job.outputSizeBytes,
                                          2,
                                        )})`
                                        : null}
                                      <Icon
                                        name="download"
                                        className="ms-2"
                                        size={16}
                                      />
                                    </Button>
                                  ) : null}
                                  {job.log ? (
                                    <Button
                                      variant="secondary"
                                      onClick={() => download(`jobs/${job.id}/downloadLog`)}
                                    >
                                      Download log
                                      <Icon
                                        name="download"
                                        className="ms-2"
                                        size={16}
                                      />
                                    </Button>
                                  ) : null}
                                  <Button
                                    onClick={() => {
                                      close(true);
                                    }}
                                  >
                                    Close
                                  </Button>
                                </Stack>
                              </Modal.Footer>
                            </>
                          ),
                        });
                      }}
                    >
                      <Icon name="copy" size="18" />
                    </Button>
                  </OverlayTrigger>
                );
              }

              return (
                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip>Click to refresh</Tooltip>}
                >
                  <Button
                    className="overflow-hidden"
                    variant="primary"
                    size="sm"
                    disabled={refreshing[row.original.id]}
                    onClick={async () => refreshJob(row.original.id)}
                  >
                    {refreshing[row.original.id] ? (
                      <div className="overflow-hidden">
                        <Spinner size="sm" className="mt-1" />
                      </div>
                    ) : (
                      <Icon name="refresh-cw" size="18" />
                    )}
                  </Button>
                </OverlayTrigger>
              );
            },
            disableFilter: true,
            disableHiding: true,
          }),
        },
      ];
      return columns;
    }, [
      columnHelper,
      getModuleNameOrDefault,
      allModuleOptions,
      pagedTableFilter,
      modal,
      jobHasData,
      refreshing,
      loadJobDetails,
      download,
      refreshJob,
    ]);

    return !hasModuleRole(Module.admin, 'read') ? null : (
      <>
        <Card className="fill-content">
          <Card.Body className="overflow-auto" id="jobs-card">
            <PagedTable
              data={pagedJobs}
              filters={pagedTableFilter}
              isLoading={isLoading}
              columnDefs={columnDefs}
              refresh={invalidateJobs}
            />
          </Card.Body>
        </Card>
        <ElementScrollRestoration targetId="jobs-card" />
      </>
    );
  },
};

export default AdminJobsPage;
