import React, { useMemo, useState } from 'react';
import {
  Spinner, Col, Row, Badge, Stack, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomerUserDetailsModal from './CustomerUserDetailsModal';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { ICustomerSettingsUser } from './CustomerTypes';
import AddAssignableUserModal from './AddAssignableUserModal';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { createPagedColumnHelper, PagedTable, usePagedTableFilter } from '../../common/table';

const toastOptions = {
  toastId: 'user-add-toast',
  updateId: 'user-add-toast',
};

/**
 * User management tab
 *
 * Rendered by CustomerSettingsPage
 */
const CustomerUserManagementTab = () => {
  const { hasModuleRole } = useAccount();
  const { pushModal } = useNewModalContext();

  const [selected, setSelected] = useState<ICustomerSettingsUser|undefined>();

  const handleOpen = (userToOpen: ICustomerSettingsUser) => {
    setSelected(userToOpen);
  };
  const handleClose = () => setSelected(undefined);

  const getRowClassNames = (userToOpen:ICustomerSettingsUser) => {
    const classes = [];
    if (!userToOpen.active) {
      classes.push('text-muted');
    }
    return classes.join(' ');
  };

  const columnHelper = createPagedColumnHelper<ICustomerSettingsUser>();

  const filters = usePagedTableFilter<{
    name?:string,
    externalId?:string,
    active?:boolean
  }>(
    'customer-accounts',
    {},
  );

  const { query, appendQuery } = filters;

  const columnDefs = useMemo(() => ([
    columnHelper.accessor('name', {
      header: 'Name',
      updateFilterFn: (values:string[]) => {
        appendQuery({
          ...query,
          name: values.length ? values[0] : undefined,
        });
      },
    }),
    columnHelper.accessor('externalId', {
      header: 'UPN',
      sortPropertyName: 'externalId',
      updateFilterFn: (values:string[]) => {
        appendQuery({
          ...query,
          externalId: values.length ? values[0] : undefined,
        });
      },
    }),
    columnHelper.accessor('active', {
      header: 'Active',
      cell: ({ value: active }) => (active
        ? <Badge bg="success">Yes</Badge>
        : <Badge bg="danger">No</Badge>
      ),
      selectOptions: [true, false],
      updateFilterFn: (values:boolean[]) => {
        appendQuery({
          ...query,
          active: values.length ? values[0] : undefined,
        });
      },
    }),
  ]), [appendQuery, columnHelper, query]);

  const {
    data: pagedUsers,
    invalidate: invalidateUsers,
  } = useApi<PagedResult<ICustomerSettingsUser>>('module/customer/accounts', query);

  return !pagedUsers
    ? <Spinner animation="border" />
    : (
      <>
        <Row>
          <Col md={12} className="overflow-auto">
            <PagedTable
              columnDefs={columnDefs}
              filters={filters}
              data={pagedUsers}
              disableColumnSelect
              rowClassNameResolver={getRowClassNames}
              onRowClick={(_, userToOpen) => {
                handleOpen(userToOpen);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button
                disabled={!hasModuleRole(Module.customerAdmin, 'readWrite')}
                onClick={async () => {
                  const addedUser = await pushModal({
                    title: 'Add user',
                    size: 'lg',
                    ModalBodyAndFooter: ({ close }) => (
                      <AddAssignableUserModal close={close} />
                    ),
                  }) as ICustomerSettingsUser&{invite:boolean}|undefined;

                  if (!addedUser) {
                    return;
                  }

                  setSelected(addedUser);

                  toast.success(
                    (
                      <>
                        <strong>
                          { addedUser.invite
                            ? (
                              <>
                                {addedUser.externalId}
                                {' '}
                                was invited
                              </>
                            ) : (
                              <>
                                {addedUser.name}
                                {' '}
                                was added
                              </>
                            ) }
                        </strong>
                        <div className="mt-3">
                          The added user has limited permissions by default, please grant permissions as required.
                        </div>
                      </>
                    ), toastOptions,
                  );
                  setSelected(addedUser);

                  await invalidateUsers();
                }}
              >
                Add account
              </Button>
            </Stack>
            { selected
              ? <CustomerUserDetailsModal disabled={false} user={selected} show handleClose={handleClose} />
              : undefined }
          </Col>
        </Row>
      </>
    );
};

export default CustomerUserManagementTab;
