import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IAsset } from '../../types/AssetsTypes';
import { PagedResult } from '../../types/PagedResult';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import {
  AssetType, assetTypesAsString, IAssetListOptions, IKeyValue,
} from '../../types/Types';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import {
  createPagedColumnHelper, PagedTable, TableCellDateFormatted, IPagedTableFilter,
} from '../../common/table';

interface IAssetAndAdvisories extends IAsset {
  securityAdvisoryIds?:string[]
}

/**
 * Table for showing assets.
 */
export const AssetsTable = ({
  pagedAssets,
  pagedFilters,
}:{
  pagedAssets?:PagedResult<IAsset>,
  pagedFilters:IPagedTableFilter<IAssetListOptions>
}) => {
  const navigate = useNavigate();
  const assetTypeAsText = useAssetTypeAsText();

  const columnHelper = createPagedColumnHelper<IAssetAndAdvisories>();

  const { appendQuery, query } = pagedFilters;

  const assetColumns = React.useMemo(() => {
    const columns = [
      columnHelper.accessor('friendlyId', {
        header: 'Unique ID',
        cell: ({ value }) => (value
          ? (
            <ClipboardCopy>
              {value}
            </ClipboardCopy>
          ) : null),
        disableColumnDefault: true,
        defaultHidden: true,
        sortPropertyName: 'friendlyId',
        filterPropertyName: 'assetFriendlyIds',
        updateFilterFn: (values:string[]) => {
          appendQuery({
            assetFriendlyIds: values,
          });
        },
      }),
      {
        ...columnHelper.accessor('name', {
          header: 'Name',
          disableHiding: true,
          cell: ({ value }) => (
            <ClipboardCopy>
              {value}
            </ClipboardCopy>
          ),
          filterPropertyName: 'name',
          updateFilterFn: (values:string[]) => {
            appendQuery({
              name: values.length ? values[0] : undefined,
            });
          },
        }),
      },
      columnHelper.accessor('type', {
        header: 'Type',
        cell: ({ value }) => (value
          ? (
            <span>
              {assetTypeAsText(value)}
            </span>
          ) : null),
        formatter: (v:AssetType) => assetTypeAsText(v, false),
        filterPropertyName: 'types',
        sortPropertyName: 'type',
        updateFilterFn: (values:AssetType[]) => {
          appendQuery({
            types: values,
          });
        },
        selectOptions: assetTypesAsString,
        supportMultiSelect: true,
      }),
      columnHelper.accessor(
        'created',
        {
          header: 'Created',
          cell: ({ value }) => TableCellDateFormatted(value),
          disableFilter: true,
          filterPropertyName: 'created',
        },
      ),
      columnHelper.accessor(
        'vulnerabilityCount',
        {
          header: 'Vulnerability count',
          cell: ({ value }) => TableCellDateFormatted(value),
          disableFilter: true,
          filterPropertyName: 'vulnerabilityCount',
        },
      ),
      {
        ...columnHelper.accessor('properties', {
          header: 'Properties',
          cell: ({ value }) => (
            <ul>
              { value?.map((p) => (
                <li key={p.key}>
                  {p.key}
                  =
                  {p.value}
                </li>
              ))}
            </ul>
          ),
          defaultHidden: true,
          filterPropertyName: 'properties',
          updateFilterFn: (values:string[]) => {
            appendQuery({
              properties: values.length
                ? values[0].split(',').reduce((acc, pair) => {
                  const [key, value] = pair.split('=');
                  if (key && value) {
                    acc.push({ key: key.trim(), value: value.trim() });
                  }
                  return acc;
                }, [] as IKeyValue[])
                : undefined,
            });
          },
          filterFormatter: (values:unknown[]) => (
            values ? (values as IKeyValue[]).map((v) => `${v.key}=${v.value}`, '').join(', ') : ''
          ),
        }),
      },
    ];

    // Only add securityAdvisoryIds if filtered. If filtered, we know the
    // asset has the advisory and add that from the filter. We do not
    // expand this server side, due to the database cost.
    if (query.securityAdvisoryIds?.length) {
      columns.push(columnHelper.accessor(
        'securityAdvisoryIds',
        {
          header: 'Security Advisory',
          cell: ({ value }) => (value
            ? <>{value.map((advisoryId) => <ClipboardCopy key={advisoryId}>{advisoryId}</ClipboardCopy>)}</>
            : null),
          defaultHidden: true,
          filterPropertyName: 'securityAdvisoryIds',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              securityAdvisoryIds: values.length ? values : undefined,
            });
          },
        },
      ));
    }

    return columns;
  }, [appendQuery, assetTypeAsText, columnHelper, query.securityAdvisoryIds]);

  const onRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, object: IAssetAndAdvisories) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/asset/${object?.id}`, '_blank');
      } else {
        navigate(`/asset/${object?.id}`);
      }
    }
  };

  return (
    <div>
      <PagedTable
        columnDefs={assetColumns}
        data={pagedAssets}
        filters={pagedFilters}
        onRowClick={onRowClick}
      />
    </div>
  );
};
