/* eslint-disable no-continue */
import { IAsset } from '../../types/AssetsTypes';
import { AssetType, assetTypesAsString } from '../../types/Types';
import { isChildIndex } from '../../utils/Utils';
import { Actions, IState, IStateOperations } from './AssessmentStateStore';
import {
  IAssessment, IAssessmentAsset, IAssessmentControl, IAssessmentOption, IAssessmentQuestion, IAssessmentSyncAsset,
  IAssessmentSyncVulnerability, IAssetQuestionContext, IHasAssessmentQuestions, IIsApplicable,
  IQuestionContext, ISelectedOption,
} from './AssessmentTypes';

export const createAssessmentStateManager = (
  assessment:IAssessment|undefined,
  state:IState&IStateOperations,
  onAssetCompleted?:(asset:IAssessmentSyncAsset, hasSkippedQuestions:boolean) => void,
) => (
  (assessment && state
    // eslint-disable-next-line no-use-before-define
    ? new AssessmentStateManager(assessment, state, onAssetCompleted)
    : undefined)
);

export class AssessmentStateManager {
  private p_state:IState&IStateOperations;

  private p_assessment:IAssessment;

  private p_assessible_asset_types:AssetType[];

  private event_on_asset_completed:((asset:IAssessmentSyncAsset, hasSkippedQuestions:boolean) => void)|undefined;

  constructor(
    assessment:IAssessment,
    state:IState&IStateOperations,
    onAssetCompleted?:(asset:IAssessmentSyncAsset, hasSkippedQuestions:boolean) => void,
  ) {
    this.p_state = state;
    this.p_assessment = assessment;
    this.p_assessible_asset_types = AssessmentStateManager.resolveAssessableAssetTypes(this.p_assessment);
    this.event_on_asset_completed = onAssetCompleted;
  }

  private static resolveAssessableAssetTypes(assessment:IAssessment) : AssetType[] {
    const allAssetTypes:AssetType[] = assetTypesAsString.map((t) => t as AssetType);

    let assetTypes:AssetType[] = [];
    for (let i = 0; i < assessment.script.length; i += 1) {
      const control = assessment.script[i];
      if (control.if === undefined) {
        return allAssetTypes;
      }
      assetTypes = assetTypes.concat(control.if);
    }

    return assetTypes.filter(AssessmentStateManager.onlyUnique);
  }

  private static onlyUnique<T>(value:T, index:number, array:T[]) {
    return array.indexOf(value) === index;
  }

  public getAssessableAssetTypes() {
    return this.p_assessible_asset_types;
  }

  hasActiveAsset() {
    return this.p_state.assets.length > 0;
  }

  getAsset(uniqueId:number) {
    return this.p_state.assets.find((a) => a.uniqueId === uniqueId);
  }

  getAssetCount() {
    return this.p_state.assets.length;
  }

  getAssets() {
    return [...this.p_state.assets];
  }

  getActive() {
    if (!this.hasActiveAsset()) {
      throw new Error('There is no active asset');
    }
    return {
      asset: this.p_state.assets[this.p_state.assets.length - 1],
      control: this.p_state.currentControl,
      question: this.p_state.currentQuestion,
      index: this.p_state.index,
    };
  }

  activateAsset(asset:IAssessmentAsset|undefined) {
    if (!asset) return;

    this.p_state.stack = [];

    this.p_state.assets.push({
      ...asset,
      uniqueId: this.getNextAssetUniqueId(),
    });

    this.p_state.update({ ...this.p_state });
    this.gotoNext();
  }

  private getNextAssetUniqueId() {
    const maxId = this.p_state.assets.length === 0
      ? -1
      : Math.max.apply(null, this.p_state.assets.map((a) => a.uniqueId));

    return maxId + 1;
  }

  resolveSelectableAssets(customerAssets:IAsset[]) {
    let selectableAssets:IAssessmentAsset[] = customerAssets.map((a) => (
      {
        id: a.id,
        name: a.name,
        type: a.type,
        friendlyId: a.friendlyId,
      }
    ));

    if (this.p_assessment.assets) {
      this.p_assessment.assets.forEach((a) => {
        if (!selectableAssets.find((sa) => (
          (sa.friendlyId !== undefined && sa.friendlyId === a.id)
        ))) {
          selectableAssets.push({
            friendlyId: a.id,
            name: a.name,
            type: a.type,
          });
        }
      });
    }

    selectableAssets = selectableAssets
      .filter((customerAsset) => (
        this.p_assessible_asset_types.includes(customerAsset.type)
        && !this.getAssets().find((assessedAsset) => (
          customerAsset.friendlyId === assessedAsset.friendlyId
        ))
      ));

    selectableAssets.sort((a, b) => {
      if (a === b) return 0;
      return a.name.localeCompare(b.name);
    });

    return selectableAssets;
  }

  assetHasVulnerabilities(asset:IAssessmentSyncAsset) {
    if (!asset) {
      return false;
    }
    const assetKey = AssessmentStateManager.getAssessmentAssetIndexedKey(asset);

    const activeAssetHasVulnerabilities = Object
      .keys(this.p_state.vulnerabilities)
      .find((k) => k.startsWith(assetKey));

    return !!activeAssetHasVulnerabilities?.length;
  }

  hasActiveQuestion() {
    return this.hasActiveAsset() && this.p_state.currentControl && this.p_state.currentQuestion;
  }

  getCurrentStepKey() {
    return JSON.stringify(this.p_state.index);
  }

  canUndo() {
    return this.p_state.stack.length > 0;
  }

  getVulnerabilities() {
    return { ...this.p_state.vulnerabilities };
  }

  getVulnerabilityCount() {
    return Object.keys(this.p_state.vulnerabilities).length;
  }

  undoToPrevious() {
    const last = this.p_state.stack.pop();
    // If there are no more items to pop
    if (!last) return;
    // Remove vulnerabilities added by the undone question
    if (last.vulnerabilityKey) {
      delete this.p_state.vulnerabilities[last.vulnerabilityKey];
    }
    // If skipped, remove question from skipped question
    if (this.hasActiveAsset()) {
      const { asset: activeAsset } = this.getActive();
      if (this.p_state.skipped[activeAsset.uniqueId]) {
        const skippedIndex = this.p_state.skipped[activeAsset.uniqueId].findIndex((index) => (
          JSON.stringify(index) === JSON.stringify(last.questionIndex)
        ));
        if (skippedIndex >= 0) {
          this.p_state.skipped[activeAsset.uniqueId].splice(skippedIndex, 1);
        }
      }
    }
    // Activate the question
    this.activateIndex(last.questionIndex);
  }

  canSkip() {
    const { asset } = this.getActive();
    const currentQuestion = this.getQuestionContextForAssetAtIndex(asset, this.p_state.index);
    // Only allow skip if options does not contain goto questions, or if all goto questions
    // are child questions of this node.
    return !currentQuestion.question.options.some((o) => {
      if (!o.goto) return false;
      const gotoContext = this.findGotoQuestion(o.goto);
      return !gotoContext || !isChildIndex(this.p_state.index, gotoContext?.index);
    });
  }

  skipCurrentQuestion() {
    const { asset } = this.getActive();
    const currentQuestion = this.getQuestionContextForAssetAtIndex(asset, this.p_state.index);

    if (!this.canSkip()) {
      return;
    }

    if (!currentQuestion) {
      throw new Error('Index is not for a question');
    }

    if (this.p_state.assets.length > 0) {
      const skippedIndex = [...this.p_state.index];
      const currentAsset = this.p_state.assets[this.p_state.assets.length - 1];
      const nextState = this.getStateFromQuestion(
        this.skipToNextQuestion(this.p_state.index),
      );

      if (!this.p_state.skipped[currentAsset.uniqueId]) {
        this.p_state.skipped[currentAsset.uniqueId] = [];
      }
      this.p_state.skipped[currentAsset.uniqueId].push(skippedIndex);
      this.p_state.stack.push({
        questionIndex: skippedIndex,
      });
      this.activateState(nextState);
    }
  }

  discard() {
    this.p_state.clear();
  }

  discardAsset(asset:IAssessmentSyncAsset) {
    if (!this.hasActiveAsset()) {
      return;
    }

    const newState = { ...this.p_state };

    const assetIndex = this.p_state.assets.findIndex((a) => a.uniqueId === asset.uniqueId);
    // Asset was not found
    if (assetIndex < 0) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const assetKey = AssessmentStateManager.getAssessmentAssetIndexedKey(this.p_state.assets[assetIndex]);
    Object.keys(this.p_state.vulnerabilities).forEach((k) => {
      if (k.startsWith(assetKey)) {
        delete newState.vulnerabilities[k];
      }
    });
    delete newState.skipped[asset.uniqueId];

    // If asset is active asset, unset and return to start page
    if (assetIndex === newState.assets.length - 1) {
      newState.currentControl = undefined;
      newState.currentQuestion = undefined;
      newState.index = [];
      newState.stack = [];
    }

    // Remove the asset
    newState.assets.splice(assetIndex, 1);

    this.p_state.update(newState);
  }

  toString() {
    return JSON.stringify(this.p_state, null, 2);
  }

  activate() {
    this.activateIndex(this.p_state.index);
    return this;
  }

  getControlLibrary() {
    return this.p_assessment.library;
  }

  async registerAnswer(
    answer:ISelectedOption,
    vulnerabilityAnnotator:(vulnerability:IAssessmentSyncVulnerability) => Promise<IAssessmentSyncVulnerability>,
  ) {
    const { asset } = this.getActive();
    const key = AssessmentStateManager.getAssessmentAssetIndexedKey(asset, this.p_state.index);

    // Remove any existing vulnerabilities for this question and any descedants.
    // This can happen if we re-assess a question.
    Object.keys(this.p_state.vulnerabilities).forEach((k) => {
      if (k.startsWith(key)) {
        delete this.p_state.vulnerabilities[k];
      }
    });

    if (answer.option.vulnerabilities?.length) {
      this.p_state.vulnerabilities[key] = answer.option.vulnerabilities.map((v) => ({
        ...v,
        controlFriendlyId: this.p_state.currentControl?.id,
        // controlFriendlyId: this.p_state.control?.friendlyId,
        assetUniqueId: asset.uniqueId,
        mitigationPercent: 0,
      } as IAssessmentSyncVulnerability));

      const promises = this.p_state.vulnerabilities[key].map(async (vulnerability) => (
        vulnerabilityAnnotator(vulnerability)
      ));

      this.p_state.vulnerabilities[key] = await Promise.all(promises);
      this.p_state.stack.push({
        questionIndex: [...this.p_state.index],
        vulnerabilityKey: key,
      });
    } else {
      this.p_state.stack.push({
        questionIndex: [...this.p_state.index],
      });
    }

    this.p_state.update(this.p_state);
    this.gotoNext(answer);
  }

  private gotoNext(answer?:ISelectedOption) {
    this.activateState(
      this.nextApplicableState(answer),
    );
  }

  private assetHasSkippedQuestions(asset:IAssessmentSyncAsset) {
    return this.p_state.skipped[asset.uniqueId] && this.p_state.skipped[asset.uniqueId].length > 0;
  }

  private hasSkippedQuestions() {
    return Object.values(this.p_state.skipped).some((arr) => arr.length > 0);
  }

  getSkippedQuestionsCount() {
    return Object.values(this.p_state.skipped).reduce((prev, curr) => prev + curr.length, 0);
  }

  getSkippedQuestions() {
    const questionMap:Record<number, IAssetQuestionContext[]> = {};

    (Object.keys(this.p_state.skipped)).forEach((assetUniqueIdAsString) => {
      const asset = this.getAsset(parseInt(assetUniqueIdAsString, 10));
      if (!asset) return;

      questionMap[asset.uniqueId] = this.p_state.skipped[asset.uniqueId].map((index) => (
        this.getQuestionContextForAssetAtIndex(asset, index)
      ));
    });

    return questionMap;
  }

  private activateState(nextState:IState|undefined) {
    if (nextState) {
      this.p_state = {
        ...this.p_state,
        ...nextState,
      };
    } else {
      this.p_state = {
        ...this.p_state,
        currentControl: undefined,
        currentQuestion: undefined,
        current: this.p_state.current.action === Actions.resumeQuestion
          ? {
            action: Actions.default,
          }
          : this.p_state.current,
        stack: [],
        index: [],
      };
    }
    this.p_state.update(this.p_state);

    // Setting an empty state, means that the current asset is done processing
    if (!nextState) {
      const { asset } = this.getActive();
      if (this.event_on_asset_completed) {
        this.event_on_asset_completed(
          asset,
          this.assetHasSkippedQuestions(asset),
        );
      }
    }
  }

  isReady() {
    return this.p_state.hasHydrated;
  }

  canBeFinalized() {
    return this.p_state.assets.length > 0
    && !this.hasSkippedQuestions()
    && !this.p_state.currentControl
    && !this.p_state.currentQuestion;
  }

  getState() {
    return { ...this.p_state };
  }

  resumeQuestion(asset:IAssessmentSyncAsset, index:number[]) {
    if (!this.p_state.skipped[asset.uniqueId]) {
      throw new Error(`Asset ${asset.uniqueId} does not have skipped questions`);
    }

    const skippedIndex = this.p_state.skipped[asset.uniqueId].findIndex((i) => (
      JSON.stringify(i) === JSON.stringify(index)
    ));

    if (skippedIndex < 0) {
      throw new Error(`Asset ${asset.uniqueId} does not have a skipped question ${JSON.stringify(index)}`);
    }

    this.p_state.skipped[asset.uniqueId].splice(skippedIndex, 1);

    this.p_state = {
      ...this.p_state,
      skipped: {
        ...this.p_state.skipped,
      },
      index,
      current: {
        action: Actions.resumeQuestion,
        params: {
          index,
          asset,
        },
      },
    };
    this.p_state.update(this.p_state);
    this.activateIndex(this.p_state.index);
  }

  private findNextQuestion(afterIndex:number[]) : IQuestionContext|undefined {
    if (!this.p_assessment.script || afterIndex.length === 0) {
      return undefined;
    }

    const { asset } = this.getActive();

    const item = this.getItemAtIndex(afterIndex);
    if (afterIndex.length > 1 && afterIndex.length % 2 !== 0) {
      const asOption = item as IAssessmentOption;
      if (asOption?.goto) {
        try {
          // We do not allow skipping questions with goto-options that takes the user out of
          // the current node. This would prevent us from knowing when the skipped question was
          // answered. The canSkip method will prevent the UI from allowing this, and we throw
          // an error if this is detected.
          const context = this.findGotoQuestion(asOption?.goto);
          if (!context) return undefined;

          if (!AssessmentStateManager.isApplicable(context?.control, asset.type)
            || !AssessmentStateManager.isApplicable(context?.question, asset.type)) {
            throw new Error('Goto question is not applicable for this asset');
          }
          if (!this.isValidNextQuestion(context?.index)) {
            throw new Error('Cannot goto ancestor when resuming a skipped question');
          }
          return context;
        } catch {
          // Goto question is not applicable for this asset. Abort further execution.
          return undefined;
        }
      }
    }

    const question = this.findNextApplicableQuestion(asset, afterIndex);

    return this.isValidNextQuestion(question?.index)
      ? question
      : undefined;
  }

  private isValidNextQuestion(nextIndex:number[]|undefined) {
    return this.p_state.current.action === Actions.default
      || !nextIndex
      || isChildIndex(this.p_state.index, nextIndex);
  }

  private static isApplicable(applicable:IIsApplicable, assetType:AssetType) {
    return applicable && (!applicable.if || applicable.if.includes(assetType));
  }

  private getItemAtIndex(index:number[]) : IAssessmentControl|IAssessmentOption|IAssessmentQuestion|undefined {
    if (index.length === 0) {
      return undefined;
    }

    const control = this.p_assessment.script[index[0]];
    if (!control) {
      return undefined;
    }

    if (index.length === 1) {
      return control;
    }

    return this.recurseToIndex(control, index.slice(1));
  }

  private recurseToIndex(hasQuestions:IHasAssessmentQuestions, index:number[])
  : IAssessmentOption|IAssessmentQuestion|undefined {
    if (index.length === 0 || !hasQuestions?.questions) {
      return undefined;
    }

    const question = hasQuestions.questions[index[0]];
    if (!question) {
      return undefined;
    }

    if (index.length === 1) {
      return question;
    }
    const option = question.options[index[1]];
    if (index.length === 2) {
      return option;
    }
    return this.recurseToIndex(option, index.slice(2));
  }

  /**
   * From `startIndex` or the first control, traverses the script until the
   * first question where `matchFunction` returns true.
   *
   * @param matchFunction Function to check question applicability
   * @param startIndex Optional startIndex, if omitted, traversal starts at the first question
   * @returns First matching question or undefined if no question was found
   */
  private traverseToMatchingQuestion(
    matchFunction: (control:IAssessmentControl, question:IAssessmentQuestion, index:number[]) => boolean,
    startIndex?: number[]|undefined,
  ) : IQuestionContext|undefined {
    const nextIndex = startIndex?.length ? [...startIndex] : [-1];
    for (;nextIndex[0] < this.p_assessment.script.length;) {
      // Step 1: bump to next index that has a question
      if (nextIndex.length === 1) {
        nextIndex.push(0);
        // If control has no questions, goto next control
        if (!this.getItemAtIndex(nextIndex)) {
          nextIndex.pop();
          nextIndex[nextIndex.length - 1] += 1;
        }
      } else if (nextIndex.length % 2 === 0) {
        // Index is for a question
        nextIndex[nextIndex.length - 1] += 1;
        // If there are no more questions, skip to next sibling
        if (!this.getItemAtIndex(nextIndex)) {
          // If we're in an option question, pop to next question
          nextIndex.pop();
          if (nextIndex.length > 2) {
            nextIndex.pop();
          }
          nextIndex[nextIndex.length - 1] += 1;
        }
      } else {
        // Index is option, check if option has a question
        nextIndex.push(0);
        if (!this.getItemAtIndex(nextIndex)) {
          nextIndex.pop();
          nextIndex.pop();
          nextIndex[nextIndex.length - 1] += 1;
          if (!this.getItemAtIndex(nextIndex) && nextIndex.length > 2) {
            nextIndex.pop();
            nextIndex.pop();
            nextIndex[nextIndex.length - 1] += 1;
          }
        }
      }

      // Step 2: nextIndex points to a question, check if its applicable
      // and return or try the next one.

      // If we've traversed to the next control, continue loop to find question
      if (nextIndex.length === 1) {
        continue;
      }

      const question = this.getItemAtIndex(nextIndex) as IAssessmentQuestion;

      // Skip to next iteration if index does not contain an item
      if (!question) {
        continue;
      }
      // Sanity check the item in case we've messed up the logic above
      if (question && !question.text) {
        throw new Error(`Expected index ${JSON.stringify(nextIndex)} to resolve to a question. Got: ${JSON.stringify(question)}`);
      }

      if (matchFunction(this.p_assessment.script[nextIndex[0]], question, nextIndex)) {
        return { control: this.p_assessment.script[nextIndex[0]], question, index: nextIndex };
      }
    }

    return undefined;
  }

  private static getGotoControlId(goto:string) {
    const matches = goto.match(/^c(.*)$/);
    if (matches) {
      return matches[1];
    }
    return undefined;
  }

  private static getGotoQuestionId(goto:string) {
    const matches = goto.match(/^q(.*)$/);
    if (matches) {
      return matches[1];
    }
    return undefined;
  }

  private findGotoQuestion(goto:string) : IQuestionContext|undefined {
    const controlId = AssessmentStateManager.getGotoControlId(goto);
    const questionId = AssessmentStateManager.getGotoQuestionId(goto);
    const { asset } = this.getActive();

    if (controlId === undefined && questionId === undefined) {
      throw new Error(`Invalid goto: ${goto}`);
    }

    const context = controlId !== undefined
      ? this.getControlIdContext(asset, controlId)
      : questionId && this.getQuestionIdContext(asset, questionId);

    if (!context) {
      return undefined;
    }

    if (
      !AssessmentStateManager.isApplicable(context.control, asset.type)
      || !AssessmentStateManager.isApplicable(context.question, asset.type)
      || !context.question.options?.length
    ) {
      throw new Error('Goto question is not applicable for this asset');
    }
    return context;
  }

  private getControlIdContext(asset:IAssessmentSyncAsset, controlId:string) : IQuestionContext|undefined {
    const controlIndex = this.p_assessment.script.findIndex((c) => c.id === controlId);

    if (controlIndex < 0) {
      return undefined;
    }

    return this.findNextApplicableQuestion(asset, [controlIndex]);
  }

  private getQuestionIdContext(
    asset:IAssessmentSyncAsset,
    questionId:string,
  ) : IQuestionContext|undefined {
    for (let i = 0; i < this.p_assessment.script.length; i += 1) {
      const control = this.p_assessment.script[i];
      const context = AssessmentStateManager.traverseQuestions(
        control,
        [i],
        (question) => question.id === questionId,
      );

      if (context) {
        return {
          ...context,
          control,
        };
      }
    }
    return undefined;
  }

  private static traverseQuestions(
    hasQuestions:IHasAssessmentQuestions|undefined,
    index:number[],
    isMatch:(question:IAssessmentQuestion) => boolean,
  ) : { question:IAssessmentQuestion, index:number[] }|undefined {
    if (hasQuestions?.questions === undefined) return undefined;
    for (let i = 0; i < hasQuestions.questions.length; i += 1) {
      const question = hasQuestions.questions[i];
      if (isMatch(question)) {
        return { question, index: [...index, i] };
      }

      for (let j = 0; j < question.options.length; j += 1) {
        const option = question.options[j];
        const context = this.traverseQuestions(option, [...index, i, j], isMatch);
        if (context) {
          return context;
        }
      }
    }
    return undefined;
  }

  private findFirstQuestion() {
    return this.findNextQuestion([-1]);
  }

  private activateIndex(index:number[]) {
    if (index.length === 0) {
      return;
    }

    const { asset } = this.getActive();

    const questionIndex = this.toNearestQuestionIndex(asset, index);

    if (!questionIndex) {
      throw new Error(`Question ${JSON.stringify(index)} does not exist`);
    }

    if (questionIndex) {
      this.activateState(
        this.getStateFromQuestion(
          this.getQuestionContextForAssetAtIndex(asset, questionIndex),
        ),
      );
    }
  }

  private toNearestQuestionIndex(asset:IAssessmentSyncAsset, index:number[]) {
    if (index.length <= 1) {
      const context = this.findNextApplicableQuestion(asset, index);
      return context ? context.index : undefined;
    } if (index.length % 2 !== 0) {
      return index.slice(0, -1);
    }
    return index;
  }

  getQuestionContextForAssetAtIndex(asset:IAssessmentSyncAsset, index:number[]) {
    if (index.length === 1 || index.length % 2 !== 0) {
      throw new Error(`Not a question index: ${JSON.stringify(index)}`);
    }

    const control = this.getItemAtIndex(index.slice(0, 1)) as IAssessmentControl;
    const question = this.getItemAtIndex(index) as IAssessmentQuestion;

    if (!question) {
      throw new Error(`Question ${JSON.stringify(index)} does not exist`);
    }
    return {
      index,
      question,
      control,
      asset,
    };
  }

  /**
 * Find first next applicable step for asset.
 * @returns updated state object
 */
  private nextApplicableState(answer?:ISelectedOption) {
    // If no answer is given, we find the first applicable question for this asset
    if (!answer) {
      return this.firstApplicableState();
    }
    const nextQuestion = this.findNextQuestion([...this.p_state.index, answer.index]);
    return this.getStateFromQuestion(nextQuestion);
  }

  private firstApplicableState() {
    const first = this.findFirstQuestion();
    return first
      ? {
        ...this.p_state,
        index: first.index,
        currentControl: first.control,
        currentQuestion: first.question,
      } as IState
      : undefined;
  }

  private findNextApplicableQuestion(asset:IAssessmentSyncAsset, index:number[]) : IQuestionContext|undefined {
    return this.traverseToMatchingQuestion(
      (control, question) => AssessmentStateManager.isApplicable(control, asset.type)
        && question
        && AssessmentStateManager.isApplicable(question, asset.type),
      index,
    );
  }

  private skipToNextQuestion(afterIndex:number[]) : IQuestionContext|undefined {
    if (!this.p_assessment.script || afterIndex.length === 0) {
      return undefined;
    }

    const { asset } = this.getActive();

    const nextQuestion = this.traverseToMatchingQuestion(
      (control, question, index) => !isChildIndex(afterIndex, index)
        && AssessmentStateManager.isApplicable(control, asset.type)
        && question
        && AssessmentStateManager.isApplicable(question, asset.type),
      afterIndex,
    );

    return this.isValidNextQuestion(nextQuestion?.index)
      ? nextQuestion
      : undefined;
  }

  private getStateFromQuestion(question:IQuestionContext|undefined) : IState|undefined {
    return question
      ? {
        ...this.p_state,
        index: question.index,
        currentControl: question.control,
        currentQuestion: question.question,
      }
      : undefined;
  }

  private static getAssessmentAssetIndexedKey(asset:IAssessmentSyncAsset, index?:number[]) {
    return `${asset.uniqueId}_${index && index.length ? index.join(':') : ''}`;
  }
}
