/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import {
  Alert,
  Button, Col, Form, OverlayTrigger, Row, Stack,
  Tooltip,
} from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import RenderHtml from '../../components/RenderHtml';
import { useAccount } from '../../providers/AccountProvider';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { asClientErrorString } from '../../routing/ClientError';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { useLocalStorage } from '../../utils/StorageUtils';
import { filterEmails } from '../../utils/StringUtils';

const toastMessageId = 'notificationSchedule';
const toastSettings = {
  updateId: toastMessageId,
  toastId: toastMessageId,
};

interface INotificationMesssageAndReceiver {
  subject:string,
  body:string,
  receivers: { address: string, type: 'email', }[],
}

interface IMessageDraft {
    body?: string,
    subject?: string,
}

const emptyDraft = {
  subject: '',
  body: '',
};

export const AdminNotificationsSendTab = () => {
  const { user } = useAccount();
  const { pushConfirm } = useNewModalContext();

  const { value: draft, setter: persistDraft } = useLocalStorage<
  IMessageDraft & {
    previewed?: IMessageDraft,
    previewReceivers?: string
  }
  >(
    'product-info-message-draft',
    { },
  );
  const [previewState, setPreviewState] = useState<IMessageDraft|undefined>(draft?.previewed);
  const [previewReceivers, setPreviewReceivers] = useState(draft?.previewReceivers ?? '');

  const [messageDraft, setMessageDraft] = useState(draft ?? { ...emptyDraft });

  const updateDraft = useCallback((updatedDraft?:IMessageDraft|undefined) => {
    setMessageDraft(updatedDraft ?? {
      ...emptyDraft,
    });
    persistDraft(updatedDraft ?? {
      ...draft,
      ...emptyDraft,
    });
  }, [draft, persistDraft]);

  const invalidateLog = useInvalidateQueries('module/admin/notifications');

  const currentUserEmail = user.email?.length ? user.email : user.externalId;
  const [busy, setBusy] = useState(false);
  const hasContent = messageDraft.body?.length && messageDraft.subject?.length;

  const isPreviewed = previewState
  && previewState.subject === messageDraft.subject
  && previewState.body === messageDraft.body;

  const sendEmailToSelf = useCallback(async (
    subject:string|undefined,
    body:string|undefined,
    additionalReceivers?:string[],
  ) => {
    if (!subject?.length || !body?.length) {
      return;
    }

    const receivers = [...additionalReceivers ?? [], currentUserEmail].map((a) => ({
      address: a,
    }));

    setBusy(true);
    try {
      await axios.post('/api/v1/module/admin/notifications/scheduleMessage', {
        subject,
        body,
        receivers: receivers.map((r) => ({
          address: r.address,
          type: 'email',
        })),
      } as INotificationMesssageAndReceiver);

      toast.success('Message was successfully scheduled for delivery to yourself', toastSettings);

      const previewed:IMessageDraft = {
        subject,
        body,
      };

      setPreviewState(previewed);

      persistDraft({
        ...draft,
        previewed,
      });

      await invalidateLog();
    } catch (err) {
      toast.error(`Unable to schedule messaage for delivery. ${asClientErrorString(err)}`, toastSettings);
    } finally {
      setBusy(false);
    }
  }, [currentUserEmail, draft, invalidateLog, persistDraft]);

  const sendProductInfo = useCallback(async (subject:string|undefined, body:string|undefined) => {
    if (!previewState || !subject?.length || !body?.length) {
      return;
    }

    if (subject !== previewState.subject || body !== previewState.body) {
      toast.warn('Send email to yourself before you can send everyone!', toastSettings);
      return;
    }

    if (!await pushConfirm({
      title: 'Send email to all product info receivers?',
      content: (
        <div>
          Inspect the preview and confirm that you would like to send the email to
          all registered product update receviers?
        </div>
      ),
      okLabel: 'Send email',
      cancelLabel: 'Cancel',
    })) {
      return;
    }

    setBusy(true);
    try {
      await axios.post('/api/v1/module/admin/notifications/scheduleProductInfoMessage', {
        subject,
        body,
      });
      toast.success('Notification message have been scheduled for delivery to all product info receivers.', toastSettings);
      await invalidateLog();
      updateDraft(undefined);
    } catch (err) {
      toast.error(`Unable to schedule messaage for delivery. ${asClientErrorString(err)}`, toastSettings);
    } finally {
      setBusy(false);
    }
  }, [invalidateLog, previewState, pushConfirm, updateDraft]);

  const textAreaRef = useRef(null);

  const updateTextAreaHeight = () => {
    if (textAreaRef.current) {
      const element = textAreaRef.current as HTMLTextAreaElement;
      element.style.height = '1px';
      element.style.overflowY = 'auto';
      element.style.height = `${10 + element.scrollHeight}px`;
    }
  };

  useLayoutEffect(() => {
    if (textAreaRef.current) {
      const element = textAreaRef.current as HTMLTextAreaElement;
      element.style.height = `${element.scrollHeight}px`;
      element.style.overflowY = 'hidden';
    }
  }, [textAreaRef]);

  return (
    <div>
      <Row>
        <Col md={12} className="mb-3">
          <Form.Label>Subject:</Form.Label>
          <Form.Control
            value={messageDraft.subject}
            onChange={(e) => updateDraft({
              ...messageDraft,
              subject: e.target.value,
            })}
          />
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Body:</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={messageDraft.body}
            ref={textAreaRef}
            onChange={(e) => {
              updateDraft({
                ...messageDraft,
                body: e.target.value,
              });
              updateTextAreaHeight();
            }}
          />
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Preview:</Form.Label>
          <RenderHtml className="form-control pb-2">
            {messageDraft.body}
          </RenderHtml>
          <div className="muted small">Markdown preview</div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-3">
          <Form.Label>Additional receivers (comma separated email addresses):</Form.Label>
          <Form.Control
            value={previewReceivers}
            onChange={(e) => {
              setPreviewReceivers(e.target.value);
              persistDraft({
                ...draft,
                previewReceivers: e.target.value,
              });
            }}
          />
          {
            previewReceivers
              ? (
                <Row>
                  <Col md={6} className="mb-3 mt-2">
                    <Alert variant="warning" className="p-2 mb-0">
                      <div>
                        Message will be sent to yourself and each additional receiver as an individual email.
                        <br />
                        <br />
                        If you would like to preview the mail before sending to external receivers, you can do this
                        by sending the mail to yourself without additional receivers.
                      </div>
                    </Alert>
                  </Col>
                </Row>
              )
              : null
          }
        </Col>
        <Col md={12}>
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="secondary"
              disabled={busy || (!messageDraft.subject && !messageDraft.body)}
              onClick={async () => {
                if (await pushConfirm({
                  title: 'Discard notification?',
                  content: 'Are you sure you would like to discard this notification message?',
                  okLabel: 'Yes, discard it!',
                  cancelLabel: 'No, cancel',
                })) {
                  updateDraft();
                  setTimeout(() => {
                    updateTextAreaHeight();
                  });
                }
              }}
            >
              Discard
            </Button>
            <OverlayTrigger
              overlay={(
                <Tooltip>
                  Send notification preview to yourself and any additional receivers added above
                </Tooltip>
              )}
            >
              <Button
                disabled={busy || !hasContent}
                onClick={async () => sendEmailToSelf(
                  messageDraft.subject,
                  messageDraft.body,
                  previewReceivers?.split(',').map((s) => s.trim()).filter(filterEmails),
                )}
              >
                Send to yourself
                { previewReceivers
                  ? ', and additional receivers'
                  : ''}
              </Button>
            </OverlayTrigger>
            { hasContent
              ? isPreviewed
                ? (
                  <Button
                    variant="danger"
                    className="ms-auto"
                    disabled={busy || !hasContent || !isPreviewed}
                    onClick={async () => sendProductInfo(messageDraft.subject, messageDraft.body)}
                  >
                    Send to all product info receivers
                  </Button>
                )
                : (
                  <OverlayTrigger
                    overlay={<Tooltip>Send preview before you can send to eveyone</Tooltip>}
                  >
                    <div
                      className="ms-auto btn btn-secondary"
                    >
                      Send to all product info receivers
                    </div>
                  </OverlayTrigger>
                ) : null}
          </Stack>
        </Col>
      </Row>
    </div>
  );
};
