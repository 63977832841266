import React, { useLayoutEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';

const toastOptions:ToastOptions = {
  autoClose: false,
  updateId: 'action-confirm',
  toastId: 'action-confirm',
};

export const ActionConfirmReturnPage = () => {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const success = searchParams.get('success')?.toLowerCase() === 'true';

  useLayoutEffect(
    () => {
      if (type === 'SignupActivation') {
        if (success) {
          toast.success(
            <div>
              <strong>Your account has been activated!</strong>
              <br />
              <br />
              If you&apos;re not already logged in, please log in with the associated user
              to start using the Ivolv Cybersecurity Platform.
            </div>,
            toastOptions,
          );
        } else {
          toast.error(
            <div>
              <strong>Activation failed!</strong>
              <br />
              <br />
              Please sign up again to retry, or contact support@ivolv.no if the problem persists
            </div>,
            toastOptions,
          );
        }
      } else if (success) {
        toast.success(
          <div>Action was successfully executed</div>,
          toastOptions,
        );
      } else {
        toast.error(
          <div>Failed to execute action</div>,
          toastOptions,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return null;
};
