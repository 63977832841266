/* eslint-disable no-nested-ternary */
import {
  IVulnerability, VulnerabilityStatus, RiskLevel, SecurityLevel, IControl, Significance,
  IStatusableVulnerability, IAggregatedVulnerabilityStatus,
  ISeverityComponents,
  Severity,
} from './Types';

export const severityComponentsAsKey = ({ probability, impact }:ISeverityComponents) => (
  `${impact}:${probability}`
);

export const significanceAsSeverityComponent = (significance:Significance) => {
  switch (significance) {
  case Significance.VeryLow:
    return 2;
  case Significance.Low:
    return 4;
  case Significance.Medium:
    return 6;
  case Significance.High:
    return 8;
  case Significance.VeryHigh:
    return 10;
  default:
    return 0;
  }
};

export const severityAsHexColor = (severity:Severity) => {
  switch (severity) {
  case Severity.VeryLow:
  case Severity.Low:
    return '#2c8';
  case Severity.Medium:
    return '#fb3';
  case Severity.High:
  case Severity.VeryHigh:
    return '#d34';
  default:
    return '#aaa';
  }
};

export const severityAsCssClassName = (severity:Severity|undefined) => {
  switch (severity) {
  case Severity.VeryLow:
  case Severity.Low:
    return 'severity-low severity-low-text';
  case Severity.Medium:
    return 'severity-medium severity-medium-text';
  case Severity.High:
  case Severity.VeryHigh:
    return 'severity-high severity-high-text';
  default:
    return 'secondary';
  }
};

export const severityAsLighterCssClassName = (
  severity:Severity|undefined,
  addOpacity:boolean,
  classSuffix?: string,
) => {
  switch (severity) {
  case Severity.VeryLow:
    return `severity severity-very-low-lighter${classSuffix ? `-${classSuffix}` : ''}`;
  case Severity.Low:
    return `severity severity-low-lighter${classSuffix ? `-${classSuffix}` : ''}`;
  case Severity.Medium:
    return `severity severity-medium-lighter${classSuffix ? `-${classSuffix}` : ''}`;
  case Severity.High:
    return `severity severity-high-lighter${classSuffix ? `-${classSuffix}` : ''}`;
  case Severity.VeryHigh:
    return `severity severity-very-high-lighter${classSuffix ? `-${classSuffix}` : ''}`;
  default:
    return 'secondary';
  }
};

export const severityAsIndex = (severity:Severity) => {
  switch (severity) {
  case Severity.Low:
    return 0;
  case Severity.Medium:
    return 1;
  case Severity.High:
  default:
    return 2;
  }
};

/**
 * Get a fraction (0-1) indicating how much of it's implementation status the vulnerability
 * represents. Most statuses gives a fraction of 1, except the partially implemented status
 * which fraction is represented by the implementation status.
 */
export const resolveVulnerabilityStatus = (vuln:IVulnerability) : {status:VulnerabilityStatus, fraction:number}[] => {
  if (vuln.status === VulnerabilityStatus.Open) {
    const fraction = Math.round((vuln?.mitigationPercent ? vuln.mitigationPercent / 100 : 0) * 100) / 100;
    return [
      { status: VulnerabilityStatus.Open, fraction: 1 - fraction },
      { status: VulnerabilityStatus.Mitigated, fraction },
    ];
  }
  return [
    { status: vuln.status, fraction: 1 },
  ];
};

export const getImplementationPercentText = (vulnerability:IStatusableVulnerability) => (
  vulnerability.status !== VulnerabilityStatus.Open || !vulnerability.mitigationPercent
    ? ''
    : ` (${Math.floor(vulnerability.mitigationPercent)}%)`
);

const vulnerabilityStatusAsColorClassName = (status:VulnerabilityStatus) => {
  switch (status) {
  case VulnerabilityStatus.Mitigated:
    return 'vuln-mitigated-bg';
  case VulnerabilityStatus.Open:
    return 'vuln-open-bg';
  default:
    return 'vuln-unknown-bg';
  }
};

export const vulnerabilityStatusAndSeverityAsColorClassNames = (
  status:VulnerabilityStatus,
  severity:Severity|undefined,
) => [
  vulnerabilityStatusAsColorClassName(status),
  severityAsCssClassName(severity),
].join(' ');

const statusIndexMap = [
  VulnerabilityStatus.Mitigated,
  VulnerabilityStatus.Unknown,
  VulnerabilityStatus.Open,
];

export const vulnerabilityStatusAsIndex = (status:VulnerabilityStatus) : number => statusIndexMap.indexOf(status);

export const indexAsVulnerabilityStatus = (statusIndex:number) => (
  statusIndexMap[statusIndex] ?? VulnerabilityStatus.Unknown
);

export const containsSecurityLevel = (targetLevel:SecurityLevel, level:SecurityLevel) => {
  switch (targetLevel) {
  case SecurityLevel.Unknown:
    return true;
  case SecurityLevel.Basic:
    return level === SecurityLevel.Basic;
  case SecurityLevel.Improved:
    return level === SecurityLevel.Basic
    || level === SecurityLevel.Improved;
  case SecurityLevel.Advanced:
    return level === SecurityLevel.Basic
    || level === SecurityLevel.Improved
    || level === SecurityLevel.Advanced;
  default:
    return false;
  }
};

/**
 * Group vulnerabilities by topic and control.
 */
export const groupVulnerabilityControlsByTopic = (
  vulns: IVulnerability[]|undefined,
  securityLevel: SecurityLevel,
) => {
  const topicData = {} as Record<string, Record<string, {control:IControl, vulnerabilities:IVulnerability[]}>>;

  vulns?.filter((v) => containsSecurityLevel(securityLevel, v.control.securityLevel)).forEach((vuln) => {
    const topicName = vuln.control.functionName && vuln.control.topicName
      ? `${vuln.control.functionName}: ${vuln.control.topicName}`
      : 'Unknown';
    if (topicData[topicName] === undefined) {
      topicData[topicName] = {};
    }
    if (topicData[topicName][vuln.control.id] === undefined) {
      topicData[topicName][vuln.control.id] = {
        control: vuln.control,
        vulnerabilities: [],
      };
    }
    topicData[topicName][vuln.control.id].vulnerabilities.push(vuln);
  });

  return topicData;
};

export const securityLevelAsIndex = (securityLevel:SecurityLevel|undefined) => {
  switch (securityLevel) {
  case SecurityLevel.Basic: return 10;
  case SecurityLevel.Improved: return 20;
  case SecurityLevel.Advanced: return 30;
  case SecurityLevel.Unknown:
  default:
    return 0;
  }
};

export const createTopicProgressData = (
  vulnerabilitiesByTopic:Record<string, Record<string, {control:IControl, vulnerabilities:IVulnerability[]}>>,
  topics:string[],
) => {
  const progressByTopic = new Map<string, number>();

  for (const topic of topics) {
    const counts = { total: 0, mitigated: 0 };

    for (const topicKey of Object.keys(vulnerabilitiesByTopic[topic]) || []) {
      for (const vuln of vulnerabilitiesByTopic[topic][topicKey].vulnerabilities) {
        counts.total += 1;
        if (vuln.status === VulnerabilityStatus.Mitigated) {
          counts.mitigated += 1;
        }
        if (vuln.status === VulnerabilityStatus.Open) {
          const openStatus = resolveVulnerabilityStatus(vuln).find((v) => v.status === VulnerabilityStatus.Mitigated);
          counts.mitigated += openStatus?.fraction ?? 0;
        }
      }
    }

    const percent = Math.round(counts.mitigated / counts.total * 100);
    progressByTopic.set(topic, Number.isInteger(percent) ? percent : 0);
  }

  return progressByTopic;
};

export const riskLevelAsColor = (
  riskLevel:RiskLevel|undefined,
  prefix:string|undefined = undefined,
  defaultColor:string|undefined = undefined,
): string => {
  switch (riskLevel) {
  case RiskLevel.High:
    return `${prefix || ''}-red`;
  case RiskLevel.Medium:
    return `${prefix || ''}-yellow`;
  case RiskLevel.Low:
    return `${prefix || ''}-green`;
  default:
    return defaultColor ?? '';
  }
};

const vulnStatusColorMap = new Map<VulnerabilityStatus, string>([
  [VulnerabilityStatus.Mitigated, '#22cc88'],
  [VulnerabilityStatus.Open, '#dd3443'],
  [VulnerabilityStatus.Unknown, '#ccc'],
]);

export const vulnerabilityStatusAsHexColor = (status:VulnerabilityStatus) : string => vulnStatusColorMap.get(status) ?? '#ccc';

export const findMostSevereVulnerabilityStatus = (statuses:VulnerabilityStatus[]) => (
  indexAsVulnerabilityStatus(Math.max(...statuses.map(vulnerabilityStatusAsIndex)))
);

export const compareSignificance = (itemA:Significance, itemB:Significance) => {
  const indexA = significanceAsSeverityComponent(itemA);
  const indexB = significanceAsSeverityComponent(itemB);
  return indexA === indexB
    ? 0
    : indexA < indexB ? -1 : 1;
};

export const compareSeverity = (itemA:Severity|undefined, itemB:Severity|undefined) => {
  const indexA = itemA ? severityAsIndex(itemA) : -99;
  const indexB = itemB ? severityAsIndex(itemB) : -99;
  return indexA === indexB
    ? 0
    : indexA < indexB ? -1 : 1;
};

export const aggregateVulnerabilities = (
  vulnerabilities:IStatusableVulnerability[],
) : IAggregatedVulnerabilityStatus => {
  const aggregate:IAggregatedVulnerabilityStatus = {
    statuses: [],
    severity: Severity.Unknown,
    relativeSeverity: Severity.Unknown,
  };

  if (vulnerabilities.length === 0) {
    return aggregate;
  }
  if (vulnerabilities.length === 1) {
    aggregate.statuses = [vulnerabilities[0].status];
    aggregate.severity = vulnerabilities[0].severity;
    aggregate.relativeSeverity = vulnerabilities[0].relativeSeverity;
    return aggregate;
  }

  vulnerabilities.filter((v) => v.status === VulnerabilityStatus.Open).forEach((v) => {
    if (!aggregate.statuses.includes(v.status)) {
      aggregate.statuses.push(v.status);
    }

    if (aggregate.severity === Severity.Unknown) {
      aggregate.severity = v.severity;
    } else if (compareSeverity(aggregate.severity ?? Severity.Unknown, v.severity) > 0) {
      aggregate.severity = v.severity;
    }

    const relativeSeverity = v.relativeSeverity ?? Severity.Unknown;
    if (aggregate.relativeSeverity === Severity.Unknown) {
      if (relativeSeverity !== Severity.Unknown) {
        aggregate.relativeSeverity = relativeSeverity;
      }
    } else if (compareSeverity(aggregate.relativeSeverity ?? Severity.Unknown, relativeSeverity) > 0) {
      aggregate.relativeSeverity = relativeSeverity;
    }
  });

  return aggregate;
};
