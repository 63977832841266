import { StoreApi, create } from 'zustand';
import { useLocation } from 'react-router-dom';
import { IStringDictionary } from '../../types/Types';
import { ISorting } from './PagedResultFilter';
import { ValidPageSizes } from './Pagination';
import { PaginationState } from './PagedTable';
import { VisibilityState } from './useTableModel';

export type PagedTableState<TQuery> = {
  queryFilters:TQuery,
  setQueryFilters:(state:TQuery) => void,
  columnVisibility: VisibilityState|null,
  setColumnVisibility: (state:VisibilityState) => void,
  pagination: PaginationState,
  setPagination: (state:PaginationState) => void,
  sorting: ISorting[]
  setSorting: (state:ISorting[]) => void,
};

const storeMap = {} as IStringDictionary<StoreApi<PagedTableState<unknown>>>;

const getTableStoreLocaleStoreKey = (key:string, id:string) => `paged_table_state_${key}_${id}`;

const getFromLocalStoreOrDefault = <T, >(key:string, id:string, defaultValue:T) => {
  const storageItemAsString = localStorage.getItem(
    getTableStoreLocaleStoreKey(key, id),
  );
  if (!storageItemAsString) {
    return defaultValue;
  }
  try {
    return JSON.parse(storageItemAsString) as T;
  } catch {
    return defaultValue;
  }
};

const getFromLocalStoreOrNull = <T, >(key:string, id:string) => {
  const storageItemAsString = localStorage.getItem(
    getTableStoreLocaleStoreKey(key, id),
  );
  if (!storageItemAsString) {
    return null;
  }
  try {
    return JSON.parse(storageItemAsString) as T;
  } catch {
    return null;
  }
};

const setToLocalStorage = <T, >(key:string, id:string, value:T) => {
  localStorage.setItem(
    getTableStoreLocaleStoreKey(key, id),
    JSON.stringify(value),
  );
};

const columnVisibilityKey = 'column_visibility';
const paginationKey = 'pagination';

export const getDefaultPagination = (
  defaultPaginationState?:PaginationState,
) => ({
  pagination: getFromLocalStoreOrDefault<PaginationState>(
    paginationKey,
    '',
    defaultPaginationState ?? {
      pageIndex: 0,
      pageSize: 15,
    },
  ),
  setPagination: (pagination:PaginationState) => {
    setToLocalStorage(paginationKey, '', pagination);
  },
});

const createTableStore = <TQuery, >(
  {
    id,
    initialFilters,
    initialSorting,
  }:{
    id:string,
    initialFilters?:TQuery
    initialSorting?:ISorting[]
  }) => {
  const {
    pagination: defaultPagination,
    setPagination: setDefaultPagination,
  } = getDefaultPagination({ pageSize: 15, pageIndex: 0 }) ?? {};

  return create<PagedTableState<TQuery>>((set) => ({
    queryFilters: initialFilters ?? {} as TQuery,
    setQueryFilters: (queryFilters:TQuery) => {
      set(() => ({ queryFilters }));
    },
    columnVisibility: getFromLocalStoreOrNull<VisibilityState>(
      columnVisibilityKey,
      id,
    ),
    setColumnVisibility: (columnVisibility) => {
      set((state) => {
        setToLocalStorage(columnVisibilityKey, id, columnVisibility);
        return {
          ...state,
          columnVisibility,
        };
      });
    },
    // Note: using '' as id to use same navigation settings for all tables
    pagination: {
      ...defaultPagination,
      // Always start on page 0 when first navigating to a table
      pageIndex: 0,
    },
    setPagination: (pagination) => {
      set((state) => {
        setDefaultPagination(pagination);
        return {
          ...state,
          pagination,
        };
      });
    },
    sorting: initialSorting ?? [],
    setSorting: (sorting) => {
      set((state) => ({
        ...state,
        sorting,
      }));
    },
  }));
};

interface ITableDefaults<TQuery> {
  visibilityState?:VisibilityState,
  pageSize?:ValidPageSizes,
  initialFilters?:TQuery,
  initialSorting?:ISorting[]
}

export const usePagedTableStore = <TQuery, >(
  id:string,
  defaults?:ITableDefaults<TQuery>,
) => {
  const location = useLocation();

  // Key on location key to use a store per unique route.
  // This allows us to remember filters etc. on back navigation, while starting fresh
  // when first navigating to a table.
  const key = `${location.key}::${id}`;

  if (!storeMap[key]) {
    storeMap[key] = createTableStore({
      id,
      initialFilters: defaults?.initialFilters,
      initialSorting: defaults?.initialSorting,
    }) as StoreApi<PagedTableState<unknown>>;
  }
  return {
    uniqueKey: key,
    store: storeMap[key] as StoreApi<PagedTableState<TQuery>>,
  };
};
